import React, { Component } from "react"
import ProfileStore from "../../store/profile.store"
import historyService from "../../service/history.service"
import { extendObservable } from "mobx"
import t from '../../service/translate.service'
import { observer } from "mobx-react"
import ThankYouOverlay from "../../components/ThankYouOverlay"
import User from "../../store/user.store"

class DeleteProfile extends Component {

    constructor (props, context) {
        super(props)
    
        extendObservable(this, {
          profileStore: new ProfileStore()
        })
      }

    deleteAccount = () => {
        this.profileStore.deleteAccount().then(res => {
          User.logout()
        })
      }

    render () {
        return (
            <ThankYouOverlay
                title={t.DELETE_ACCOUNT}
                description={t.DELETE_ACCOUNT_TEXT}
                buttonText={t.DELETE_ACCOUNT_YES}
                buttonLink={''}
                handleClick={this.deleteAccount}
                cancelButtonText={t.DELETE_ACCOUNT_NO}
                handleCancel={() => historyService.back()}
        />
        )
    }
}

export default observer(DeleteProfile)