import { extractResponse, extractError, extractErrorFromSap } from '../service/response.service'
import { extendObservable, runInAction } from 'mobx'
import { api, API_BASE } from '../service/api.service'
import t from '../service/translate.service'
import moment from 'moment'
import Contract from '../store/contract.store'

class PrepaymentsFormStore {
  constructor(contractNumber) {
    extendObservable(this, {
      model: {
        editablePrepayments: []
      },
      dateToday: moment().format('YYYY-MM-DD'),
      editablePrepayments: [],
      futurePrepayments: [],
      currentPrepayments: [],
      loading: false,
      submitting: false,
      submitSuccess: false,
      error: '',
      responses: [],
      confirmSubmit: false,
      contractStore: new Contract(),
      contract: null
    })

    window.store = this
    window.scrollTo(0, 0)
    this.loadPrepayments(contractNumber)
  }

  async loadPrepayments(contractNumber) {
    runInAction(() => {
      this.loading = true
    })
    try {
      const contracts = await this.contractStore.fetchContracts()
      const contract = contracts.find(contract => contract.contractNumber === contractNumber)
      const {editablePrepayments, currentPrepayments, futurePrepayments} = await this.getPrepayments(contract.contractNumber)
      runInAction(() => {
        this.currentPrepayments.replace(currentPrepayments)
        this.editablePrepayments.replace(editablePrepayments)
        this.futurePrepayments.replace(futurePrepayments)
        this.model.editablePrepayments.replace(editablePrepayments)
        this.contract = contract
        this.loading = false
      })
    } catch (err) {
      console.log(err)
      runInAction(() => {
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        window.scrollTo(0, 0)
        this.loading = false
      })
    }
  }

  async getPrepayments(contractNumber) {
    const response = await api.get(`${API_BASE}/api/contract/${contractNumber}/prepayments`)
    const data = extractResponse(response)
    const editablePrepayments = data.editablePrepayments.reduce((acc, prepayment) => {
      acc.push({
        ...prepayment,
        checked: false,
        label: this.getLabel(prepayment.condtype)
      })
      return acc
    }, [])
    const currentPrepayments = data.currentPrepayments.reduce((acc, prepayment) => {
      acc.push({
        ...prepayment,
        label: prepayment.condtype === '9200'
          ? t.PREPAYMENT_ADJUST_OPERATIONAL_COSTS
          : t.PREPAYMENT_ADJUST_HEATING_COSTS,
      })
      return acc
    }, [])
    const futurePrepayments = data.futurePrepayments.map(prepayment => ({
      ...prepayment, 
      label: this.getLabel(prepayment.condtype)
    }))
    return {
      editablePrepayments,
      currentPrepayments,
      futurePrepayments
    }
  }

  getLabel = (condtype) => {
    return condtype === '9200'
      ? t.PREPAYMENT_OPERATIONAL_COSTS
      : t.PREPAYMENT_HEATING_COSTS
  }

  displayCurrency = (price) => {
    return Number(price).toLocaleString('de-DE', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  changePrepayments = (value, editablePrepayment) => runInAction(() => {
    const amount = Number(value.replace('.', ''))
    const prepayments = this.model.editablePrepayments.map(prepayment => {
      if (prepayment.condtype === editablePrepayment.condtype) {
        prepayment.unitprice = isNaN(amount) ? prepayment.unitprice : amount
      }
      return prepayment
    })
    this.model.editablePrepayments.replace(prepayments)
  })

  togglePrepayments = (editablePrepayment) => runInAction(() => {
    const prepayments = this.model.editablePrepayments.map(prepayment => {
      if (prepayment.condtype === editablePrepayment.condtype) {
        prepayment.checked = !prepayment.checked
      }
      return prepayment
    })

    this.model.editablePrepayments.replace(prepayments)
  })

  onSubmitClick = () => runInAction(() => {
    this.confirmSubmit = !this.confirmSubmit
  })

  async submit() {
    runInAction(() => {
      this.submitting = true
      this.error = ''
      this.submitSuccess = false
      this.confirmSubmit = false
    })

    try {
      const {contractNumber} = this.contract
      const response = await api.post(`${API_BASE}/api/contract/${contractNumber}/prepayments`, {
        contractNumber,
        editablePrepayments: this.model.editablePrepayments
          .filter(prepayment => prepayment.checked)
          .map(prepayment => {
            delete prepayment.checked
            return prepayment
          })
      })

      const {editablePrepayments, currentPrepayments, futurePrepayments} = await this.getPrepayments(contractNumber)

      runInAction(() => {
        this.submitting = false
        this.error = ''
        this.submitSuccess = true
        this.responses.replace(response.data)
        this.currentPrepayments.replace(currentPrepayments)
        this.editablePrepayments.replace(editablePrepayments)
        this.futurePrepayments.replace(futurePrepayments)
        this.model.editablePrepayments.replace(editablePrepayments)
        window.scrollTo(0, 0)
      })
    } catch (err) {
      console.log(extractError(err))
      runInAction(() => {
        this.submitting = false
        this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
        this.submitSuccess = false
        window.scrollTo(0, 0)
      })
    }
  }
}

export default PrepaymentsFormStore