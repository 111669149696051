import React, { Component } from 'react'
import t from '../service/translate.service'
import { CustomSelect } from './formComponents'
import history from '../service/history.service'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import contractService from '../service/contract.service'
import DocumentStore from '../store/documentForm.store'

class ContractSelector extends Component {
  constructor (props) {
    super(props)
    if (!this.props.contractStore.selectedContract) history.push('/contracts')
  }

  static propTypes = {
    contractStore: PropTypes.object.isRequired,
    getConsultations: PropTypes.func,
    isDisabled: PropTypes.bool,
    contractNumber: PropTypes.string,
    isDigitalForm: PropTypes.bool,
    loadPrepayments: PropTypes.func
  }

  shouldHideForCondominium = (page) => {
    switch(page) {
      case 'documents':
        return true
      case 'letters':
        return !this.props.menuStore.lettersEnabledForCondoActive
      default:
        return false
    }
  }

  render () {
    const { activeContracts, selectedContract } = this.props.contractStore
    const contracts = []
    activeContracts.forEach(contract => {
      if (this.shouldHideForCondominium(this.props.page) && contractService.checkIsContractCondominium(contract.contractNumber)) {
        return
      }
      
      contracts.push({
        label: `${getContractType(contract.contractNumber)} - ${
          contract.address
        } (${contract.contractNumber})`,
        value: contract.id
      })
    })

    const selectedOption = contracts.filter(
      option => option.value === (((this.props.isDisabled && this.props.contractNumber) || this.props.contractNumber) ? activeContracts.find(contract => contract.contractNumber === this.props.contractNumber).id : selectedContract.id)
    )

    return (
      contracts.length > 1 && (
        <CustomSelect
          value={selectedOption || null}
          label={this.props.isDisabled && this.props.contractNumber ? t['PREPAYMENT_I_REQUEST'] : t['USER_TENANT_NUMBER']}
          options={contracts}
          onChange={option => {
            const contract = find(activeContracts, { id: option.value })
            this.props.contractStore.selectedContract = contract
            this.props.getConsultations && this.props.getConsultations()
            this.props.isDigitalForm && this.props.loadPrepayments(contract.contractNumber)
          }}
          isSearchable
          isDisabled={this.props.isDisabled}
        />
      )
    )
  }
}

function getContractType (contractNumber) {
  const contractTypeCode = getContractTypeLetter(contractNumber)
  const contractTypes = {
    M: t['CONTRACT_TYPE_APARTMENT'],
    P: t['CONTRACT_TYPE_PARKING_SPOT'],
    G: t['CONTRACT_TYPE_COMMERCIAL_USE'],
    K: t['CONTRACT_TYPE_CELLAR'],
    L: t['CONTRACT_TYPE_STORAGE'],
    E: t['CONTRACT_TYPE_CONDOMINIUM'],
    T: t['CONTRACT_TYPE_GARAGE_OR_PARKING_SPACE'],
    S: t['CONTRACT_TYPE_SPECIAL_AREA']
  }
  return contractTypes[contractTypeCode] || ''
}

function getContractTypeLetter (contractNumber) {
  const testForType = /[MPGKLETS]/
  const matches = testForType.exec(contractNumber)
  const typeLetter = matches && matches[0]
  return typeLetter
}
export default inject('contractStore', 'menuStore')(observer(ContractSelector))
