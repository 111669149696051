import t from '../../service/translate.service'

export default {
  // user and accont info
  get subject () {
    return {
      label: t['DAMAGE_REPORT_SUBJECT'],
      rules: 'required|string|between:1,32',
      value: ''
    }
  },
  get description () {
    return {
      label: t['DAMAGE_REPORT_DESCRIPTION'],
      rules: 'required|string|between:1,1024',
      value: ''
    }
  }
}
