import { api } from '../../service/api.service'
import { action, extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'

class AdminAlertStore {
  constructor () {
    extendObservable(this, {
      messages: [{
        content: '',
        contentDe: '',
        created_at: '',
        createdBy: '',
        selectedUsers: []
      }],
      selectedUserOption: { value: 'Select all users', label: 'Select all users' },
      selectedAlertOption: {value: 'general', label: 'General'},
      model: {
        content: '',
        contentDe: '',
        createdBy: '',
        selectUserInputValue: '',
        isFocus: false,
        selectedUsers: []
      },
      error: null,
      page: 1,
      loading: false,
      userList: [],
      contractList: [],
      selectedUserBy: '',
      usersByAlert: [],
      messageType: [],
      formSubmittedSuccessfully: false
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  load = action(id => {
    if (id === 'new') return
    this.loading = true
    api
      .get('/api/admin-alert/' + id)
      .then(extractResponse)
      .then(res => {
        const model = pick(res, [
          'id',
          'content',
          'contentDe',
          'createdBy'
        ])
        this.model = model
        if (res.messageType === 'Select all users' || res.messageType === null || res.messageType === '') {
          this.messageType = 'You have selected all users'
          this.usersByAlert = []
        } else {
          const users = res.usersList
          api
            .get('/api/users-by-id', { params: { users } })
            .then(res => {
              this.messageType = ''
              this.usersByAlert = res.data
            })
        }
      })
      .finally(() => {
        this.loading = false
      })
  })

  new () {
    this.model = {
      id: null,
      content: null,
      contentDe: null,
      selectUserInputValue: '',
      isFocus: false,
      selectedUsers: []
    }
  }

  deleteAlert (id) {
    api.delete('/api/admin-alert/' + id).then(() => {
      this.getAll()
    })
  }

  getAll () {
    this.loading = true
    api
      .get('/api/admin-alert')
      .then(res => {
        const { rows } = extractResponse(res)
        this.messages = rows
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    set(this.model, key, val)
  }

  onSelecetInputChange = (inputValue) => {
    this.model['selectUserInputValue'] = inputValue
    if (this.selectedUserOption.value === 'Select specific users') {
      if (inputValue !== '') {
        this.getUsers(inputValue)
      }
    } else if (this.selectedUserOption.value === 'Select users by contract') {
      this.getContracts(inputValue, 'contract_number')
    } else if (this.selectedUserOption.value === 'Select users by address') {
      this.getContracts(inputValue, 'address')
    } else if (this.selectedUserOption.value === 'Select users by city') {
      this.getContracts(inputValue, 'city')
    }
  }

  onContractSelect = (contractQuery) => {
    this.getUsers('', contractQuery)
  }

  setSelectedUsers = (user, type) => {
    switch (type) {
      case 'add':
        this.model.selectedUsers = [...this.model.selectedUsers, { key: user.key, info: `${user.firstName} ${user.lastName} (${user.email})` }]
        break
      case 'remove':
        this.model.selectedUsers = this.model.selectedUsers.filter((selectedUser) => user.key !== selectedUser.key)
    }
  }

  getUsers (inputValue = '', contractQuery = '') {
    api
      .get('/api/user', { params: { page: 1, q: inputValue, contractQuery: contractQuery, orderBy: 'created_at', order: 'DESC', isLimit: this.model.selectedUsers.length + 10 } })
      .then(res => {
        const { rows } = extractResponse(res)
        if (contractQuery !== '') {
          this.model.selectedUsers = rows.map((row) => { return { key: row.id, info: `${row.firstName} ${row.lastName} (${row.email})` } })
          this.selectedUserBy = contractQuery
        } else {
          this.userList = rows.map((row) => { return { key: row.id, firstName: row.firstName, lastName: row.lastName, email: row.email } })
        }
      }).catch(err => {
        console.log(err)
      })
  }

  getContracts (inputValue = '', contractBy = 'contract_number') {
    api
      .get('/api/contracts-admin', { params: { q: inputValue, contractBy } })
      .then(res => {
        this.contractList = [...res.data]
      }).catch(err => {
        console.log(err)
      })
  }

  save () {
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    const data = omit(this.model, ['id'])
    data['messageType'] = this.selectedUserOption.value
    data['categoryType'] = this.selectedAlertOption.value
    return api
      .post(
        '/api/admin-alert',
        data
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/admin-alert')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/admin-alert' + path,
        omit(this.model, ['id'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/admin-alert/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  updateQuery (val) {
    this.query = val
    this.debouncedGetAll()
  }

  selectUsersOptions = [
    { value: 'Select all users', label: 'Select all users' },
    { value: 'Select specific users', label: 'Select specific users' },
    { value: 'Select users by contract', label: 'Select users by contract' },
    { value: 'Select users by address', label: 'Select users by address' },
    { value: 'Select users by city', label: 'Select users by city' }
  ]


  selectAlertOptions = [
    { value: 'general', label: 'General' },
    { value: 'letter', label: 'Letter' },
  ]
}

export default new AdminAlertStore()
