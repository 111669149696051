import ContractStore from './contract.store'
import ConsultationStore from './consultation.store'
import userStore from './user.store'
import MenuStore from './menu.store'
import FaqFeedbackStore from './faqFeedback.store'
import AlertStore from './alert.store'
import MobileRedirectPopUp from './MobileRedirectPopUp.store'
import receiptsStore from "./receipts.store";
import ConsumptionStore from './consumption.store'
import ConfigStore from './config.store'
import RepairsAndInquiriesStore from './repairsAndInquiries.store'

const configStore = new ConfigStore()
const contractStore = new ContractStore(configStore)
const alertStore = new AlertStore()
const menuStore = new MenuStore(configStore)
const faqFeedbackStore = new FaqFeedbackStore()
const mobileRedirectPopUpStore = new MobileRedirectPopUp()
const consumptionStore = new ConsumptionStore()
const repairsAndIquiriesStore = new RepairsAndInquiriesStore(configStore)

userStore.initContractStore(contractStore)

export const stores = {
  menuStore,
  contractStore,
  consultationStore: new ConsultationStore(contractStore),
  faqFeedbackStore,
  alertStore,
  userStore,
  mobileRedirectPopUpStore,
  receiptsStore,
  consumptionStore,
  configStore,
  repairsAndIquiriesStore
}
