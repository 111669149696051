import { extractError, extractErrorFromSap } from '../service/response.service'
import { api, API_BASE } from '../service/api.service'
import { extendObservable, autorun } from 'mobx'
import {
  buildValidators,
  required
} from '../service/validate'
import t from '../service/translate.service'
import { Buffer } from 'buffer'
import * as DOMPurify from 'dompurify'
import ConfigStore from './config.store'

class RepairsAndIquiriesStore {
  constructor() {
    extendObservable(this, {
      configStore: new ConfigStore(),
      reports: [],
      statuses: [],
      selectedReport: {},
      files: [],
      submitted: false,
      loading: true,
      showSuccessMessage: false,
      showResolved: false,
      showAmendForm: false,
      srAmendmentImagesEnabled: false,
      error: '',
      amendment: '',
      excludedFiles: []
    })
    autorun(() => {
      this.configStore.getConfig()
      this.showAmendConfig()
    });
  }
  addFile = (files) => {
    if (files.length) {
      files.forEach(file => this.files.push(file))
      this.error = ''
    } else {
      this.error = t.INVALID_FILE_TYPE
    }
  }
  get validators() {
    return buildValidators(
      {
        message: [required]
      },
      this.submitted
    )
  }
  async getReports() {
    try {
      const res = await api.get(`${API_BASE}/api/damage-report`)
      this.reports = res.data.filter(r => r.contract !== null)
      const activeReports = this.reports.filter(r => r.repairAndInquiryStatus.id !== 3)

      if (Object.keys(this.selectedReport).length === 0) {
        if (activeReports.length) {
          this.selectedReport = activeReports[0]
        } else {
          this.showResolved = true
          this.selectedReport = this.reports[0]
        }
      }
      const statuses = await api.get(`${API_BASE}/api/repair-and-inquiry-statuses`)
      this.statuses = statuses.data
      this.loading = false
      return
    } catch (err) {
      extractError(err)
    }
  }
  onAmendInput = (value) => {
    this.amendment = DOMPurify.sanitize(value)
  }
  async downloadDocument(documentName) {
    try {
      const sanitizedName = DOMPurify.sanitize(documentName)
      const res = await api.get(`${API_BASE}/api/damage-report/download-document/${sanitizedName}`)
      const buffer = Buffer.from(res.data.buffer)
      const blob = new Blob([buffer])
      let url = window.URL.createObjectURL(blob)
      url = DOMPurify.sanitize(url)
      const a = document.createElement('a')
      a.setAttribute('style', 'display: none;')
      a.href = url
      a.download = sanitizedName
      a.click()
      window.URL.revokeObjectURL(url)

    } catch (err) {
      extractError(err)
    }
  }

  showAmendConfig() {
    if (this.configStore.srAmendmentImagesEnabled) {
      this.srAmendmentImagesEnabled = this.configStore.srAmendmentImagesEnabled
    }
  }

  async submitAmendment(reportId, amendment) {
    this.submitted = true
    const isValid = !this.validators.message(amendment)
    if (!isValid) {
      this.loading = false
      return
    }
    const data = new FormData()

    data.append('repairAndInquiryId', reportId)
    data.append('amendment', amendment)

    this.files.forEach((file, i) => data.append(`file_${i}`, file, file.name))

    try {
      const res = await api.post(`${API_BASE}/api/repair-and-inquiry-amendment`, data, {
        timeout: 300000, headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (res.data.amendment) {
        this.reports = this.reports.map(report => {
          if (report.id === reportId) {
            report.amendments = report.amendments.concat(res.data)
            this.selectedReport = report
          }
          return report
        })
        this.loading = false
        this.showSuccessMessage = true
        this.showAmendForm = false
        this.excludedFiles = res?.data?.excludedFiles || []
      } else {
        this.loading = false
      }
      this.files = []
      this.getReports()
      this.amendment = ''
    } catch (err) {
      this.loading = false
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
    }
  }
}

export default RepairsAndIquiriesStore
