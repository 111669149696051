import axios from "axios"

const DATA_KEY = 'deimos_data' // localstorage key

const CSRF_TOKEN_ENDPOINT = '/generate-csrf'


const create = ({ token, refreshToken }) => {
  const userData = JSON.stringify({ token, refreshToken })
  localStorage.setItem(DATA_KEY, userData)
}

const remove = () => localStorage.removeItem(DATA_KEY)

const get = () => {
  let stringifiedData = localStorage.getItem(DATA_KEY)

  try {
    let userData = JSON.parse(stringifiedData)
    return userData.token
  } catch (ex) {
    return null
  }
}

const getRefreshToken = () => {
  let stringifiedData = localStorage.getItem(DATA_KEY)

  try {
    let userData = JSON.parse(stringifiedData)
    return userData.refreshToken
  } catch (ex) {
    return null
  }
}


const generateCsrfToken = async () => {
  
  try {
    const response = await axios.get(CSRF_TOKEN_ENDPOINT, {
      headers: {
        'Content-Type': 'application/json',
        'GCP-App-Info': `GCP App - web`,
      }
    })

    return response.data.crumb
  } catch (err) {
    console.log(err)
    console.log(`Error generating csrf token`)
  }
}

export default { create, get, getRefreshToken, remove, generateCsrfToken }
