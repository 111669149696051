import React from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, DateInput, Textarea } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const Key = (props) => {
  const { model, setVal, setSelectedKeys, keyReorderValidators, keyOptions } = DocumentStore
  const { file } = props
  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: keyReorderValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.KEY} />
      <Form onSubmit={onSubmit}>
        <ContractSelector label padding page='documents' />
        <h3 className='inputs-text'>{t.NAME_CHANGE_HEAD_ONE}</h3>
        <Input name='fullName' {...getAttrs('fullName')} label={t.DIGITAL_CONTRACT_FORM_FULL_NAME} />
        <h3 className='inputs-text'>{t.KEY_HEAD_ONE}</h3>
        <ul className='selected-inputs-holder'>
          {model['selectedKeys'].map((selectedKey) => (
            <li key={selectedKey.key}>
              <div className='selected-input-header'>
                {selectedKey.key.includes('additional') ? (
                  <Input validate={keyReorderValidators[`${selectedKey.key}Head`]} styles={{ width: '100%', maxWidth: '280px', marginBottom: '0' }} value={selectedKey.head} onChange={(e) => setSelectedKeys(selectedKey, 'input-change', 'head', e.target.value)} label={t.KEY_HEAD_G} />
                ) : (
                  <h3 className='inputs-text'>{selectedKey.head}</h3>
                )}
                <button onClick={() => {
                  setSelectedKeys(selectedKey, 'remove')
                }} type='button'>x</button>
              </div>
              <div className='inputs-holder'>
                <Input name={`${selectedKey.key}KeyNumber`} validate={keyReorderValidators[`${selectedKey.key}KeyNumber`]} value={selectedKey.keyNumber} onChange={(e) => setSelectedKeys(selectedKey, 'input-change', 'keyNumber', e.target.value)} label={t.DIGITAL_CONTRACT_FORM_KEY_NUMBER} />
                <Input name={`${selectedKey.key}Manufacturer`} validate={keyReorderValidators[`${selectedKey.key}Manufacturer`]} value={selectedKey.manufacturer} onChange={(e) => setSelectedKeys(selectedKey, 'input-change', 'manufacturer', e.target.value)} label={t.DIGITAL_CONTRACT_FORM_MANUFACTURER} />
                <Input name={`${selectedKey.key}Quantity`} validate={keyReorderValidators[`${selectedKey.key}Quantity`]} value={selectedKey.quantity} onChange={(e) => setSelectedKeys(selectedKey, 'input-change', 'quantity', e.target.value)} label={t.DIGITAL_CONTRACT_DESCRIPTION_QUANTITY} />
              </div>
              <div className='line' />
            </li>
          ))}
        </ul>
        <div className='new-key-select-holder'>
          <button onClick={() => {
            setVal('showOptions', !model['showOptions'])
          }} className='open-options-holder' type='button'><span>+</span>{t.KEY_ADD_NEW}</button>
          <div style={{ marginTop: `${model['keyFormError'] === '' ? 0 : '-12px'}`, marginBottom: `${model['keyFormError'] === '' ? 0 : '20px'}` }} className='error-message'>
            {model['keyFormError']}
          </div>
          <ul style={{ display: `${model['showOptions'] ? 'flex' : 'none'}` }} className='options-holder'>
            {keyOptions.map((option) => {
              if ((!model['selectedKeys'].find((selectedKey) => selectedKey.key === option.key)) && (!option.key.includes('additional') || model['additionalLength'] < 2)) {
                return <li key={option.key}><button onClick={() => {
                  setVal('showOptions', false)
                  setSelectedKeys(option, 'add')
                }} type='button'>{option.value}</button></li>
              }
            })}
          </ul>
        </div>
        <Textarea name='keyReorderReason' {...getAttrs('keyReorderReason')} label={t.DIGITAL_CONTRACT_FORM_REORDER_REASON + '*'} />
        <p className='input-para'>{t.KEY_PARA_ONE}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={keyReorderValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button
              type='submit'
              className='button primary'
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </Button>
            <Button
              type='button'
              className='button secondary'
              onClick={() => {
                DocumentStore.onCloseForm()
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

Key.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(Key))
