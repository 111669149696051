import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import moment from 'moment'
import { ConsultationsImage } from '../../components/icons'
import ContractSelector from '../../components/contractSelector'
import MultipaneLayout from '../../components/MultipaneLayout'
import NoContent from '../../components/NoContent'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import 'moment/locale/de'
import { ConsultationItems, ConsultationContent } from '../../pages/Consultations'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import find from 'lodash/find'
import withRouter from '../../components/withRouter'
import { Alert } from 'antd'
import { extendObservable } from 'mobx'

class ConsultationsPage extends Component {
  constructor(props) {
    super(props)
    this.store = this.props.consultationStore
    this.navigate = this.props.navigate
    extendObservable(this, {
      hasActiveContracts: false
    })
    //this.store.getAll()
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    moment.locale(localStorage.getItem('locale') || 'de')
  }

  onSchedule = (e, consultation) => {
    e.preventDefault()
    this.store.submitted = true
    const isValid = !find(this.store.validators, (validate, field) =>
      validate(this.store.selectedConsultation[field])
    )
    if (isValid) this.store.scheduleConsultation(consultation.schedule, consultation.subject)
  }

  onSlotSelect = (consultationId, schedule) => {
    this.store.consultations = this.store.consultations.map(consultation => {
      if (consultation.id === consultationId) {
        consultation.schedule = schedule.value
        consultation.timeslot = schedule
      }
      return consultation
    })
  }

  onSubjectInput = (consultationId, subject) => {
    this.store.consultations = this.store.consultations.map(consultation => {
      if (consultation.id === consultationId) {
        consultation.subject = subject
      }
      return consultation
    })
  }

  linkClick = (e) => {
    e.preventDefault()
    this.store.success = false
    this.navigate('/')
  }

  render() {
    if (this.store.success) {
      return (
        <ThankYouOverlay title={t.THANK_YOU}
          description={t.SCHEDULED_TEXT}
          buttonText={t.BACK_HOME}
          buttonLink='/'
          handleClick={this.linkClick}
        />
      )
    }

    return (
      <div>
        {this.store.loading && (<LoadingPageOverlay />)}
        <div className='subtitle primary'>{t.CONSULTATIONS_SUBTITLE}</div>
        <div style={{ paddingBottom: 15 }}>
          {this.store.error && <Alert message={this.store.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
          <ContractSelector
            getConsultations={() => {
              this.store.success = false
              this.store.getAll()
            }}
          />
        </div>
        <RenderConsultations
          store={this.store}
          onSlotSelect={this.onSlotSelect}
          onSubjectInput={this.onSubjectInput}
          onSchedule={this.onSchedule}
          validators={this.store.validators}
        />
      </div>
    )
  }
}

const RenderConsultations = observer(({ store, onSlotSelect, onSubjectInput, onSchedule, validators }) => {
  if (store.loading) {
    return <LoadingPageOverlay />
  }

  if (!store.consultations.length) {
    return (
      <NoContent
        message={t.CONSULTATIONS_NO_CONSULTATIONS}
        image={ConsultationsImage}
      />
    )
  }

  return (
    <MultipaneLayout selectedItem={store.selectedConsultation}>
      <ConsultationItems
        items={store.consultations}
        selectedItem={store.selectedConsultation}
        handleClick={store.handleMultipaneListItemClick}
        isEnglish={localStorage.getItem('locale') === 'en'}
      />
      <ConsultationContent
        success={store.success}
        error={store.error}
        onSelect={onSlotSelect}
        onSubjectInput={onSubjectInput}
        handleSubmit={onSchedule}
        validators={validators}
      />
    </MultipaneLayout>
  )
})
ConsultationsPage.propTypes = {
  consultationStore: PropTypes.object
}

export default inject(
  'consultationStore',
  'contractStore'
)(withRouter(observer(ConsultationsPage)))
