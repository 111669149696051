import React from 'react'
import { Link } from 'react-router-dom'
import PrimaryTitle from '../../components/PrimaryTitle'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'

export const HeatingAndEnergyConfirm = ({handleCancel, handleClick}) => {
  return (
      <div className='wrapper heating-energy-modal'>
        <PrimaryTitle title={t.HEATING_AND_ENERGY_TITLE} />
        <div className='description'>{t.HEATING_AND_ENERGY_CANCEL_CONFIRM_TEXT_ONE}</div>
        <div className='description'>{t.HEATING_AND_ENERGY_CANCEL_CONFIRM_TEXT_TWO}</div>
        <div className='btns-holder'>
          <Link to='' onClick={handleClick} className='button primary'>{t.HEATING_AND_ENERGY_CONFIRM}</Link>
          <Link className='button tertiary' to='/' onClick={handleCancel}>{t.HEATING_AND_ENERGY_CANCEL}</Link>
        </div>
      </div>
  )
}
HeatingAndEnergyConfirm.propTypes = {
  handleCancel: PropTypes.func,
  handleLink: PropTypes.string
}