import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'

class HeatingItem extends Component {
  constructor (props) {
    super(props)
    extendObservable(this, {
      data: this.props.data
    })
  }
  render () {
    return (
      <div className={`heating-item-holder ${this.props.type}`}>
        <div className='head-holder'>
          <img src={this.props.image} />
          <h2>{this.props.head}</h2>  
        </div>
        <div className='item-content-holder'>
          <div className='energy-info-holder'>
            {this.props.data?.consumption && (
              <div className='energy-item-holder'>
                <p>{t.HEATING_AND_ENERGY_CONSUMPTION}</p>
                <p>{`${this.props.data.consumption} kWh`}</p>
              </div>
            )}
            {this.props.data?.previousMonth&& (
              <div className='energy-item-holder'>
                <p>{t.HEATING_AND_ENERGY_PREVIOUS_MONTH}</p>
                <p>{`${this.props.data.previousMonth} kWh`}</p>
              </div>
            )}
            {this.props.data?.currentMonthLastYear && (
              <div className='energy-item-holder'>
                <p>{t.HEATING_AND_ENERGY_PREVIOUS_MONTH_LAST_YEAR}</p>
                <p>{`${this.props.data.currentMonthLastYear} kWh`}</p>
              </div>
            )}
            {this.props.data?.comparison && (
              <div className='energy-item-holder'>
                <p>{t.HEATING_AND_ENERGY_COMPARISON}</p>
                <p>{`${this.props.data.comparison} kWh`}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
HeatingItem.propTypes = {
  image: PropTypes.string,
  head: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string
}

export default observer(HeatingItem)
