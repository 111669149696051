import React, { Component } from 'react'

class NotFoundPage extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div>NotFoundPage</div>
    )
  }
}

export default NotFoundPage
