import React, { Component } from 'react'
import { observer } from 'mobx-react'
import FeedbackForm from './FeedbackForm'
import Feedback from '../../store/feedback.store'
import { PropTypes } from 'prop-types'
import withRouter from '../../components/withRouter'

class FeedbackPage extends Component {
  constructor(props) { super(props) }
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    const feedback = new Feedback()
    const { rating } = this.props.useParams
    return (
      <div className='container white'>
        <FeedbackForm
          feedback={feedback}
          rating={rating}
        />
      </div>
    )
  }
}

FeedbackPage.propTypes = {
  params: PropTypes.object
}

export default withRouter(observer(FeedbackPage))
