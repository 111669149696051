import HomepageOverlay from "./HomepageOverlay";
import { checkIsUserCondominium, checkIfUserHasMixedContracts } from "../service/user.service";
import PropTypes from 'prop-types'
import find from "lodash/find";
import { quickLinksHome, quickLinksServices } from '../config'

const TenantServicesList = ({menuStore, receipts, onClick, User}) => {
  const hasActiveContracts = Boolean(find(User.contracts, (c) => c.valid && c.active));
  let isCondominium = false;
  let mixedContracts = false
  if (User.contracts) {
    isCondominium = checkIsUserCondominium(User.contracts);
    mixedContracts = checkIfUserHasMixedContracts(User.contracts)
  }
  const quickLinks = window.location.pathname === '/' ? quickLinksHome : quickLinksServices
  return (
    <div className="tenant-services-list welcome">
      {hasActiveContracts ? <span /> : <HomepageOverlay />}
      {quickLinks.map((item) => {
        if (item.link === '/letters' && (
          isCondominium
            && !menuStore.lettersEnabledForCondoActive
            || isCondominium
            && !mixedContracts
            && !menuStore.lettersEnabledForCondoActive
        )) return
        if (item?.isCondominium && isCondominium && item.link !== '/letters') return;
        if (item.link === '/loyalty-program' && !menuStore.loyaltyActive) return
        if (item.link === '/heating-and-energy' && !menuStore.energyAndHeatingActive) return
        if (item.link === '/receipt-inspection' && !menuStore.ocInspectionActive && !receipts.length) return
        if (item.link === '/letters' && !menuStore.lettersActive) return
        var iconClass = "";
        if (item.link === "/heating-and-energy") iconClass = " newBadge";
        if (item.link === "/letters") iconClass = " newBadge";
        var linkClass = "";
        if (item.link === "/loyalty-program" && User.user.resident) linkClass = " disabled";
        if (localStorage.getItem("locale") === "de") iconClass += " de";

        return (
          <div key={item.link} className={"item" + linkClass}>
            <div className={"container" + iconClass}>
              <div onClick={() => onClick(item.link)}>
                <div className="image">{item.image}</div>
                <div className="name">{item.name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
}

TenantServicesList.propTypes = {
  menuStore: PropTypes.object.isRequired,
  receipts: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired
}

export default TenantServicesList