
import { Textarea } from "../../../../components/formComponents";
import t from "../../../../service/translate.service";
import { Modal } from "antd";


const DescriptionModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => {
        props.closeAppointment('description')
      }}
      footer={[
        <button
          style={{
            marginRight: '10px'
          }}
          className="button secondary small"
          key="cancel"
          onClick={() => {
            props.closeAppointment('description')
          }}
        >
          {t.CONSULTATIONS_CANCEL}
        </button>,
        <button
          className="button primary small"
          key="ok"
          onClick={() => {
            props.setSubmitted(true)
            if (props.description) {
              props.onSubmit()
              props.setShowDescriptionModal(false)           
            }
          }}
        >
          {t.CONSULTATIONS_OK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className={`${props.count === 0 ? 'error-message' : ''}`}>
          {props.bookingTimeLeftMessage}
        </p>
        <Textarea 
          placeholder={t.CONSULTATIONS_DESCRIPTION_PLACEHOLDER}
          value={props.description}
          label={t.CONSULTATIONS_DESCRIPTION}
          maxLength={300}
          onChange={(e) => props.setDescription(e.target.value)}
        />
        <div 
          style={{
            display: 'flex',
            justifyContent: `${!props.description && props.submitted ? 'space-between' : 'end'}`
          }}
        >
          {!props.description && props.submitted && (
            <div className='error-message'>
              {t.CONSULTATIONS_DESCRIPTION}
            </div>
          )}
          <p
            style={{
              marginTop: '11px',
              marginBottom: 0
            }}
            className="modal-p">
              {`${props.description.length}/300`}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionModal;
