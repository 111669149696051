import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import ProfileStore from '../../store/profile.store'
import PrimaryTitle from '../../components/PrimaryTitle'
import ListSavedFields from '../../components/ListSavedFields'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import historyService from '../../service/history.service'

class ViewProfile extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  constructor (props, context) {
    super(props)
    this.state = {
      showAreYouSure: false
    }

    extendObservable(this, {
      profileStore: new ProfileStore()
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    const fields = [
      {
        label: t.USER_FIRST_NAME,
        value: this.profileStore.model.firstName
      },
      {
        label: t.USER_LAST_NAME,
        value: this.profileStore.model.lastName
      },
      {
        label: t.USER_EMAIL,
        value: this.profileStore.model.email
      },
      {
        label: t.USER_PHONE,
        value: this.profileStore.model.phone
      },
      {
        label: t.USER_MOBILE,
        value: this.profileStore.model.mobile
      }
    ]

    return (
      <div>
        {this.profileStore.loader && <LoadingPageOverlay />}
        <PrimaryTitle title={t.PROFILE_TITLE} />
        <div className='view-saved-profile-data'>
          <ListSavedFields fields={fields} textCenter />
        </div>

        <div className='text-center'>
          <Link to='/profile/edit'
          className='button primary'
          onClick={() => historyService.push('/profile/edit')}>
            {t.PROFILE_EDIT}
          </Link>
        </div>
        <div className='text-center' style={{ margin: 20 }}>
          <Link to='/profile/edit/email'
          className='button primary'
          onClick={() => historyService.push('/profile/edit/email')}>
            {t.EMAIL_EDIT}
          </Link>
        </div>
        <div className='text-center' style={{ margin: 20 }}>
          <Link to='/profile/edit/password'
          className='button primary editPassword'
          onClick={() => historyService.push('/profile/edit/password')}>
            {t.PASSWORD_EDIT}
          </Link>
        </div>
        <div className='text-center' style={{ margin: 20}}>
          <Link to='/profile/delete-account'
            className='button primary editPassword'
            onClick={() => historyService.push('/profile/delete-account')}>
              {t.DELETE_ACCOUNT}
            </Link>
        </div>
      </div>
    )
  }
}

export default observer(ViewProfile)
