import React from 'react'

const HomeIcon = (<svg
  xmlns='http://www.w3.org/2000/svg'
  version='1.1'
  width='30pt'
  height='30pt'
  viewBox='0 0 30 30'
>
  <g enableBackground='new'>
    <clipPath id='svg-home'>
      <path
        transform='matrix(1,0,0,-1,0,30)'
        d='M 26 28 L 4 28 L 0 20 L 3 20 L 3 2 L 27 2 L 27 20 L 30 20 L 26 28 Z M 16.99957 4 L 12.99957 4 L 12.99957 11 L 16.99957 11 L 16.99957 4 Z M 24.99957 4 L 18.99957 4 L 18.99957 13 L 10.99957 13 L 10.99957 4 L 4.99957 4 L 4.99957 20 L 24.99957 20 L 24.99957 4 Z M 3.2359 22 L 5.2359 26 L 24.76324 26 L 26.76324 22 L 3.2359 22 Z '
        fillRule='evenodd'
      />
    </clipPath>
    <g clipPath='url(#svg-home)'>
      <path
        transform='matrix(1,0,0,-1,0,30)'
        d='M -5 33 L 35 33 L 35 -3 L -5 -3 Z '
        fill='white'
      />
    </g>
  </g>
</svg>)

export default HomeIcon
