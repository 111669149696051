import React from 'react'

const ProfileIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='20pt'
    height='20pt'
    viewBox='0 0 20 20'
  >
    <g enableBackground='new'>
      <g>
        <clipPath id='svg-profile'>
          <path
            transform='matrix(1,0,0,-1,0,20)'
            d='M 10.04186 8 C 12.95116 8 15.77674 7.160494 18.22558 5.607407 C 18.70698 5.31358 19 4.788889 19 4.222222 L 19 2.62963 C 19 2.27284 18.72791 2 18.37209 2 C 18.01628 2 17.74419 2.27284 17.74419 2.62963 L 17.74419 4.222222 C 17.74419 4.369136 17.66047 4.495062 17.55581 4.558025 C 15.29535 5.985185 12.7 6.740741 10.04186 6.740741 L 9.937209 6.740741 C 7.27907 6.740741 4.683721 5.985185 2.444186 4.558025 C 2.318605 4.495062 2.255814 4.348148 2.255814 4.222222 L 2.255814 2.62963 C 2.255814 2.27284 1.983721 2 1.627907 2 C 1.272093 2 1 2.27284 1 2.62963 L 1 4.222222 C 1 4.788889 1.293023 5.31358 1.753488 5.607407 C 4.202326 7.181481 7.027907 8 9.937209 8 Z M 10.37674 18 C 12.13488 18 13.55814 16.57284 13.55814 14.80988 L 13.55814 11.95556 C 13.55814 10.19259 12.13488 8.765432 10.37674 8.765432 L 9.623256 8.765432 C 7.865116 8.765432 6.44186 10.19259 6.44186 11.93457 L 6.44186 14.80988 C 6.44186 16.57284 7.865116 18 9.602326 18 Z M 10.39767 16.74074 L 9.623256 16.74074 C 8.555814 16.74074 7.697674 15.85926 7.697674 14.80988 L 7.697674 11.95556 C 7.697674 10.88519 8.555814 10.02469 9.623256 10.0037 L 10.39767 10.0037 C 11.46512 10.0037 12.32326 10.88519 12.32326 11.93457 L 12.32326 14.80988 C 12.32326 15.88025 11.44419 16.74074 10.39767 16.74074 Z '
            fillRule='evenodd'
          />
        </clipPath>
        <g clipPath='url(#svg-profile)'>
          <g>
            <clipPath id='svg-profile-1'>
              <path
                transform='matrix(1,0,0,-1,0,20)'
                d='M -5 -4 L 24 -4 L 24 24 L -5 24 Z '
                fillRule='evenodd'
              />
            </clipPath>
            <g clipPath='url(#svg-profile-1)'>
              <clipPath id='svg-profile-2'>
                <path
                  transform='matrix(1,0,0,-1,0,20)'
                  d='M 10.04186 8 C 12.95116 8 15.77674 7.160494 18.22558 5.607407 C 18.70698 5.31358 19 4.788889 19 4.222222 L 19 2.62963 C 19 2.27284 18.72791 2 18.37209 2 C 18.01628 2 17.74419 2.27284 17.74419 2.62963 L 17.74419 4.222222 C 17.74419 4.369136 17.66047 4.495062 17.55581 4.558025 C 15.29535 5.985185 12.7 6.740741 10.04186 6.740741 L 9.937209 6.740741 C 7.27907 6.740741 4.683721 5.985185 2.444186 4.558025 C 2.318605 4.495062 2.255814 4.348148 2.255814 4.222222 L 2.255814 2.62963 C 2.255814 2.27284 1.983721 2 1.627907 2 C 1.272093 2 1 2.27284 1 2.62963 L 1 4.222222 C 1 4.788889 1.293023 5.31358 1.753488 5.607407 C 4.202326 7.181481 7.027907 8 9.937209 8 Z M 10.37674 18 C 12.13488 18 13.55814 16.57284 13.55814 14.80988 L 13.55814 11.95556 C 13.55814 10.19259 12.13488 8.765432 10.37674 8.765432 L 9.623256 8.765432 C 7.865116 8.765432 6.44186 10.19259 6.44186 11.93457 L 6.44186 14.80988 C 6.44186 16.57284 7.865116 18 9.602326 18 Z M 10.39767 16.74074 L 9.623256 16.74074 C 8.555814 16.74074 7.697674 15.85926 7.697674 14.80988 L 7.697674 11.95556 C 7.697674 10.88519 8.555814 10.02469 9.623256 10.0037 L 10.39767 10.0037 C 11.46512 10.0037 12.32326 10.88519 12.32326 11.93457 L 12.32326 14.80988 C 12.32326 15.88025 11.44419 16.74074 10.39767 16.74074 Z '
                  fillRule='evenodd'
                />
              </clipPath>
              <g clipPath='url(#svg-profile-2)'>
                <clipPath id='svg-profile-3'>
                  <path
                    transform='matrix(1,0,0,-1,0,20)'
                    d='M 0 1 L 19 1 L 19 19 L 0 19 Z '
                  />
                </clipPath>
                <g clipPath='url(#svg-profile-3)'>
                  <path
                    transform='matrix(1,0,0,-1,0,20)'
                    d='M 10.04186 8 C 12.95116 8 15.77674 7.160494 18.22558 5.607407 C 18.70698 5.31358 19 4.788889 19 4.222222 L 19 2.62963 C 19 2.27284 18.72791 2 18.37209 2 C 18.01628 2 17.74419 2.27284 17.74419 2.62963 L 17.74419 4.222222 C 17.74419 4.369136 17.66047 4.495062 17.55581 4.558025 C 15.29535 5.985185 12.7 6.740741 10.04186 6.740741 L 9.937209 6.740741 C 7.27907 6.740741 4.683721 5.985185 2.444186 4.558025 C 2.318605 4.495062 2.255814 4.348148 2.255814 4.222222 L 2.255814 2.62963 C 2.255814 2.27284 1.983721 2 1.627907 2 C 1.272093 2 1 2.27284 1 2.62963 L 1 4.222222 C 1 4.788889 1.293023 5.31358 1.753488 5.607407 C 4.202326 7.181481 7.027907 8 9.937209 8 Z M 10.37674 18 C 12.13488 18 13.55814 16.57284 13.55814 14.80988 L 13.55814 11.95556 C 13.55814 10.19259 12.13488 8.765432 10.37674 8.765432 L 9.623256 8.765432 C 7.865116 8.765432 6.44186 10.19259 6.44186 11.93457 L 6.44186 14.80988 C 6.44186 16.57284 7.865116 18 9.602326 18 Z M 10.39767 16.74074 L 9.623256 16.74074 C 8.555814 16.74074 7.697674 15.85926 7.697674 14.80988 L 7.697674 11.95556 C 7.697674 10.88519 8.555814 10.02469 9.623256 10.0037 L 10.39767 10.0037 C 11.46512 10.0037 12.32326 10.88519 12.32326 11.93457 L 12.32326 14.80988 C 12.32326 15.88025 11.44419 16.74074 10.39767 16.74074 Z '
                    fill='white'
                  />
                </g>
              </g>
              <clipPath id='svg-profile-4'>
                <path
                  transform='matrix(1,0,0,-1,0,20)'
                  d='M 0 1 L 19 1 L 19 19 L 0 19 Z '
                />
              </clipPath>
              <g clipPath='url(#svg-profile-4)'>
                <path
                  transform='matrix(1,0,0,1,1,2)'
                  strokeWidth='1'
                  strokeLinecap='butt'
                  strokeMiterlimit='10'
                  strokeLinejoin='miter'
                  fill='none'
                  stroke='white'
                  d='M 9.04186 10 C 11.95116 10 14.77674 10.83951 17.22558 12.39259 C 17.70698 12.68642 18 13.21111 18 13.77778 L 18 15.37037 C 18 15.72716 17.72791 16 17.37209 16 C 17.01628 16 16.74419 15.72716 16.74419 15.37037 L 16.74419 13.77778 C 16.74419 13.63086 16.66047 13.50494 16.55581 13.44198 C 14.29535 12.01481 11.7 11.25926 9.04186 11.25926 L 8.937209 11.25926 C 6.27907 11.25926 3.683721 12.01481 1.444186 13.44198 C 1.318605 13.50494 1.255814 13.65185 1.255814 13.77778 L 1.255814 15.37037 C 1.255814 15.72716 .9837209 16 .627907 16 C .272093 16 0 15.72716 0 15.37037 L 0 13.77778 C 0 13.21111 .2930233 12.68642 .7534884 12.39259 C 3.202326 10.81852 6.027907 10 8.937209 10 Z M 9.376744 0 C 11.13488 0 12.55814 1.42716 12.55814 3.190123 L 12.55814 6.044444 C 12.55814 7.807407 11.13488 9.234568 9.376744 9.234568 L 8.623256 9.234568 C 6.865116 9.234568 5.44186 7.807407 5.44186 6.065432 L 5.44186 3.190123 C 5.44186 1.42716 6.865116 0 8.602326 0 Z M 9.397674 1.259259 L 8.623256 1.259259 C 7.555814 1.259259 6.697674 2.140741 6.697674 3.190123 L 6.697674 6.044444 C 6.697674 7.114815 7.555814 7.975309 8.623256 7.996296 L 9.397674 7.996296 C 10.46512 7.996296 11.32326 7.114815 11.32326 6.065432 L 11.32326 3.190123 C 11.32326 2.119753 10.44419 1.259259 9.397674 1.259259 Z '
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ProfileIcon
