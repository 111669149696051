import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'

const MultipaneLayout = (props) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => getWindowDimensionsOnResize(setDimensions))

  const { mobileBreakpoint = 991 } = props
  const isMobile = dimensions.width < mobileBreakpoint
  const children = React.Children.toArray(props.children)
  const itemsComponent = children[0]
  const itemContentSidebar = children[1]
  const itemContentComponent = children[2]

  return (
    <div className='multipane-view'>
      <div className='item items-component-wrapper'>
        <div className='container'>
          {isMobile
            ? React.cloneElement(itemsComponent, { itemContentComponent })
            : itemsComponent}
        </div>
      </div>
      {!isMobile && (
        React.cloneElement(itemContentSidebar, { itemContentComponent })
      )}
    </div>
  )
}

function getWindowDimensionsOnResize (setDimensions) {
  const debouncedHandleResize = debounce(function () {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }, 1000)

  window.addEventListener('resize', debouncedHandleResize)

  return (_) => {
    window.removeEventListener('resize', debouncedHandleResize)
  }
}

function debounce (fn, ms) {
  let timer
  return (_) => {
    clearTimeout(timer)
    timer = setTimeout((_) => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

MultipaneLayout.propTypes = {
  mobileBreakpoint: PropTypes.number,
  children: PropTypes.node
}

export default MultipaneLayout
