import t from './translate.service'
import find from 'lodash/find'
import each from 'lodash/each'
import moment from 'moment'

export const buildValidators = (validators, submitted) => {
  const _validators = {}
  each(validators, (validators, field) => {
    if (!submitted) return null
    _validators[field] = (val, f) => {
      const faultyValidator = find(validators, validator => validator(val, f))
      return faultyValidator && faultyValidator(val, f)
    }
  })

  return _validators
}

export const passwordValidation = (val, label) => {
  if (val.search(/[a-z]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_LOWER_CASE_LETTER']
  }
  if (val.search(/[A-Z]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_CAPITAL_LETTER']
  }
  if (val.search(/[0-9]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_NUMBER']
  }
  // eslint-disable-next-line
  if (val.search(/[-!$%^&*()_+|~=`'"\\{}\[\]:\/;<>?,.@#]/) === -1) {
    return t['VALIDATE_EMPTY_MIN_ONE_SPECIAL_CHARACTER']
  }
}

export const checkPasswordResponse = (response) => {
  switch (response) {
    case 'wrong_password':
      return t['WRONG_PASSWORD_CHANGE_PASSWORD']
    case 'min_length':
      return t['VALIDATE_MIN_LENGTH']('Password', 10)
    case 'max_length':
      return t['VALIDATE_MAX_LENGTH']('Password', 50)
    case 'require_lower_case_letter':
      return t['VALIDATE_EMPTY_MIN_ONE_LOWER_CASE_LETTER']
    case 'require_capital_letter':
      return t['VALIDATE_EMPTY_MIN_ONE_CAPITAL_LETTER']
    case 'require_number_character':
      return t['VALIDATE_EMPTY_MIN_ONE_NUMBER']
    case 'LIMIT_EXCEEDED':
      return t['LIMIT_EXCEEDED']
    case 'require_special_character':
      return t['VALIDATE_EMPTY_MIN_ONE_SPECIAL_CHARACTER']
  }
}

export const checkEmailResponse = (response) => {
  switch (response) {
    case 'EMAIL_ALREADY_IN_USE':
      return t['EMAIL_IN_USE']
    default:
      return t['DAMAGE_REPORT_ON_ERROR']
  }
}

export const requiredCheckbox = (val, error) => {
  if (!val) {
    return error
  }
}

export const requiredFormField = (val, label) => !val && t['VALIDATE_FORM_FIELD'](label)
export const required = (val, label) => !val && t['VALIDATE_REQUIRED'](label)
export const requiredName = (val, label) => !val && t['VALIDATE_NAME'](label)

export const requiredLastName = (val, label) => !val && t['VALIDATE_LASTNAME'](label)

export const emptyPass = (val, label) =>
  !val && t['VALIDATE_EMPTY_PASSWORD'](label)

export const minCharWithoutSpace = len => (val, label) => {
  const regex = new RegExp('^(?:[^a-zA-Z0-9]*[a-zA-Z0-9]){' + len + '}')
  return val && !regex.test(val) && t['VALIDATE_MIN_LENGTH'](label, len)
}

export const minLength = len => (val, label) =>
  val && val.length < len && t['VALIDATE_MIN_LENGTH'](label, len)

export const maxLength = len => (val, label) =>
  val && val.length > len && t['VALIDATE_MAX_LENGTH'](label, len)

export const betweenLength = (min, max) => (val, label) => {
  if (val.length < min || val.length > max) return t['VALIDATE_BETWEEN_LENGTH'](label, min, max)
}

export function email(val, label) {
  if (!val) {
    return t['VALIDATE_EMAIL'](label)
  } else {
    // eslint-disable-next-line
    const r = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !r.test(val) && t['VALIDATE_EMAIL'](label)
  }
}

export const emailConfirm = email => (val, label) =>
  val && val !== email && t['VALIDATE_EMAIL_CONFIRM'](label)

export const emailChangeConfirm = email => (val, label) =>
  val && val !== email && t['VALIDATE_EMAIL_CHANGE_CONFIRM']

export function phone(val, label) {
  if (!val) {
    return t['PHONE_FORMAT']
  } else {
    const r = /^(\+|0)[0-9|(|)|-]{10,}$/
    return !r.test(val) && t['PHONE_FORMAT']
  }
}

export function phone2(val, label) {
  if (val) {
    const r = /^(\+|0)[0-9|(|)|-]{10,}$/
    return !r.test(val) && t['PHONE_FORMAT']
  }
}

export const password = passwordConfirm => (val, label) =>
  val && val !== passwordConfirm && t['VALIDATE_PASSWORD'](label)

export function birthday(val) {
  if (!val) return false
  return val && moment(val).format('YYYY/MM/DD') !== val && t['VALIDATE_DATE']
}
