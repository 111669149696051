import React, { Component } from 'react'
import t from '../../service/translate.service'
import PasswordForm from './PasswordForm'
import { observer } from 'mobx-react'
import PrimaryTitle from '../../components/PrimaryTitle'

class EditPassword extends Component {
  render () {
    return (
      <div>
        <PrimaryTitle title={t.PASSWORD_EDIT} />
        <PasswordForm />
      </div>
    )
  }
}

export default observer(EditPassword)
