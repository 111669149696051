import React from 'react'
import PrimaryTitle from '../components/PrimaryTitle'
import { Link } from 'react-router-dom'
import t from '../service/translate.service'
import historyService from '../service/history.service'

const HomepageOverlay = () => {
  return (
    <div className='homepage-overlay'>
      <PrimaryTitle title={t.OVERLAY_TITLE} />
      <div className='text-center'>
        <Link to='/contracts/add-contract' className='button primary' onClick={() => historyService.push('/contracts/add-contract')}>
          {t.ADD_A_CONTRACT_BUTTON}
        </Link>
      </div>
    </div>
  )
}

export default HomepageOverlay
