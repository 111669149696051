import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import t from "../service/translate.service";
import User from "../store/user.store";
import PrimaryTitle from "../components/PrimaryTitle";
import AlertCard from "../components/AlertCard";
import { extendObservable } from "mobx";
import { inboxCategories } from "../config";
import moment from "moment";
import RepairsAndIquiriesStore from "../store/repairsAndInquiries.store";
import { PropTypes } from "prop-types";
import { getAlertProperties } from "../service/alert.service";
import find from "lodash/find";
import IconsCloseSmall from "../assets/svg/iconsCloseSmall.svg";
import withRouter from "../components/withRouter";
import * as DOMPurify from "dompurify";
import TenantServicesList from "../components/TenantServicesList";
import configStore from '../store/config.store'

class Welcome extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props);
    extendObservable(this, {
      alerts: this.props.alertStore.alerts,
      schedules: this.props.alertStore.schedules,
    });

    User.getProfile();
    this.store = new RepairsAndIquiriesStore(this.props.configStore);
    this.store.getReports();
  }

  openReport = (id) => {
    const selectedReport = this.store.reports.filter((r) => r.id === id)[0];
    this.store.selectedReport = selectedReport;
    this.store.showResolved = selectedReport.statusId === 3;
    this.props.menuStore.setCurrentPage("/repairs-and-inquiries");
    this.props.navigate("/repairs-and-inquiries");
  };

  markAlertRead = (id) => {
    this.alerts = this.alerts.filter((a) => a.id !== id);
  };

  onClick = (link) => {
    this.props.menuStore.setCurrentPage(link);
    this.props.navigate(link);
  };
   navigateToPage(type, status){
    if(type === 'letter') this.props.navigate('/letters')
    if(type === 'oc_inspection') this.props.navigate('/receipt-inspection')
    if(status === 'emailVerification') this.props.navigate('/contracts')
  }

  prepareContent = (content) => {
    const regex =
      /(https?:\/\/[^\s<>"',]+(?<![.,]))|\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    const parts = content.split(regex);

    return parts.map((part, index) => {
      if (part.match(regex)) {
        return (
          <a
            key={index}
            href={part.startsWith("http") ? part : `mailto:${part}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        )
      }
      return <span key={index}>{part}</span>;
    });
  };

  render() {
    const hasPending = Boolean(
      find(User.contracts, (c) => !c.valid && c.active)
    );
    const { alerts, markAlertRead, markAllAlertsRead } = this.props.alertStore;

    return (
      <div>

        <PrimaryTitle title={t.WELCOME_TITLE} />

        <div className="homepage">
          <div>
            {alerts && alerts.find((alert) => alert.seen === false) && (
              <div className="clear-all-notifications-holder">
                <button
                  onClick={() => markAllAlertsRead()}
                  className="clear-all-notifications-btn"
                >
                  {t.CLEAR}
                </button>
              </div>
            )}
            {alerts && // TODO https://aroundtown.atlassian.net/browse/TEN-2593
              alerts.length > 0 &&
              alerts.some((alert) => alert.seen === false) && (
                <div className="alert-list">
                  {alerts.map((alert) => {
                    if (alert.seen) return;
                    const { statusName, statusClass } =
                      getAlertProperties(alert);
                    let content =
                      localStorage.getItem("locale") === "en"
                        ? alert.content
                        : alert.contentDe;
                    content = DOMPurify.sanitize(content);
                    const regEx =
                    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
                    const isUrl = regEx.test(content);
                    content = isUrl ? this.prepareContent(content) : content;
                    let sName = statusName
                    const foundCategory = inboxCategories.find((category) => category.type == statusName)
                    if (foundCategory) {
                      sName = foundCategory.name
                    }
                    return (
                      <div
                        className={
                          alert.type === "general" || alert.type === 'letter'
                            ? "itemGeneral notification-group"
                            : "item notification-group"
                        }
                        key={alert.id}
                      >
                        <div className={`${alert.type === 'letter' ? 'letters' : ''} notification`}>
                          <div
                            className="close"
                            onClick={() => {
                              markAlertRead(alert.id);
                            }}
                          >
                            <img src={IconsCloseSmall} />

                          </div>
                          <div className="content" onClick={() => this.navigateToPage(alert.type, alert.status)}>
                            <div className="message">
                              <div className="status">
                                <span className={`status ${statusClass}`}>
                                  {t[statusClass]}
                                </span>{" "}
                                {sName}
                              </div>
                              {!isUrl ? (
                                <div
                                  className="contract-number"
                                  dangerouslySetInnerHTML={{
                                    __html: content,
                                  }}
                                ></div>
                              ) : (
                                <div>
                                  <p>{content}</p>
                                </div>
                              )}
                              <div className="date">
                                {moment(alert.created_at)
                                  .lang(
                                    `${
                                      localStorage.getItem("locale") === "de"
                                        ? "de"
                                        : "en"
                                    }`
                                  )
                                  .format("dddd, MMMM Do YYYY, HH:mm")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            {hasPending && (
              <div className="item notification-group" key="0">
                <div className="notification">
                  <div className="content">
                    <label style={{ fontFamily: "parka-bold" }}>
                      {t.PENDING_CONTRACT}
                    </label>
                    <div className="message">{t.PENDING_CONTRACT_STICKY}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {this.props.menuStore.christmasGreeting && (
            <AlertCard
              message={this.props.menuStore.christmasGreeting}
              linkUrl={this.props.menuStore.linkUrl}
              linkText={this.props.menuStore.linkText}
            />
          )}
          <div className="text-center">
            <div className="title secondary">{t.QUICK_LINKS}</div>
          </div>

          <TenantServicesList
            menuStore={this.props.menuStore}
            receipts={this.props.receiptsStore.receipts}
            onClick={this.onClick}
            User={User}
          />
        </div>
      </div>
    );
  }
}

Welcome.propTypes = {
  menuStore: PropTypes.object.isRequired,
  alertStore: PropTypes.object.isRequired,
  receiptsStore: PropTypes.object.isRequired,
  contractStore: PropTypes.object.isRequired,
  configStore: PropTypes.object.isRequired
};

export default inject("menuStore", "alertStore", 'receiptsStore', 'contractStore', 'configStore')(withRouter(observer(Welcome)));
