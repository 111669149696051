import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PartnerCategoryForm from './PartnerCategoryForm'
import partnerCategoryStore from '../../../store/admin/PartnerCategory.store.js'
import propTypes from 'prop-types'
import withRouter from '../../../components/withRouter'

class PartnerCategoryEdit extends Component {
  constructor(props) {
    super(props)
    partnerCategoryStore[this.props.useParams.id === 'new' ? 'new' : 'load'](
      this.props.useParams.id
    )
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <PartnerCategoryForm store={partnerCategoryStore} id={this.props.useParams.id} />
        </div>
      </div>
    )
  }
}

PartnerCategoryEdit.propTypes = {
  params: propTypes.object
}

export default withRouter(observer(PartnerCategoryEdit))
