import { autorun, extendObservable } from 'mobx';
import { api } from '../service/api.service';
import { configSchema } from '../schemas/config-schema';

class ConfigStore {
  constructor() {
    extendObservable(this, {
      lettersEnabled: false,
      lettersEnabledForCondo: false,
      rentContractDocumentsEnabled: false,
      ocInspectionEnabled: false,
      ocCalculationsEnabled: false,
      pmOcInspectionEnabled: false,
      operationalCostPrepaymentEnabled: false,
      consultationsBookingEnabled: false,
      verificationMethodEmail: false,
      tenantNotificationsEnabled: false,
      adventCalendarEnabled: false,
      adventCalendarUrl: '',
      heatingAndEnergyEnabled: false, 
      loyaltyEnabled: false,
      srAmendmentImagesEnabled: false,
      digitalFormsEnabled: false,
      rentAccountEnabled: false,
    });
    autorun(() => {
      this.getConfig();
    });
  }

  async getConfig() {
    try {
      const response = await api.get('/api/config');
      const { settings } = response.data

      const { error, value: config } = configSchema.validate(settings, { abortEarly: false })
      const errorFields = error?.details.map(entry => entry.context.label)

      for (const field in config) { 
        if (errorFields?.includes(field)) {
          console.log(`ignoring field: "${field}"\nexpected different value type`)
          continue
        }
        this[field] = config[field]
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export default ConfigStore;
