import React, {useEffect, useState} from 'react'
import { observer } from 'mobx-react'
import { Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { Alert } from 'antd'
import ContractVerificationPage from '../Contracts/ContractVerificationPage'

const LoyaltyPaymentCashOut = observer(({ store, onCancel, onRedeem, goBack, emailVerificationEnabled, contractVerified }) => {
  // const { setIban, iban, email, password, setEmail, setPassword, toggleConfirmIban } = store
  const [verificationRequired, setVerificationRequired] = useState(false)
  const [showVerificationModal, setShowVerificationModal] = useState(false)
  const { showThankYou, cashOutError } = store

  useEffect(() => {
    if(!contractVerified && emailVerificationEnabled) setVerificationRequired(true)
  }, [])
  if(showVerificationModal) {
    return (
      <ContractVerificationPage isAddingContract={false} loyaltyContractNumber={store.model.contractNumber} redirectUrl={'/loyalty-program'} loyaltyCashOut={true}/>
    )
  }

  const onSubmit = () => {
    if(verificationRequired) setShowVerificationModal(true)
      else onRedeem()
  }

  if (showThankYou) {
    return (
      <ThankYouOverlay title={t.THANK_YOU}
        description={t.LOYALTY_PAYMENT_CASH_OUT_THANK_YOU}
        buttonText={t.BACK_HOME}
        buttonLink='/loyalty-program'
        handleClick={goBack}
      />
    )
  }

  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.LOYALTY_PAYMENT_CASH_OUT_TITLE} />
        <p>{t.LOYALTY_PAYMENT_CASH_OUT_TEXT1}</p>
        <p style={{ marginTop: '15px' }}>{t.LOYALTY_PAYMENT_CASH_OUT_TEXT2}</p>
      </div>
      {/* <Form onSubmit={(e) => onSubmit(e)} >
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Input
              value={iban}
              placeholder={t.LOYALTY_PAYMENT_IBAN}
              onChange={e => setIban(e.target.value)}
            />
            <Checkbox
             label={t['LOYALTY_PAYMENT_CASH_OUT_TEXT3']}
             onChange={toggleConfirmIban}
            />
            <p>{t.LOYALTY_PAYMENT_CASH_OUT_TEXT4}</p>
             <Input
             value={email}
             type='email'
              placeholder={t.USER_EMAIL}
              onChange={e => setEmail(e.target.value)}
            />
            <Input
              type='password'
              value={password}
              placeholder={t.USER_PASSWORD}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
        </Form.Group>
      </Form> */}
      <Button onClick={onSubmit} type='submit' className='button primary' style={{ alignSelf: 'center', marginTop: '20px' }}>{t.LOYALTY_PAYMENT_CASH_OUT_BTN}</Button>
      {cashOutError && <Alert message={cashOutError} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      <Button className='button tertiary' onClick={(e) => onCancel(e)}>{t.FORM_CANCEL}</Button>
    </div>
  )
})

export default LoyaltyPaymentCashOut
