import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import t from '../../../service/translate.service'
import { get as getUser } from '../../../store/users.store'
import { adminDeleteContract } from '../../../store/admin/AdminContract.store'
import ContractCard from '../../../components/ContractCard'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, Popconfirm, Modal } from 'antd'
import withRouter from '../../../components/withRouter'
import userListStore from '../../../store/admin/UserList.store'

class UserPage extends Component {
  constructor (props) {
    super(props)
    extendObservable(this, { user: null })
    getUser(props?.useParams?.id).then((u) => (this.user = u))
  }

  deleteUserContract (contract) {
    adminDeleteContract(contract, this.user.id).then(() => {
      getUser(this.user.id).then((u) =>  Object.assign(this.user, u))
    })
  }
  editContractData(data, id) {
    userListStore.error = false
    userListStore.editContractData(data).then(() => {
       getUser(data.userId).then((u) =>  Object.assign(this.user, u))
    })

  }

  changeStatusActive (id, status) {
    userListStore.changeActiveStatus(id, status).then(() => {
      getUser(this.user.id).then((u) => (this.user = u))
    })
  }

  resetLoyalty() {
    userListStore.resetLoyaltyRegistration(this.user).then(() => {
      getUser(this.user.id).then((u) => (this.user = u))
    })
  }

  resetRefreshToken(userId) {
    userListStore.resetRefreshToken(userId).then((response) => {
      getUser(this.user.id).then((u) => (this.user = u))
    })
  }

  resendEmailVerification (email) {
    userListStore.resendVerificationEmail(email).then(() => {
      getUser(this.user.id).then((u) => (this.user = u))
    })
  }

  deleteSelectedUser(id) {
    userListStore.deleteUser(id).then(() => {
      this.props.navigate('/admin/user')
    })
  }

  loginAsUser() {
    userListStore.onLoginAs(this.user.refreshToken).then(() => {
      window.location.reload(true);
    })
  }

  render() {
    if (!this.user) return null
    if(userListStore && userListStore.error ) {
      setTimeout(() => {
        userListStore.error = false
      }, 3000)
    }
    return (
      <div>
        <h1>
          {this.user.lastName}, {this.user.firstName}
        </h1>
        {userListStore.mailSent && <Modal
          open={userListStore.mailSent}
          onCancel={userListStore.closeModal}
          closable
          confirmLoading={userListStore.loading}
          className='modalSubjectWrapper'
          destroyOnClose
          footer={null}>{t[userListStore.message]}</Modal>}
        <div>
          <div className='adminUserSingleWrapper'>
          <div className='userProps'>
            <div>
              <div>{t.USER_EMAIL}:</div>
              <div>
                {this.user.email}{' '}
                {this.user.emailConfirmed ? '(confirmed)' : '(not confirmed)'}
              </div>
            </div>
            {this.user.phone && (
              <div>
                <div>{t.USER_PHONE}:</div>
                <div>{this.user.phone}</div>
              </div>
            )}
            {this.user.mobile && (
              <div>
                <div>{t.USER_MOBILE}:</div>
                <div>{this.user.mobile}</div>
              </div>
            )}
            {this.user.preferredLanguage && (
              <div>
                <div>{t.USER_PREFERRED_LANGUAGE}:</div>
                <div>{this.user.preferredLanguage}</div>
              </div>
            )}
            {this.user.birthday && (
              <div>
                <div>{t.USER_DATE_OF_BIRTH}:</div>
                <div>
                  {this.user.birthday &&
                    moment(this.user.birthday).format('DD/MM/YYYY')}
                </div>
              </div>
            )}
            <div>
            <div>Active:</div>
            <div>
                {this.user.active === true ? 'yes' : 'no'}
                </div>
            </div>
            <div>
            <div>Loyalty serial number:</div>
            <div>
                {this.user.loyaltySerialNumber}
                </div>
            </div>
            <div>
            <div>Signed up for loyalty:</div>
            <div>
                {this.user.registeredToLoyaltyProgram === true ? 'yes' : 'no'}
                </div>
            </div>
            <div>
            <div>Refresh token:</div>
            <div>
                {this.user.refreshToken.split('').map((char, index, arr) => {
                    const threshold = arr.length - 3
                    return index < threshold ? '*' : char
                })}
                </div>
            </div>
            </div>
            <div>
             <div className='adminUserActionBtn'>
             {!this.user.emailConfirmed && <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to resend the verification email to (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.resendEmailVerification(this.user.email)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>Resend verification email</Button>
                      </Popconfirm>}
                   </div>
                   <div className='adminUserActionBtn'>
                        <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to login as (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.loginAsUser()}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>Login as</Button>
                      </Popconfirm>
                  </div>
                 <div className='adminUserActionBtn'>
                        <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to delete this user (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.deleteSelectedUser(this.user.id)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>Delete</Button>
                      </Popconfirm>
                  </div>
                  <div className='adminUserActionBtn'>
                      <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to ${this.user.active === true ? 'dactivate': 'activate'} this user (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.changeStatusActive(this.user.id, !this.user.active)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>{this.user.active === true ? 'Deactivate' : 'Activate'}</Button>
                      </Popconfirm>
                </div>
                <div className='adminUserActionBtn'>
                      <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to reset loyalty for (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.resetLoyalty(this.user)}
                        okText='Yes'
                        cancelText='No'
                        disabled={this.user.loyaltySerialNumber ? false : true}
                      >
                        <Button disabled={this.user.loyaltySerialNumber ? false : true} className='ant-btn ant-sm'>Reset loyalty</Button>
                      </Popconfirm>
                </div>
                <div className='adminUserActionBtn'>
                      <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to reset refresh token for (${
                          this.user.firstName
                        } ${this.user.lastName})?`}
                        onConfirm={() => this.resetRefreshToken(this.user.id)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>Reset refresh token</Button>
                      </Popconfirm>
                </div>
               </div>
              </div>
           <div className='contract-list' style={{ marginTop: 20, marginBottom: 0 }}>
           {userListStore && userListStore.error &&
            <p className='editContractWarning'> {t.RESEND_VERIFICATION_EMAIL_ERROR}</p>}
            <h2 style={{ textAlign: 'left', marginBottom: 0 }}>Contracts</h2>
            {this.user.contracts.map((c) => (
              <div key={c?.id}>
                <div>
                  <ContractCard
                    deleteUserContract={this.deleteUserContract}
                    editContractData={this.editContractData}
                    user={this.user}
                    key={c.id}
                    isAdmin={true}
                    contract={c}
                    currContractNum={this.currContractNum}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

UserPage.propTypes = {
  params: PropTypes.object
}

export default withRouter(observer(UserPage))
