
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const BookingConfirmationModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => {
        props.closeAppointment('confirmation')
      }}
      footer={[
        <button
          style={{
            marginRight: '10px'
          }}
          className="button secondary small"
          key="no"
          onClick={async () => {
            props.closeAppointment('confirmation')
          }}
        >
          {t.CONSULTATIONS_NO}
        </button>,
        <button
          className="button primary small"
          key="yes"
          onClick={async () => {
            props.setShowTopicModal(true)
            props.setShowBookingConfiramtionModal(false)
          }}
        >
          {t.CONSULTATIONS_YES}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className={`${props.count === 0 ? 'error-message' : ''}`}>
          {props.bookingTimeLeftMessage}
        </p>
        <p className="modal-p">
          {`
            ${t.CONSULTATIONS_BOOKING_CONFIRMATION_1}
            ${props.date}
            ${t.CONSULTATIONS_BOOKING_CONFIRMATION_2}
            ${props.time}
            ${t.CONSULTATIONS_BOOKING_CONFIRMATION_3}
          `}
        </p>
      </div>
    </Modal>
  );
};

export default BookingConfirmationModal;
