import React from 'react'
import { Link } from 'react-router-dom'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Alert } from 'antd'

export const LettersUnregister = ({
   onHandleConfirm,
   onHandleCancel,
   loading,
   error
}) => {

  return (
      <div className='wrapper heating-energy-modal'>
        {loading && (
          <LoadingPageOverlay />
        )}
        <div className='description'>{t.LETTERS_UNREGISTRATION_TEXT}</div>
        {error && (
          <Alert message={error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
        )}
        <div className='btns-holder'>
        <Link to='' className='button primary' onClick={() => onHandleConfirm()}>{t.LOYALTY_SUBMIT_CODE}</Link>
        <Link className='button tertiary' to='' onClick={() => onHandleCancel()}>{t.HEATING_AND_ENERGY_CANCEL}</Link>
        </div>
      </div>
  )
}
LettersUnregister.propTypes = {
    onHandleConfirm: PropTypes.func,
    setLettersUnregiser: PropTypes.bool,
    onHandleCancel: PropTypes.func
}