import { extendObservable } from 'mobx'
import set from 'lodash/set'
import {
  buildValidators,
  required,
  email,
  emailChangeConfirm
} from '../service/validate'
import find from 'lodash/find'
import { api } from '../service/api.service'

class ChangeEmailStore {
  constructor () {
    extendObservable(this, {
      model: {
        email: '',
        emailConfirm: ''
      },
      status: '',
      submitted: false,
      loader: true,
      submitErr: null
    })
  }

  setVal = (field, val) => set(this.model, field, val)

  get validators () {
    return buildValidators(
      {
        emailConfirm: [
          required,
          emailChangeConfirm(this.model.email)
        ],
        email: [
          required,
          email
        ]
      },
      this.submitted
    )
  }

  submit () {
    this.submitted = true

    const isValid = !find(this.validators, (validate, field) =>
      validate(this.model[field])
    )
    if (!isValid) return

    return api
      .put('/api/user/email', { email: this.model.email, emailConfirm: this.model.emailConfirm })
  }
}

export default ChangeEmailStore
