import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Popconfirm, Button } from 'antd'
import adminAlertStore from '../../../store/admin/AdminAlert.store'

const paginationContainerStyle = {
  marginTop: 10
}

class InboxMessagesList extends Component {
  constructor (props) {
    super(props)
    adminAlertStore.getAll()
  }

  render () {
    return (
      <div>
        <h1>Inbox Messages</h1>
        <div style={{ ...paginationContainerStyle, marginBottom: '30px' }}>
          <Link to={'/admin/admin-alert/new'}>
            <Button className='ant-btn ant-btn-primary'>New Message</Button>
          </Link>
        </div>
        {(!adminAlertStore.loading || adminAlertStore.messages.length) && (
          <div>
            <table className='table'>
              <thead>
                <th>Content</th>
                <th>Content de</th>
                <th>Created at</th>
                <th>Created by</th>
                <th colSpan={2} />
              </thead>
              <tbody>
                {adminAlertStore.messages.map(c => (
                  <tr key={c.id}>
                    <td>{c.content}</td>
                    <td>{c.contentDe}</td>
                    <td>{new Date(c.created_at).toLocaleDateString('de-DE')}</td>
                    <td>{c.createdBy}</td>
                    <td>
                      <Link to={`/admin/admin-alert-details/${c.id}`}>Preview</Link>
                    </td>
                    <td>
                      <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to delete this message?`}
                        onConfirm={() => adminAlertStore.deleteAlert(c.id)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm'>Delete</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }
}

export default observer(InboxMessagesList)
