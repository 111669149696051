import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Popconfirm, Input, Button } from 'antd'
import t from '../../../service/translate.service'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import faqCategoryStore from '../../../store/admin/FaqCategory.store'

const paginationContainerStyle = {
  marginTop: 10
}

const queryInputStyle = {
  float: 'right',
  width: 300,
  marginTop: -40
}

class FaqCategoriesList extends Component {
  constructor (props) {
    super(props)

    faqCategoryStore.getAll()
  }

  getItemStyle = (draggableStyle, isDragging) => {
    return {
      userSelect: 'none',
      border: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e9e9e9',
      padding: '8px 20px',
      alignItems: 'center',
      background: 'white',
      boxShadow: isDragging ? '2px 2px 25px -10px black' : '',
      ...draggableStyle
    }
  }

  getListStyle = () => ({
    transition: 'background 0.2s ease-out'
  })

  onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    const [removed] = faqCategoryStore.faqCategories.splice(source.index, 1)
    faqCategoryStore.faqCategories.splice(destination.index, 0, removed)
    faqCategoryStore.faqCategories.map((faqCategory, index) => {
      faqCategory.position = index + 1
      return faqCategory
    })
  }
  render () {
    return (
      <div>
        <h1>Faq Categories</h1>
        <div style={queryInputStyle}>
          <div className='table-inputs'>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={faqCategoryStore.query}
              onChange={e => faqCategoryStore.updateQuery(e.target.value)}
            />
          </div>
        </div>
        {(!faqCategoryStore.loading || faqCategoryStore.faqCategories.length) && (
          <div>
            <div style={{ minWidth: 900 }}>
              <div className='table__header'>
                <div style={{ width: 130 }}>{t['PARTNER_NAME']}</div>
                <div style={{ width: 100, paddingLeft: 15 }}>Position</div>
                <div style={{ width: 60 }} />
                <div style={{ width: 60 }} />
              </div>
              <DragDropContext
                onDragEnd={this.onDragEnd}
              >
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >

                      {faqCategoryStore.faqCategories.length
                        ? faqCategoryStore.faqCategories.map((p, index) => (
                          <Draggable
                            index={index}
                            key={p.id}
                            draggableId={`${p.id}`}
                            isDragDisabled={faqCategoryStore.dragDisabled}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div>
                                  <div ref={provided.innerRef}
                                    {...provided.draggableProps} {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ width: 130 }}>
                                      <Link to={`/admin/faq-category/${p.id}`}>
                                        {p.nameDe}
                                      </Link>
                                    </div>
                                    <div style={{ width: 100, paddingLeft: 15 }}>{p.position}</div>
                                    <div style={{ width: 60 }}>
                                      <Link to={`/admin/faq-category/${p.id}`}>Edit</Link>
                                    </div>
                                    <div className='m-r-sm' style={{ width: 60 }}>
                                      <Popconfirm
                                        placement='topRight'
                                        title={`Are you sure you want to delete this faqCategory (${p.nameDe})?`}
                                        onConfirm={() => faqCategoryStore.deleteFaqCategory(p.id)}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Button className='ant-btn ant-sm'>Delete</Button>
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )
                            }}
                          </Draggable>
                        ))
                        : <div style={paginationContainerStyle}>No results</div>
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div style={paginationContainerStyle}>
                <Link to={'/admin/faq-category/new'}>
                  <Button className='ant-btn ant-btn-primary'>New Faq Category</Button>
                </Link>
                <Button
                  className='ant-btn ant-btn-secondary'
                  onClick={faqCategoryStore.saveOrder}
                >
                  Save reordering
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default observer(FaqCategoriesList)
