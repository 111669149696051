import React, { Component } from 'react'
import t from '../../service/translate.service'
import EmailForm from './EmailForm'
import { observer } from 'mobx-react'
import PrimaryTitle from '../../components/PrimaryTitle'

class EditEmail extends Component {
  render () {
    return (
      <div>
        <PrimaryTitle title={t.EMAIL_EDIT} />

        <EmailForm />
      </div>
    )
  }
}

export default observer(EditEmail)
