import React from 'react'

const DocumentsImage = (
  <svg width='91' height='103' viewBox='0 0 91 103'>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FFF'
        d='M0 0H375V1783H0z'
        transform='translate(-55 -1051)'
      />
      <path
        fill='#FFF'
        stroke='#ECEDEE'
        strokeWidth='1.5'
        d='M25.75 1027.75H174.25V1176.25H25.75z'
        transform='translate(-55 -1051)'
      />
      <g
        fillRule='nonzero'
        transform='translate(-55 -1051) translate(55 1051)'>
        <path
          fill='#415055'
          d='M36.1 44.1v-4.5c0-2.5-2-4.5-4.5-4.5H9.5c-2.5 0-4.5 2-4.5 4.5v58c0 2.5 2 4.5 4.5 4.5h76.2c2.5 0 4.5-2 4.5-4.5V53.2c0-2.5-2-4.5-4.5-4.5H40.6c-2.4 0-4.5-2.1-4.5-4.6z'
        />
        <path
          fill='#ECEDEE'
          d='M35.5 66.4l-.1-65.3c0-.3.2-.6.6-.6L87.4.4c.3 0 .6.2.6.6l.1 65.3c0 .3-.2.6-.6.6l-51.4.1c-.4 0-.6-.3-.6-.6z'
        />
        <path
          fill='#26F8AB'
          d='M40.9 5.7H82.69999999999999V18.8H40.9z'
          transform='rotate(-.108 61.8 12.25)'
        />
        <path
          fill='#A0A7AA'
          d='M31.8 44.1v-4.5c0-2.5-2-4.5-4.5-4.5H5.2c-2.5 0-4.5 2-4.5 4.5v58c0 2.5 2 4.5 4.5 4.5h76.2c2.5 0 4.5-2 4.5-4.5V53.2c0-2.5-2-4.5-4.5-4.5H36.3c-2.5 0-4.5-2.1-4.5-4.6zM45.3 24.9H78.4V25.9H45.3zM45.3 29.1H78.4V30.1H45.3zM45.3 33.2H78.4V34.2H45.3zM45.3 37.4H78.4V38.4H45.3z'
        />
        <path fill='#A0A7AA' d='M45.3 41.6H78.4V42.6H45.3z' />
        <circle cx='68.9' cy='65.6' r='9' fill='#FAB900' />
      </g>
    </g>
  </svg>)

export default DocumentsImage
