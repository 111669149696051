import get from 'lodash/get'

export function extractResponse (response) {
  return get(response, 'data')
}

export function extractError (err, defaultError = 'ERROR') {
  return get(err, 'response.data.message') || get(err, 'response.body.message') || defaultError
}

export function extractErrorFromSap (err) {
  const message = err.response?.data?.message
  if (message?.includes('(external)')) {
    return message
  }
  return null
}
