
import { useNavigate } from "react-router";
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const SuccessModal = (props) => {
  const navigate = useNavigate()

  return (
    <Modal
      open={true}
      closeIcon={false}
      closable={false}
      footer={[
        <button
          className="button primary small"
          key="ok"
          onClick={() => navigate('/')}
        >
          {t.CONSULTATIONS_OK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className="modal-p">
          {`
            ${t.CONSULTATIONS_SUCCESS_MESSAGE_1}
            ${props.date}
            ${t.CONSULTATIONS_SUCCESS_MESSAGE_2}
            ${props.time}
            ${t.CONSULTATIONS_SUCCESS_MESSAGE_3}
          `}
        </p>
      </div>
    </Modal>
  );
};

export default SuccessModal;
