import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Popconfirm, Spin, Input, Button } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import t from '../../../service/translate.service'
import partnerListStore from '../../../store/admin/PartnerList.store'

const paginationContainerStyle = {
  marginTop: 10,
  marginBottom: 20,
  display: 'flex'
}

const queryInputStyle = {
  float: 'right',
  width: 300,
  marginTop: -40
}

class PartnersList extends Component {
  constructor (props) {
    super(props)

    partnerListStore.getAll()
  }

  getItemStyle = (draggableStyle, isDragging) => {
    return {
      userSelect: 'none',
      border: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e9e9e9',
      padding: '8px 20px',
      alignItems: 'center',
      background: 'white',
      boxShadow: isDragging ? '2px 2px 25px -10px black' : '',
      ...draggableStyle
    }
  }

  getListStyle = () => ({
    transition: 'background 0.2s ease-out'
  })

  onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    const [removed] = partnerListStore.partners.splice(source.index, 1)
    partnerListStore.partners.splice(destination.index, 0, removed)
    partnerListStore.partners.map((partner, index) => {
      partner.position = index + 1
      return partner
    })
  }

  render () {
    return (
      <div>
        <h1>Partners</h1>
        <div style={queryInputStyle}>
          <div className='table-inputs'>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={partnerListStore.query}
              onChange={e => partnerListStore.updateQuery(e.target.value)}
            />
          </div>
        </div>
        <div style={{ minWidth: 900 }}>
          <div className='table__header'>
            <div style={{ width: 130 }}>{t['PARTNER_NAME']}</div>
            <div style={{ width: 130 }}>Image format</div>
            <div style={{ width: 130 }}>URL</div>
            <div style={{ width: 100, paddingLeft: 15 }}>Position</div>
            <div style={{ width: 60 }} />
            <div style={{ width: 60 }} />
          </div>
          {partnerListStore.loading
            ? <div className='spinner-container'><Spin /></div>
            : <div style={{ minHeight: 400 }}>
              <DragDropContext
                onDragEnd={this.onDragEnd}
              >
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >

                      {partnerListStore.partners.length
                        ? partnerListStore.partners.map((p, index) => (
                          <Draggable
                            index={index}
                            key={p.id}
                            draggableId={`${p.id}`}
                            isDragDisabled={partnerListStore.dragDisabled}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div>
                                  <div ref={provided.innerRef}
                                    {...provided.draggableProps} {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ width: 130 }}>
                                      <Link to={`/admin/partner/${p.id}`}>
                                        {p.name}
                                      </Link>
                                    </div>
                                    <div style={{ width: 130 }}>{p.format}</div>
                                    <div style={{ width: 130 }}>{p.url}</div>
                                    <div style={{ width: 100, paddingLeft: 15 }}>{p.position}</div>
                                    <div style={{ width: 60 }}>
                                      <Link to={`/admin/partner/${p.id}`}>Edit</Link>
                                    </div>
                                    <div className='m-r-sm' style={{ width: 60 }}>
                                      <Popconfirm
                                        placement='topRight'
                                        title={`Are you sure you want to delete this partner (${p.name})?`}
                                        onConfirm={() => partnerListStore.deletePartner(p.id)}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Button className='ant-btn ant-sm'>Delete</Button>
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )
                            }}
                          </Draggable>
                        ))
                        : <div style={paginationContainerStyle}>No results</div>
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div style={paginationContainerStyle}>
                <Link to={'/admin/partner/new'}>
                  <Button className='ant-btn ant-btn-primary m-r-sm'>New Partner</Button>
                </Link>
                <Button
                  className='ant-btn ant-btn-secondary'
                  onClick={partnerListStore.saveOrder}
                >
                  Save reordering
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default observer(PartnersList)
