import React, { Component } from "react";
import { extendObservable } from "mobx";
import { observer } from "mobx-react";
import user from "../../store/user.store";
import t from "../../service/translate.service";
import ErrorOverlay from "../../components/ErrorOverlay/ErrorOverlay";
import ThankYouOverlay from "../../components/ThankYouOverlay";
import withRouter from "../../components/withRouter";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";

class NewsletterSubscribeConfirm extends Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      error: null,
      loading: false,
      status: null,
    });
  }

  componentDidMount() {
    this.loading = true;
    const id = window.location.pathname.replace(
      "/confirm-newsletter-subscription/",
      ""
    );
    user
      .confirmNewsletterSubscription(id)
      .then((response) => {
        const { success, alreadySubscribed, mailjetError } = response;
        if (mailjetError) {
          this.status = "mailjetError";
        } else if (success && alreadySubscribed) {
          this.status = "alreadySubscribed";
        } else if (success && !alreadySubscribed) {
          this.status = "success";
        }
      })
      .catch((error) => {
        this.status = null;
        this.error = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  render() {
    if (this.loading) {
      return <LoadingPageOverlay />;
    }
    return (
      <div>
        {this.status === "success" && (
          <ThankYouOverlay
            title={t.THANK_YOU}
            description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION}
            buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          />
        )}
        {this.status === "alreadySubscribed" && (
          <ThankYouOverlay
            title={t.NEWSLETTER_SUBSCRIBED_ERROR}
            description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION_ALREADY_SUBSCRIBED}
            buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          />
        )}
        {this.status === "mailjetError" && (
          <ThankYouOverlay
            title={t.ERROR}
            description={t.CONFIRM_NEWSLETTER_SUBSCRIPTION_MAILJET_ERROR}
            buttonText={t.REGISTRATION_BACK_TO_LOGIN}
          />
        )}
        {this.error && (
          <ErrorOverlay
            buttonLink="/login"
          />
        )}
      </div>
    );
  }
}

export default withRouter(observer(NewsletterSubscribeConfirm));
