import React, { Component } from 'react'
import t from '../service/translate.service'
import { observer } from 'mobx-react'

const LanguagePicker = observer(
  class LanguagePicker extends Component {
    constructor (props) {
      super(props)
      this.state = {
        defVal: t.locale,
        languages: ['en', 'de']
      }

      this.selectLang = (
        <div>
          {this.state.languages.map(language => {
            return (
              <span
                key={language}
                className={t.locale === language ? 'active' : ''}
                onClick={e => this.handleSelectClick(language)}
              >
                {language.toUpperCase()}
              </span>
            )
          })}
        </div>
      )
    }

    handleSelectClick = e => {
      t.setLocale(e)
    }

    render () {
      return <div className='translateWrapper'>{this.selectLang}</div>
    }
  }
)

export default LanguagePicker
