import React from 'react'
import { ArrowRightIcon, ManagerIcon, TimeIcon, LocationIcon } from '../../components/icons'
import moment from 'moment'
import PropTypes from 'prop-types'

const ConsultationItems = ({ items, selectedItem, handleClick, itemContentComponent, isEnglish }) => {
  return (
    <div>
      <div className='list'>
        {items.map((item, index) => {
          const activeClass = item.date === selectedItem.date ? 'active' : ''
          const weekday = moment(item.date).format('dddd')
          const day = moment(item.date).format('D')
          const month = moment(item.date).format('MMM')
          const monthShort = isEnglish ? month : month.slice(0, -1)
          return (
            <div
              key={index}
              className='item'
              onClick={e => handleClick(item)}
            >
              <div className={`container ${activeClass}`}>
                <div className='summary'>
                  <div className='group'>
                    <div className='date'>
                      <div className='weekday'>{weekday}</div>
                      <div className='day'>{day}</div>
                      <div className='month'>{monthShort}</div>
                    </div>
                    <div className='details'>
                      <div className='interval'>
                        <div className='icon time'>
                          {TimeIcon}
                        </div>
                        <div className='value'>{item.timeFrom} - {item.timeTo}</div>
                      </div>
                      <div className='address'>
                        <div className='icon location'>
                          {LocationIcon}
                        </div>
                        <div className='value'>{item.location}</div>
                      </div>
                      <div className='managers'>
                        <div className='icon managers'>
                          {ManagerIcon}
                        </div>
                        <div className='value'>
                          {item.employees.map((employee, index) => (<span key={index}>{employee.partner_name}{index !== item.employees.length - 1 && ', ' }</span>)) }
                        </div>
                      </div>
                    </div>
                  </div>
                  {item.scheduled ? (
                    <div className='selected-icon'>
                      <div />
                    </div>
                  ) : (
                    <div className='icon next'>
                      {ArrowRightIcon}
                    </div>
                  )}
                </div>
                {itemContentComponent &&
                  React.cloneElement(itemContentComponent, { item })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ConsultationItems.propTypes = {
  items: PropTypes.object,
  selectedItem: PropTypes.object,
  handleClick: PropTypes.func,
  itemContentComponent: PropTypes.object,
  isEnglish: PropTypes.bool
}
export default ConsultationItems
