import React, { Component } from 'react'
import { observer } from 'mobx-react'
import ProfileForm from './ProfileForm'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import PrimaryTitle from '../../components/PrimaryTitle'
import withRouter from '../../components/withRouter'

class EditProfile extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <PrimaryTitle title={t.PROFILE_TITLE} />

        <ProfileForm />
      </div>
    )
  }
}

export default withRouter(observer(EditProfile))
