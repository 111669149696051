
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const CancelAppointmentConfirmationModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => props.setShowCancelConfiramtionModal(false)}
      footer={[
        <button
          style={{
            marginRight: '10px'
          }}
          className="button secondary small"
          key="no"
          onClick={() => props.setShowCancelConfiramtionModal(false)}
        >
          {t.CONSULTATIONS_NO}
        </button>,
        <button
          className="button primary small"
          key="yes"
          onClick={async () => {
            props.cancelEvent(props.contractNumber, props.eventId)
              .then(() => {
                props.setSuccessCanceled(true)
                props.setShowCancelConfiramtionModal(false)
              })
              .catch((err) => {
                console.log(err)
              })
          }}
        >
          {t.CONSULTATIONS_YES}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className="modal-p">
          {`
            ${t.CONSULTATIONS_CANCEL_APPOINTMENT_CONFIRMATION_1}
            ${props.date}
            ${t.CONSULTATIONS_CANCEL_APPOINTMENT_CONFIRMATION_2}
            ${props.time}
            ${t.CONSULTATIONS_CANCEL_APPOINTMENT_CONFIRMATION_3}
          `}
        </p>
      </div>
    </Modal>
  );
};

export default CancelAppointmentConfirmationModal;
