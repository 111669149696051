import { api } from '../../service/api.service'
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'

class AppSettingsStore {
  constructor () {
    extendObservable(this, {
      appSettings: [],
      model: {},
      error: null,
      count: 0,
      loading: false,
      query: '',
      formSubmittedSuccessfully: false,
      isSubmitted: false,

    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  async load (id) {
    this.loading = true

    try {
      const [appSettignsRes] = await Promise.all([
        api.get('/api/app-settings/' + id)
      ])

      const appSetting = extractResponse(appSettignsRes)
      const model = pick(appSetting, [
        'id',
        'name',
        'value',
        'type'
      ])

      model.stringValue = model.type === 'string' ? model.value : ''
      model.numberValue = model.type === 'number' ? model.value : ''
      model.booleanValue = model.type === 'boolean' ? model.value : 'false'
      model.oldType = model.type
  
      this.isSubmitted = false
      this.model = model
      this.loading = false
    } catch (err) {
      this.error = extractError(err)
      this.loading = false
    }
  }

  new () {
    this.isSubmitted = false
    this.model = {
      id: null,
      name: null,
      value: '',
      type: null,
      oldType: '',
      stringValue: '',
      numberValue: '',
      booleanValue: 'false'
    }
  }

  deleteAppSetting (id) {
    api.delete('/api/app-settings/' + id).then(() => {
      this.getAll()
    })
  }
  getAll () {
    this.loading = true
    api
      .get('/api/app-settings')
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.appSettings.replace(rows)
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    set(this.model, key, val)
  }

  save () {
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    const newValue = this.model.type === 'string' ? this.model.stringValue : this.model.type === 'number' ? this.model.numberValue : this.model.booleanValue
    return api
      .post(
        '/api/app-settings',
        omit({...this.model, value: String(newValue)}, ['id', 'stringValue', 'numberValue', 'booleanValue', 'oldType'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/app-settings/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    const newValue = this.model.type === 'string' ? this.model.stringValue : this.model.type === 'number' ? this.model.numberValue : this.model.booleanValue
    return api
      .post(
        '/api/app-settings' + path,
        omit({...this.model, value: String(newValue)}, ['id', 'stringValue', 'numberValue', 'booleanValue', 'oldType'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/app-settings/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }
}
export default new AppSettingsStore()
