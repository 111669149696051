import React from 'react'
import {
  Form,
  Input,
  Col,
  Checkbox,
  DatePicker,
  TimePicker,
  Select,
  AutoComplete
} from 'antd'
import map from 'lodash/map'
import defaults from 'lodash/defaults'
import t from '../service/translate.service'

const { TextArea } = Input

function former({ form, fields, def }) {
  return map(fields, (item, fi) =>
    buildItem({
      item,
      fi,
      form,
      def: defaults({}, def, {
        span: 24,
        labels: true
      })
    })
  )
}

function buildItem({ item, fi, form, def }) {
  let Element = null

  switch (item.element) {
    case 'TimePicker':
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <TimePicker
            placeholder={form.$(fi).label}
            style={{ width: '100%' }}
            name={form.$(fi).name}
            disabled={form.$(fi).disabled}
            format={'HH:mm'}
            value={form.$(fi).value}
            onChange={form.$(fi).sync}
          />
        </Form.Item>
      )
      break
    case 'DatePicker':
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <DatePicker
            placeholder={form.$(fi).label}
            style={{ width: '100%' }}
            name={form.$(fi).name}
            disabled={form.$(fi).disabled}
            format={'DD/MM/YYYY'}
            value={form.$(fi).value}
            onChange={form.$(fi).sync}
          />
        </Form.Item>
      )
      break
    case 'Checkbox':
      Element = (
        <Form.Item help={form.$(fi).error}>
          <Checkbox
            className='checkbox'
            name={form.$(fi).name}
            checked={!!form.$(fi).value}
            disabled={form.$(fi).disabled}
            onChange={form.$(fi).sync}
          >
            {form.$(fi).label}
          </Checkbox>
        </Form.Item>
      )
      break
    case 'Select':
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <Select
            placeholder={form.$(fi).label}
            type='text'
            disabled={form.$(fi).disabled}
            name={form.$(fi).name}
            value={form.$(fi).value}
            onChange={form.$(fi).sync}
          >
            {map(item.options, (v, k) => (
              <Select.Option key={k} value={v}>
                {t['OPTIONS_' + k]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )
      break
    case 'Textarea':
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <TextArea
            placeholder={form.$(fi).label}
            type='textarea'
            autosize={{ minRows: 4, maxRows: 16 }}
            name={form.$(fi).name}
            value={form.$(fi).value}
            disabled={form.$(fi).disabled}
            onChange={form.$(fi).sync}
          />
        </Form.Item>
      )
      break
    case 'AddressAutoComplete':
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <AutoComplete
            placeholder={form.$(fi).label}
            dataSource={form.$(fi).options}
            autosize={{ minRows: 4, maxRows: 16 }}
            name={form.$(fi).name}
            value={form.$(fi).value}
            disabled={form.$(fi).disabled}
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            onChange={(e, v) => {
              form.$(fi).sync(e, v)
            }}
          />
        </Form.Item>
      )
      break
    default:
      Element = (
        <Form.Item
          help={form.$(fi).error}
          label={def.labels && form.$(fi).label}
        >
          <Input
            placeholder={form.$(fi).label}
            type='text'
            disabled={form.$(fi).disabled}
            name={form.$(fi).name}
            value={form.$(fi).value}
            onChange={form.$(fi).sync}
          />
        </Form.Item>
      )
      break
  }

  return (
    <Col
      style={def.style}
      key={fi}
      className='form-col'
      xs={24}
      md={item.span || def.span}
    >
      {Element}
    </Col>
  )
}

export default former
