import React from 'react'

const FaqImage = (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3710 3710'>
    <style>
      {
        '.prefix__st0{fill:#0e6d5e}.prefix__st1{fill:#26f8ab}.prefix__st2{opacity:.63;fill:#fff}.prefix__st3{fill:#415055}.prefix__st4{fill:#ccc}.prefix__st5{fill:#ffc3bd}.prefix__st6{fill:#fbb916}'
      }
    </style>
    <g id='prefix__Illustration'>
      <path
        className='prefix__st0'
        d='M1963.1 486.8c-127-1-255.1 19.1-368.5 66.2-263.9 109.5-487.8 399.2-400.2 695.1 28.5 96.1 13.5 202.2 109.3 243.9 51.8 22.6 192.9 36.1 246.6 18.2 142.6-47.3 236.3-176.9 158.8-320.6-24.8-46-57-88-78.4-135.7-21.4-47.7-31.2-104.3-9.4-151.8 42.9-93.2 185.5-122.9 277.9-120.1 109.1 3.3 208 57.3 266.7 149.9 73.2 115.4 91.7 255.8 81 389.7-11.5 143.3-71.9 252.8-162.5 362.7-78 94.7-163.8 185.5-215.7 296.7-47.6 102-63.1 215.1-65.3 326.8-.4 21.6-108 225.6-107.6 287.9.1 8.8 122 38 130.9 38.1 45.9.5 176 2 223.9 2.5 9.8.1 18-7.8 18.1-17.6 3.4-303.1 96.6-592.1 332.6-795.6 85.9-74.1 178.4-134.4 245.1-227.9 95.2-133.3 142.1-264.4 128.6-428.8-12.4-150.2-61.8-300.5-159.7-417C2510.2 624 2356.2 545.8 2196.1 512c-74.7-15.7-153.6-24.6-233-25.2zM2197.3 2976c0 139.4-77.3 262.7-216.6 262.7-30.1 0-132.5.3-159.3-9.4-97.2-35.1-97.5-109.7-97.5-219 0-139.4 47.1-276.4 186.4-276.4s287 102.7 287 242.1z'
      />
      <path
        className='prefix__st1'
        d='M1842.4 455c-127-1-255.1 19.1-368.5 66.2-263.9 109.5-487.8 399.2-400.2 695.1 28.5 96.1 94.3 217.1 190 258.9 51.8 22.6 112.1 21.1 165.8 3.3 142.6-47.3 236.3-176.9 158.8-320.6-24.8-46-57-88-78.4-135.7s-31.2-104.3-9.4-151.8c42.9-93.2 185.5-122.9 277.9-120.1 109.1 3.3 208 57.3 266.7 149.9 73.2 115.4 91.7 255.8 81 389.7-11.5 143.3-71.9 252.8-162.5 362.7-78 94.7-163.8 185.5-215.7 296.7-47.6 102-63.1 215.1-65.3 326.8-.4 21.6 6.9 247.6 7.3 309.9.1 8.8 7.2 15.9 16 16 45.9.5 176 2 223.9 2.5 9.8.1 18-7.8 18.1-17.6 3.4-303.1 96.6-592.1 332.6-795.6 85.9-74.1 178.4-134.4 245.1-227.9 95.2-133.3 142.1-264.4 128.6-428.8-12.4-150.2-61.8-300.5-159.7-417-105.2-125.3-259.2-203.5-419.3-237.2-74.4-15.9-153.4-24.7-232.8-25.4z'
      />
      <circle className='prefix__st1' cx={1855.6} cy={2978.5} r={252.4} />
      <path
        className='prefix__st2'
        d='M1940 2814.9c-.5 0-1-.1-1.5-.4-25.9-13.1-53.8-19.8-83-19.8-1.8 0-3.2-1.5-3.2-3.2 0-1.8 1.4-3.2 3.2-3.2 30.2 0 59.1 6.9 85.9 20.5 1.6.8 2.2 2.8 1.4 4.4-.4 1.1-1.6 1.7-2.8 1.7zM1751.1 3136.7c-.6 0-1.3-.2-1.8-.6-52.5-35.5-83.8-94.4-83.8-157.7 0-70.1 38.4-134.4 100.3-167.6 1.6-.8 3.5-.3 4.4 1.3.8 1.6.3 3.5-1.3 4.4-59.7 32.1-96.8 94.1-96.8 161.9 0 61.1 30.3 118.1 81 152.3 1.5 1 1.9 3 .9 4.5-.9 1-1.9 1.5-2.9 1.5z'
      />
      <g>
        <path
          className='prefix__st3'
          d='M2282.7 1312.8l-137.2 140.3 164.5-44z'
        />
        <path
          className='prefix__st3'
          d='M3098.8 1370.9l-779.4 37.8c-20 1-36.8-15-36.8-35V1053c0-34.9 27.7-63.4 62.5-64.5l702.5-21.7c52.3 0 94.7 42.4 94.7 94.7v263.7c0 24.4-19.1 44.5-43.5 45.7z'
        />
        <path
          className='prefix__st4'
          d='M3034.7 1037.3l-623.2 23.3c-6.1.2-10.9 4.3-10.7 9l.2 5.5c.2 4.7 5.2 8.3 11.3 8.1l623.3-22.8c6.1-.2 11-4.3 10.7-9l-.3-6c-.2-4.7-5.2-8.3-11.3-8.1zM2978.9 1195.1l-623.2 23.2c-6.1.2-10.9 4.4-10.7 9.3l.2 5.7c.2 4.9 5.3 8.6 11.3 8.4l623.3-22.6c6.1-.2 10.9-4.4 10.7-9.3l-.3-6.3c-.1-4.9-5.2-8.6-11.3-8.4zM3051.7 1261.1l-694.8 26.2c-6.8.3-12.2 4.5-12 9.5l.2 5.7c.2 4.9 5.8 8.7 12.6 8.4l694.8-25.6c6.8-.3 12.2-4.5 12-9.5l-.3-6.3c-.1-4.9-5.7-8.7-12.5-8.4zM2693 1130.3l-339.7 9.9c-3.3.1-5.8 4.7-5.6 10.2l.3 6.4c.3 5.5 3.1 9.8 6.5 9.7l339.7-9.2c3.3-.1 5.8-4.7 5.6-10.2l-.3-7c-.3-5.5-3.2-9.9-6.5-9.8zM3067.3 1118.6l-321.1 9.2c-3.2.1-5.5 4.7-5.2 10.2l.3 6.4c.3 5.5 3 9.8 6.1 9.7l321.1-8.5c3.2-.1 5.5-4.7 5.2-10.2l-.3-7c-.2-5.6-3-9.9-6.1-9.8z'
        />
      </g>
      <g>
        <path
          className='prefix__st3'
          d='M1099.2 3139.5s-6.4 36.4-9.6 72.4c-1.2 14.1 9.6 26.3 23.7 26.9 47.9 2 150.5 5.9 187.3 4.2 5.5-.2 9.1-5.9 7.1-11.1-1.7-4.3-5-7.8-9.2-9.7l-169.4-77.9-29.9-4.8z'
        />
        <path
          className='prefix__st5'
          d='M1093.9 3118.1l5.2 43.5s29.7 13.8 49.9-1.7l-12.3-48.5-42.8 6.7z'
        />
        <path
          className='prefix__st3'
          d='M1097.8 2300.2c-2.2 11.4-12 566.9 47.4 815.9 1 4.1-1.1 8.2-5 9.8-9.1 3.7-27 9.1-48.4 9.8-6.6.2-12.6-4.1-14.4-10.4-17-57.8-98.3-347.2-108.9-623.7-11.9-308.9 0-10.5 0-10.5l7-191 122.3.1zM904.3 3158.4s-10.2 18.4-20.1 67.2c-1.8 8.8 4.4 17.2 13.3 18.1 15.8 1.6 40.2 3.6 59.7 3.4 9-.1 16-7.9 14.9-16.8-2.2-17.8-8.9-47-29.7-69.8l-38.1-2.1z'
        />
        <path
          className='prefix__st5'
          d='M895.3 3102.5l12.1 65.8s11.9 8.9 35.5 2.4l-5.8-70.8-41.8 2.6z'
        />
        <path
          className='prefix__st3'
          d='M862.8 2317.2c-48.6 293.1 10.5 724.5 30.5 808.9 1.2 5.2 5.4 9.1 10.6 10 8.9 1.5 23.5 2.7 38-1.5 5-1.4 8.5-5.9 8.7-11.1 2.7-68.3-2.1-487.3 43.1-652.9 2.3-8.4 7.4-15.7 14.5-20.8l87.8-62.4 6.7-100.9-239.9 30.7z'
        />
        <path
          className='prefix__st6'
          d='M1172.2 1761.8C1026.6 1759.4 866 1800 866 1800c-43.4 253.2-7.6 545.2-7.6 545.2 112.7 29.6 239.8-18.1 239.8-18.1 18.7-105.8 106.1-403.6 106.1-403.6 71.1-8.8 129.7-29.3 177.9-56.5-36.8-42.7-54.1-96.9-61.7-129-51.3 16-103.4 24.6-148.3 23.8z'
        />
        <path
          className='prefix__st5'
          d='M1602.2 1547.4c-14.3-17.3-32.4-9.1-32.4-9.1-21.4 90.5-133.7 163.8-249.1 199.7 7.5 32.1 24.9 86.4 61.7 129 191.4-107.6 219.8-319.6 219.8-319.6z'
        />
        <path
          className='prefix__st5'
          d='M1596.7 1553.8c1.5 4.8-15.8 12-26-7.5-.4-9.3-6-49.3-.5-56.9 29.7-40.5 87.2-91.2 87.2-91.2 9.7 9.6 1.7 19 1.7 19l-27.9 29.1c5.8 10.5 13 19.5 16.3 32.2 2.5 9.7 1 20-3.9 28.8s-11.6 20.3-17.9 29.3c-11.9 17.1-29 17.2-29 17.2z'
        />
        <path
          className='prefix__st6'
          d='M921.9 1941l-55.8-140.9s-136 92.4-158.3 209.5c25.7 1.9 72.4 8.6 118.2 31.7 10-55.4 95.9-100.3 95.9-100.3z'
        />
        <path
          className='prefix__st5'
          d='M1040.7 2073.6s-152.6 86.3-204.1 12.6c-10.7-15.3-13.2-30.5-10.6-45-45.8-23.1-92.5-29.8-118.2-31.7-6.7 35.3-3.2 72.9 17.6 110.8 90.2 165 335.6-9.1 335.6-9.1l-20.3-37.6z'
        />
        <path
          className='prefix__st3'
          d='M1022.4 2182.8l83.3-290.9c.9-3.3 3.8-5.7 7.2-6.1 15.1-1.8 58.8-6.7 109.3-11 60.8-5.1 131.5-9.3 173.7-5.8 6 .5 9.6 6.9 7 12.2-24 48.6-122.7 246.1-172.2 310.8-1.6 2.1-4.2 3.4-6.9 3.4l-193.3-1.8c-5.6 0-9.7-5.4-8.1-10.8z'
        />
        <path
          className='prefix__st4'
          d='M1230.8 1849.5l60.4-10.1c3.2-.5 5.9 2.5 4.8 5.6l-6 18.1c-.5 1.6-1.9 2.7-3.5 2.9l-58.1 7.8c-3 .4-5.5-2.3-4.8-5.3l3.7-15.8c.4-1.6 1.8-2.9 3.5-3.2z'
        />
        <path
          className='prefix__st4'
          d='M1185.6 1900.6l5-25.9c.5-2.6 2.6-4.6 5.3-4.8l122-12.4c4.1-.4 7.4 3.3 6.4 7.4l-7.8 32.3c-.6 2.6-2.9 4.5-5.5 4.6l-119.2 6c-3.9.2-6.9-3.3-6.2-7.2z'
        />
        <path
          className='prefix__st5'
          d='M1042.5 2112.5s-11.6-47.7-6.9-64.6c4.7-16.9 13.8-38.7 44-33.9 30.2 4.8 65.9 17.6 65.9 17.6s-1.8 22.1-24.8 14.9l14.2 11s-2 17.7-21.4 8.2l13.3 14.6s-8.3 14.5-22.6 5.2c-14.4-9.3-21.2 32.7-61.7 27zM1024.8 1643.7l-46.3 133.9c-.8 2.2.1 4.7 2.2 5.8 12.6 7 58.1 27.2 112.9-7.6 5.7-3.6 9.6-9.5 10.5-16.2l15-115.9h-94.3z'
        />
        <g>
          <path
            className='prefix__st5'
            d='M1125.6 1519.6s50.9 95.7 44.3 134.4c-6.5 38.7-69.5 35.9-124.6 4.5l-38.5-103.4 118.8-35.5z'
          />
          <path
            className='prefix__st3'
            d='M1047.8 1607.8c2.2-1 3.1-3.7 1.7-5.8-4.1-6.2-11.7-18.9-14.1-30 0 0-9.1-5.9 23.3-13.6 32.4-7.8 80.7-19.8 90.9-47.4 9.9-26.9-16.1-94.5-57.6-40.6-1.6 2.1-4.7 2.1-6.3 0-6.8-8.9-24.7-27.2-49.5-16.1-23.3 10.4-25.9 27.5-25.5 36.1.1 2.5-2.2 4.5-4.6 4-11.8-2.2-40-4.2-53.1 24.8-10.8 23.8-3.8 37.3 2.6 43.9 2.1 2.1 1.2 5.7-1.7 6.5-7.7 2.4-17.7 8.9-16.7 27 1.4 25 29.4 22.8 37.3 21.7 1.3-.2 2.6.3 3.5 1.2 6.8 7.6 37.6 39.7 70.9 43.5 2.9.3 5.1-2.5 4.2-5.3l-14.4-41.9c-.7-1.9.3-4.1 2.1-4.9l7-3.1z'
          />
          <path
            className='prefix__st5'
            d='M1053.6 1613.7s-36.2-24.8-38.9-3.9c-2.6 20.9 32.6 30.3 50.1 22.5l-11.2-18.6z'
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FaqImage
