import Joi from 'joi';

export const configSchema = Joi.object({
  adventCalendarEnabled: Joi.boolean(),
  adventCalendarUrl: Joi.string().uri(),
  digitalFormsEnabled: Joi.boolean(),
  heatingAndEnergyEnabled: Joi.boolean(),
  lettersEnabled: Joi.boolean(),
  lettersEnabledForCondo: Joi.boolean(),
  loyaltyEnabled: Joi.boolean(),
  ocCalculationsEnabled: Joi.boolean(),
  ocInspectionEnabled: Joi.boolean(),
  operationalCostPrepaymentEnabled: Joi.boolean(),
  pmOcInspectionEnabled: Joi.boolean(),
  rentAccountEnabled: Joi.boolean(),
  rentCertificatePaidEnabled: Joi.boolean(),
  rentCertificatePostalEnabled: Joi.boolean(),
  rentContractDocumentsEnabled: Joi.boolean(),
  srAmendmentImagesEnabled: Joi.boolean(),
  verificationMethodEmail: Joi.boolean(),
  verificationMethodEmailEnabled: Joi.boolean(),
  verificationMethodEmailJwt: Joi.string(),
  verificationMethodEmailLifetime: Joi.number().integer(),
}).unknown(true);
