import React, { Component } from 'react'
import { extendObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import user from '../../store/user.store'
import t from '../../service/translate.service'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { PropTypes } from 'prop-types'
import withRouter from '../../components/withRouter'

class EmailRevoked extends Component {
  constructor(props) {
    super(props)
    extendObservable(this, {
      status: ''
    })
  }

  componentDidMount() {
    const hash = window.location.pathname.replace('/revoke-change-email/', '')

    user.revokeChangeEmail(hash).then(res => {
      if (res.data === 'Invalid hash') {
        this.status = 'Invalid hash...'
      } else this.status = 'revoked'
      setTimeout(() => {
        this.props.navigate('/')
      }, 5000)
    })
      .catch(err => {
        this.status = 'Invalid hash...'
        throw new Error(err)
      })
  }

  render() {
    return (
      <div>
        {
          this.status.length > 0 && this.status === 'revoked' && <ThankYouOverlay
            title={t.THANK_YOU}
            description={t.REVOKE_EMAIL_THANK_YOU_TEXT}
            buttonText={t.BACK_HOME}
            buttonLink='/'
          />
        }
        {
          this.status.length > 0 && this.status !== 'revoked' && <ThankYouOverlay
            title={t.DAMAGE_REPORT_ON_ERROR}
            description={t.CHANGE_EMAIL_INVALID_HASH}
            buttonText={t.BACK_HOME}
            buttonLink='/'
          />
        }
      </div>
    )
  }
}

EmailRevoked.propTypes = {
  router: PropTypes.object
}

export default withRouter(observer(EmailRevoked))
