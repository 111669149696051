import React from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import './mobileRedirectPopUp.css'
import LogoImage from '../../assets/gcp-app-logo.png'

const MobileRedirectPopUp = (props) => {
  return (
    <div style={{ display: `${props.isMobile ? 'flex' : 'none'}` }} className='mobile-redirect-popup-holder'>
      <button className='close-popup-btn' type='button' onClick={() => props.setIsOpen()}>
        ×
      </button>
      <div className='mobile-redirect-popup-logo'>
        <img src={LogoImage} alt='logo' />
      </div>
      <p className='mobile-redirect-popup-text'>{t.REDIRECT_TO_MOBILE}</p>
      <a className='mobile-redirect-link' href={`${props.mobileRedirectPopUpStore.link}`}>{t.REDIRCT_TO_MOBILE_LINK}</a>
    </div>
  )
}

MobileRedirectPopUp.propTypes = {
  mobileRedirectPopUpStore: PropTypes.object
}

export default inject('mobileRedirectPopUpStore')(observer(MobileRedirectPopUp))
