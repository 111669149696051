import { api, API_BASE } from '../service/api.service'

const USER_ENDPOINT = `${API_BASE}/api/user`

function getAll () {
  return api.get(USER_ENDPOINT)
    .then(res => res.data)
}

function remove (uId) {
  return api.del(USER_ENDPOINT + '/' + uId)
    .then(res => res)
}

function approve (uId) {
  return api.put(`${USER_ENDPOINT}/${uId}/approve`)
    .then(res => res)
}

function save (uId, user) {
  return api.put(`${USER_ENDPOINT}/${uId}`, user)
    .then(res => res)
}

function disapprove (uId) {
  return api.put(`${USER_ENDPOINT}/${uId}`, { active: false })
    .then(res => res)
}

function get (uId) {
  return api.get(`${USER_ENDPOINT}/${uId}`)
    .then(res => res.data)
}

export { get, getAll, remove, approve, disapprove, save }
