import { useLocation, useNavigate, useParams, /* other hooks */ } from 'react-router-dom'; 
import history from '../service/history.service';

const withRouter = WrappedComponent => props => {
  const navigateFn = useNavigate();
  const location = useLocation()
  const navigate = (path) => {
    navigateFn(path)
    history.push(path)
  }
  // other hooks

  return (
    <WrappedComponent
      {...props}
      {...{ navigate, useParams: useParams(), location /* other hooks */ }}
    />
  );
};

export default withRouter;