import React from 'react'

const MaintenanceImage = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 500 500'
    style={{
      enableBackground: 'new 0 0 500 500'
    }}
    xmlSpace='preserve'
  >
    <style>
      {
        '.st2{fill:#9ba9b2}.st3{fill:#ffae73}.st5{fill:#2c3c43}.st6{fill:#fbba00}.st7{fill:#3a4d55}'
      }
    </style>
    <g id='Objects'>
      <path
        className='st2'
        d='M175.9 163.1h-12.5c-1.2 0-2.2.6-2.8 1.6l-6.2 10.8c-.6 1-.6 2.2 0 3.3l6.2 10.8c.6 1 1.7 1.6 2.8 1.6h12.5c1.2 0 2.2-.6 2.8-1.6l6.2-10.8c.6-1 .6-2.2 0-3.3l-6.2-10.8c-.6-.9-1.6-1.6-2.8-1.6z'
      />
      <path
        className='st2'
        d='M254.5 199.8v-45.3l-14-4.4c-.5-1.3-1.1-2.7-1.6-4l6.8-13-32-32-13 6.8c-1.3-.6-2.6-1.1-4-1.6l-4.4-14H147l-4.4 14c-1.3.5-2.7 1.1-4 1.6l-13-6.8-32 32 6.8 13c-.6 1.3-1.1 2.6-1.6 4l-14 4.4v45.3l14 4.4c.5 1.3 1.1 2.7 1.6 4l-6.8 13 32 32 13-6.8c1.3.6 2.6 1.1 4 1.6l4.4 14h45.3l4.4-14c1.3-.5 2.7-1.1 4-1.6l13 6.8 32-32-6.8-13c.6-1.3 1.1-2.6 1.6-4l14-4.4zm-51.2-22.6c0 18.6-15.1 33.7-33.7 33.7s-33.7-15.1-33.7-33.7 15.1-33.7 33.7-33.7c18.7 0 33.7 15.1 33.7 33.7z'
      />
      <path
        className='st3'
        d='M313.3 185.4c3.1 4 6.1 8 8.9 12.1 2.8 4.1 5.6 8.4 8.2 12.8.6 1.1 1.3 2.2 1.9 3.3 1.1 2 1.4 3.9 1.6 5.5.2 3.3-.2 6-.8 8.7-1.3 5.3-3.2 10-5.6 14.6l-5.1-2c1-4.6 1.9-9.5 2.2-14 .2-2.2.2-4.5-.2-6.1-.2-.8-.4-1.4-.6-1.6-.7-1-1.3-2-2-3-2.7-4-5.6-7.9-8.5-11.8l-8.7-11.8 8.7-6.7z'
      />
      <path
        className='st3'
        d='m321.9 239.8-2.7 4.8 9.3 1.4s1-4-1.3-6.8l-5.3.6z'
      />
      <path
        style={{
          fill: '#ff8b7b'
        }}
        d='m317.9 250.9 7.7.8 2.9-5.8-9.2-1.3z'
      />
      <path
        className='st5'
        d='m343 279.3 2.8-14.7c.7-3.5 4.1-5.9 7.6-5.2l23.2 4.3c-3.2-10.6-12-19.1-23.7-21.3-8.4-1.6-16.5.4-23 4.9l-105.1-62.2c1.3-15.5-9.3-29.7-24.9-32.6-11.7-2.2-23 2.5-29.8 11.3l23.2 4.3c3.5.7 5.9 4.1 5.2 7.6l-2.8 14.7c-.7 3.5-4.1 5.9-7.6 5.2l-23.2-4.3c3.2 10.6 12 19.1 23.7 21.3 8.5 1.6 16.8-.5 23.3-5.1l104.9 62.1c-1.4 15.6 9.2 30 24.8 32.9 11.7 2.2 23-2.5 29.8-11.3l-23.2-4.3c-3.5-.6-5.9-4.1-5.2-7.6z'
      />
      <path
        className='st3'
        d='M297.3 160.8c-1.1 5.4-2.1 15.2 1.7 18.8 0 0-1.5 5.5-11.5 5.5-11 0-5.2-5.5-5.2-5.5 6-1.4 5.8-5.9 4.8-10.1l10.2-8.7z'
      />
      <path
        className='st6'
        d='m304 183.6-.3-.6c-.8-3.2-4.4-5.7-9-5.8-4.8-.1-10.3.5-13.5 3.1-1.4 1.2.2 2.9.2 2.9l22.6.4z'
      />
      <path
        className='st3'
        d='M277 408.7h-7.4l-.7-17h7.3zM348.4 377l-5.9 4.3-14.1-10.8 6-4.3z'
      />
      <path
        className='st5'
        d='m341.6 381.3 5.7-6c.2-.2.5-.3.8-.1l5.7 3.5c.6.4.7 1.2.2 1.7-2 2-3.1 2.9-5.6 5.6-1.5 1.6-5.8 7.3-7.9 9.5-2.1 2.2-4.6.4-3.9-.7 3-4.9 4.5-9.8 4.5-12.3 0-.5.2-.9.5-1.2zM269.7 407.9h8c.3 0 .6.2.6.5l1.5 6.5c.2.7-.4 1.3-1.1 1.3-2.9 0-7.1-.2-10.7-.2-4.3 0-7.9.2-12.9.2-3 0-3.9-3-2.6-3.3 5.7-1.3 10.4-1.4 15.4-4.5.5-.3 1.1-.5 1.8-.5z'
      />
      <path
        className='st6'
        d='M303.1 195.1c1 1.9 2.1 3.6 3.2 5.3 1.9 2.9 3.7 5.4 5 7.1 1.2 1.6 1.9 2.5 1.9 2.5l14.8-10.1s-9-17.5-15.9-18.6c-7.2-1.2-13.7 5.5-9 13.8z'
      />
      <path
        className='st6'
        d='M306.3 200.4c1.9 2.9 3.7 5.4 5 7.1-.1-3.4-.9-9-3.5-13.2-1 .9-1.4 3.4-1.5 6.1z'
      />
      <path
        className='st6'
        d='M267.3 205.6c0 2.5.1 5.2.2 8.3.4 8.2 1.3 18.5 3 31.3h39.3c.3-6-3.5-35.5 2.3-63.9 0 0-6.7-1.3-13.2-1.7-5-.2-12.3-.4-16.7 0-5.8.5-11.3 2-11.3 2s-3.6 4.1-3.6 24z'
      />
      <path
        className='st6'
        d='M267.3 205.6c0 2.5.1 5.2.2 8.3 4.6-4.5 4.9-13.7 4.9-13.7l-5.1 5.4z'
      />
      <path
        className='st3'
        d='M275.5 194.2c-.9 1.5-1.7 2.5-2.5 3.6s-1.7 2.1-2.5 3.1c-1.7 2-3.5 3.9-5.3 5.8-3.7 3.7-7.7 7.2-12.4 10.2-1.2.7-2.4 1.4-3.8 2.1l-.5.2-.3.1c-.1 0-.2.1-.3.1-.5.2-1 .4-1.5.5l-.7.2-.7.1c-.5.1-.9.1-1.3.1-3.5.2-6.2-.5-8.9-1.3-5.3-1.6-9.9-3.9-14.3-6.7l2.4-5c4.6 1.3 9.4 2.5 13.9 3.2 2.2.3 4.4.4 6 .1.2 0 .4-.1.5-.1l.2-.1.2-.1c.1 0 .2-.1.2-.1l.6-.3c.9-.5 1.8-1.1 2.7-1.8 3.6-2.6 7-5.9 10.2-9.3 1.6-1.7 3.2-3.5 4.7-5.3.8-.9 1.5-1.8 2.2-2.7.7-.9 1.4-1.8 1.8-2.5l9.4 5.9z'
      />
      <path
        className='st3'
        d='m223.8 208.8-4.9-5.8-3.3 8.5s4.2 2 7.5.8l.7-3.5z'
      />
      <path className='st3' d='m211.8 201.7-1.4 6.8 5.2 3 3.3-8.5z' />
      <path
        className='st6'
        d='M281.4 190.2c-.9 7-16.8 21.7-16.8 21.7l-14.3-11.3s5.7-8.6 12.5-14.5c10.4-9 19.6-4.3 18.6 4.1z'
      />
      <path
        className='st3'
        d='M297.5 156.1c-1.6 7.1-2.1 11.4-6.3 14.5-6.3 4.7-14.7 0-15.3-7.4-.5-6.7 2.1-17.2 9.6-19 7.3-1.7 13.5 4.8 12 11.9z'
      />
      <path
        className='st5'
        d='M303.8 145.1c6.2.2 3.7 3.5.5 5.3 0 0 3.6 1.7 1.7 5.9-2.8 6.3-8.2 13.2-13.7 12.8-6.9-.5-8.8-7.9-8.6-15.3-3.6-3.4-4.2-5.3-4.8-9.4-1-8.2 4.1-12.7 24.9.7z'
      />
      <path
        className='st3'
        d='M287.6 155.5c-.2 1.9-1.1 3.7-2.2 4.8-1.7 1.7-3.3.6-3.5-1.6-.2-1.9.4-5.2 2.4-6.1 1.9-.9 3.5.7 3.3 2.9z'
      />
      <path
        className='st7'
        d='M284.4 245.1s-.8 52.3 5.7 72.8c10.4 32.5 41.8 58.8 41.8 58.8l11.2-8.1s-26.2-22.2-30.9-49.6c-4.3-24.6-2.5-53.8-2.5-73.9h-25.3z'
      />
      <path
        className='st7'
        d='m327.2 374.3 4.6 2.8 12.3-8.9-4.1-3.1-12.8 9.2zM270.5 245.1s-11.1 46.9-11.8 69.3c-.9 25.1 7.9 84.4 7.9 84.4H279s1.5-59.7 2.1-82.6c.6-24.9 15.2-71.1 15.2-71.1h-25.8z'
      />
      <path
        className='st7'
        d='M264.1 393.8c-.1 0 .7 5.2.7 5.2H280l.4-4.6-16.3-.6z'
      />
    </g>
  </svg>
)

export default MaintenanceImage
