import React, {Component} from 'react';
import pdfSvg from "../../assets/svg/pdf.svg";
import icTmpPreview from "../../assets/ic-tmp-preview.png";
import icTmpDownload from "../../assets/ic-tmp-download.png";
import t from "../../service/translate.service";
import NoContent from "../../components/NoContent";
import {ContractsImage} from "../../components/icons";
import {saveAs} from 'file-saver';
import {Link} from "react-router-dom";
import withRouter from "../../components/withRouter";
import {observer} from "mobx-react";
import {api, API_BASE} from "../../service/api.service";
import {extendObservable} from "mobx";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import moment from 'moment';

class OcCalculations extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      contractNumber: null,
      loading: false,
      error: false,
      documents: [],
    });
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    const {contractNumber} = this.props.useParams;
    this.contractNumber = contractNumber;
    this.getDocuments();
  }

  async getDocument(docName, docId) {
    const res = await api.get(`${API_BASE}/api/contract/${this.contractNumber}/documents/ZOC_CALCULATION/${docId}`, {
      responseType: 'blob',
    })
    return res.data
  }

  async getDocuments() {
    try {
      this.loading = true;
      const res = await api.get(`${API_BASE}/api/contract/${this.contractNumber}/documents/ZOC_CALCULATION`);
      this.documents = res.data;
      this.loading = false;
    } catch (err) {
      this.loading = false;
      this.error = 'Something went wrong';
    }
  }

  downloadDocument = async (document) => {
    const file = await this.getDocument(document?.name, document?.id);
    saveAs(file, document.name, {});
  }

  render() {
    return (
      <div>
        {this.loading && <LoadingPageOverlay/>}
        <div className='documents-list' style={{marginBottom: '30px'}}>
          {
            this.documents.length > 0 || this.loading ? (
              this.documents.map((r, i) => {
                return (
                  <div key={i} className='item'>
                    <div className='container'>
                      <div className='details'>
                        <div className='svg'>
                          <img src={pdfSvg} alt='PDF'/>
                        </div>
                        <div className='group'>
                          <div className='label'>{r.name}</div>
                        </div>
                        <div className="group">
                        <div className="label">{moment(r.createdOn).format('DD.MM.YYYY')}</div>
                      </div>
                      </div>
                      <div className='actions'>
                        <Link className='open-preview'
                              to={`/oc-calculations/${this.contractNumber}/preview/${r.id}`}>
                          <img src={icTmpPreview} alt='Preview'/>
                        </Link>
                        <a className='start-download' onClick={() => this.downloadDocument(r)}>
                          <img src={icTmpDownload} alt='Download'/>
                        </a>
                      </div>
                    </div>
                  </div> 
                )
              })
            ) : (
              <NoContent message={t.NO_CALCULATIONS} image={ContractsImage}/>
            )
          }
        </div>
      </div>
    )
  }
}

export default withRouter(observer(OcCalculations))