import { api } from '../../service/api.service'
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'

class PartnerListStore {
  constructor () {
    extendObservable(this, {
      partners: [],
      model: {},
      availableCategories: [],
      error: null,
      count: 0,
      loading: false,
      query: '',
      dragDisabled: false,
      formSubmittedSuccessfully: false,
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  async load (id) {
    this.loading = true

    try {
      const [partnerCategoriesRes, partnerRes] = await Promise.all([
        api.get('/api/partner-categories'),
        api.get('/api/partner/' + id)
      ])

      const partnerCategories = extractResponse(partnerCategoriesRes)
      this.availableCategories.replace(partnerCategories.rows)

      const partner = extractResponse(partnerRes)
      const model = pick(partner, [
        'id',
        'name',
        'format',
        'title',
        'titleDe',
        'url',
        'image',
        'featured',
        'expiryDate',
        'position'
      ])

      this.model = model
      this.model.categories = partner.partnerCategories.map(c => c.id)
      this.loading = false
    } catch (err) {
      this.error = extractError(err)
      this.loading = false
    }
  }

  new () {
    this.loading = true
    api
      .get('/api/partner-categories')
      .then(extractResponse)
      .then(res => {
        if (res.count > 0) {
          this.availableCategories = res.rows
        }
      }).finally(() => {
        this.loading = false
      })

    this.model = {
      id: null,
      name: null,
      format: 'imageWithTitle',
      title: '',
      titleDe: '',
      url: null,
      image: null,
      featured: false,
      expiryDate: null,
      position: 0
    }
  }

  deletePartner (id) {
    api.delete('/api/partner/' + id).then(() => {
      this.getAll()
    })
  }
  getAll () {
    this.loading = true
    api
      .get('/api/partner', { params: { q: this.query } })
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.partners.replace(rows)
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    set(this.model, key, val)
  }

  save () {
    if (!this.model.image) {
      return false
    }
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    return api
      .post(
        '/api/partner',
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/partners/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/partner' + path,
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/partners/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  updateQuery (val) {
    this.dragDisabled = val || false
    this.query = val
    this.debouncedGetAll()
  }

  saveOrder = () => {
    const partners = this.partners.map(partner => {
      return { id: partner.id, position: partner.position }
    })

    this.loading = true
    api
      .post('/api/partner/reorder', { partners })
      .then(res => {
        this.partners.replace(extractResponse(res))
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
export default new PartnerListStore()
