import React, { Component } from 'react'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import { Link } from 'react-router-dom'
import historyService from '../../service/history.service'

class LoyaltyQueueThankYou extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div className='message-text'>
        <PrimaryTitle title={t.THANK_YOU} />
        <p>{t.LOYALTY_QUEUE_THANK_YOU_P1}</p>
        <p>{t.LOYALTY_QUEUE_THANK_YOU_P2}</p>
        <p>{t.LOYALTY_QUEUE_THANK_YOU_P3}</p>
        <p>{t.LOYALTY_QUEUE_THANK_YOU_P4}</p>
        <p>{t.LOYALTY_QUEUE_THANK_YOU_P5}</p>
        <p />
        <Link to={'/'} className='button primary' onClick={() => historyService.push('/')}>{t.LOYALTY_QUEUE_THANK_YOU_BACK_BUTTON}</Link>
      </div>
    )
  }
}

export default LoyaltyQueueThankYou
