import React, { useState } from 'react';
import t from '../service/translate.service'

export const VerificationModal = ({ isOpen, onClose }) => {
  const [visible, setVisible] = useState(isOpen);

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  if (!visible) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <p style={styles.content}>{t.CONTRACT_VERIF_THANK_YOU}</p>
        <button style={styles.closeButton} onClick={handleClose}>{t.CONTRACT_VERIF_CONTINUE}</button>
      </div>
    </div>
  );
};

const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      backgroundColor: 'white',
      width: '500px',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      textAlign: 'center',
    },
    closeButton: {
      marginTop: '20px',
      padding: '10px 20px',
      fontSize: '16px',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: '#26f8ab',
      color: 'white',
      borderRadius: '4px',
      transition: 'background-color 0.3s',
    },
    content: {
      color: 'black',
      fontSize: '16px',
    },
  };

