import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Popconfirm, Spin, Button, Switch } from "antd";
import adventStore from "../../../store/admin/Advent.store";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class AdventsList extends Component {
  constructor(props) {
    super(props);

    adventStore.getAll();
  }

  getItemStyle = (draggableStyle, isDragging) => {
    return {
      userSelect: "none",
      border: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "1px solid #e9e9e9",
      padding: "8px 20px",
      alignItems: "center",
      background: "white",
      boxShadow: isDragging ? "2px 2px 25px -10px black" : "",
      ...draggableStyle,
    };
  };

  getListStyle = () => ({
    transition: "background 0.2s ease-out",
  });

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const [removed] = adventStore.advents.splice(source.index, 1);
    adventStore.advents.splice(destination.index, 0, removed);
    adventStore.advents.map((advent, index) => {
      advent.position = index + 1;
      return advent;
    });
  };

  render() {
    return (
      <div>
        <h1>Extra content</h1>
        <div style={{ minWidth: 900 }}>
          <div style={{ marginBottom: "30px", marginTop: "10px", display: 'flex', alignItems: 'center', gap: '18px' }}>
            <Link to={"/admin/advent/new"}>
              <Button className="ant-btn ant-btn-primary m-r-sm">
                New
              </Button>
            </Link>
            <Button
              className='ant-btn ant-btn-secondary'
              onClick={adventStore.saveOrder}
            >
              Save reordering
            </Button>
          </div>
          <div className="table__header">
            <div style={{ width: 130 }}>Name</div>
            <div style={{ width: 550 }}>Active</div>
            <div style={{ width: 10 }} />
            <div style={{ width: 40 }} />
          </div>
          {adventStore.loading ? (
            <div className="spinner-container">
              <Spin />
            </div>
          ) : (
            <div style={{ minHeight: 400 }}>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >
                      {adventStore.advents.length ? (
                        adventStore.advents.map((p, index) => (
                          <Draggable
                            index={index}
                            key={p.id}
                            draggableId={`${p.id}`}
                            isDragDisabled={adventStore.dragDisabled}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ width: 130 }}>
                                      <Link to={`/admin/advent/${p.id}`}>
                                        {p.name}
                                      </Link>
                                    </div>
                                    <div style={{ width: 530 }}>
                                      <div
                                        className={`statusIcon ${
                                          p.active ? "active" : "inactive"
                                        }`}
                                      />{" "}
                                      <span>
                                        {p.active ? "Active" : "Inactive"}
                                      </span>
                                    </div>
                                    <div style={{ width: 10 }}>
                                      <Link to={`/admin/advent/${p.id}`}>
                                        Edit
                                      </Link>
                                    </div>
                                    <div
                                      className="m-r-sm"
                                      style={{ width: 60 }}
                                    >
                                      <Popconfirm
                                        placement="topRight"
                                        title={`Are you sure you want to delete this avent (${p.name})?`}
                                        onConfirm={() =>
                                          adventStore.deleteAdvent(p.id)
                                        }
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button className="ant-btn ant-sm">
                                          Delete
                                        </Button>
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              );
                            }}
                          </Draggable>
                        ))
                      ) : (
                        <div style={{ textAlign: "center", marginTop: "80px" }}>
                          No results
                        </div>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(AdventsList);
