
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const AlreadyBookedModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => props.setShowAlreadyBookedModal(false)}
      footer={[
        <button
          className="button primary small"
          key="back"
          onClick={() => props.setShowAlreadyBookedModal(false)}
        >
          {t.CONSULTATIONS_BACK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className="modal-p">
          {t.CONSULTATIONS_ALREADY_BOOKED_DATE}
        </p>
      </div>
    </Modal>
  );
};

export default AlreadyBookedModal;
