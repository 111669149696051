import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import { ImageUploader } from '../../components/formComponents'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import { Alert } from 'antd'

class LoyaltyImageUpload extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div>
        <Form className='loyalty-upload module' onSubmit={(e) => this.props.onSubmit(e)}>
          <ImageUploader buttonText={this.props.type} files={this.props.files} />
          {this.props.uploadError && <Alert message={this.props.uploadError} type='error' />}
          <Form.Group className='actions text-center'>
            <div className='group'>
              <Button type='submit' className='button primary'>{t['SUBMIT_' + this.props.type]}</Button>
              <p>{t.UPLOAD_SUBMIT_TEXT}</p>
              <Button className='button tertiary' onClick={(e) => this.props.onCancel(e)}>{t.FORM_CANCEL}</Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

LoyaltyImageUpload.propTypes = {
  files: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string,
  uploadError: PropTypes.string
}
export default LoyaltyImageUpload
