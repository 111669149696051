import React, {Component} from 'react'
import {extendObservable} from "mobx";
import rentContractDocumentStore from "../../store/rentContractDocuments.store";
import withRouter from "../../components/withRouter";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import {observer} from "mobx-react";
import { Alert } from 'antd';
import t from "../../service/translate.service";
import { extractError, extractErrorFromSap } from '../../service/response.service';

class RentContractDocumentsPreview extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      loading: false,
      error: '',
      file: null
    })
  }

  componentDidMount = () => {
    ;(async () => {
      window.scrollTo(0, 0)
      await this.setDocument()
      
    })()
  }

  setDocument = async () => {
    this.loading = true;
    this.error = ''
    try {
      const { docId } = this.props.useParams;
      const f = await rentContractDocumentStore.getDocument(this.props.useParams.contractNumber, docId);
      this.file = new Blob([f], { type: "application/pdf" })
    } catch (err) {
      this.error = extractErrorFromSap(err) || t[extractError(err)] || t['ERROR']
    }
    this.loading = false;
  }

  render() {
    if (this.loading) {
      return (<LoadingPageOverlay />)
    }
    return (
      <div>
        {this.error && <Alert message={this.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <div className='position-relative' style={{'min-height': '900px'}}>
          <div className='document-preview'>
          <button className='close-preview' onClick={() => this.props.navigate(`/rent-contract-documents/${this.props.useParams.contractNumber}`)}>x</button>
            <div className='text-center'>
              {this.file && <iframe src={URL.createObjectURL(this.file)} sandbox toolbar='false'/>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(RentContractDocumentsPreview))