import { observer, inject } from 'mobx-react'
import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { Textarea } from '../../components/formComponents'
import faqCategoryStore from '../../store/admin/FaqCategory.store'
import User from '../../store/user.store'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

class FaqCategories extends Component {
  static propTypes = {
    faqFeedbackStore: PropTypes.object.isRequired
  }
  constructor (props) {
    super(props)

    const includeFaqQuestions = true
    faqCategoryStore.getAll(includeFaqQuestions)
  }

  submitFeedback = (e) => {
    this.props.faqFeedbackStore.error = false
    e.preventDefault()
    this.props.faqFeedbackStore.submit()
  }
  handleBack = () => {
    this.props.faqFeedbackStore.resetForm()
  }

  getAttrs = field => {
    const { model, setVal, validators } = this.props.faqFeedbackStore
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: validators[field]
    }
  }

  render () {
    return (
      <div>
        {this.props.faqFeedbackStore.showThankYouPage ? <ThankYouOverlay
          title={t.THANK_YOU}
          description={t.FEEDBACK_THANK_YOU}
          buttonText={t.BACK_TO_FAQ}
          buttonLink='/faq'
          handleClick={this.handleBack}
        />
          : <div className='faq-categories'>
            <PrimaryTitle title={t.MAIN_FAQ} />
            <div className='no-content'>
              <div className='message' style={{ fontWeight: 'bold' }}>{t.MAIN_FAQ_TEXT}</div>
            </div>
            {(!faqCategoryStore.loading || faqCategoryStore.faqCategories.length) && (
              faqCategoryStore.faqCategories.map(category => {
                const guestQuestions = category?.faqQuestions?.filter(question => !question.hideForGuest)
                const registerQuestions = category?.faqQuestions?.map(question => question)
                if ((guestQuestions?.length > 0 && !User.user) || (registerQuestions?.length > 0 && User.user)) {
                  return (
                    <Link key={category.id}
                      className='button primary'
                      style={{
                        marginTop: 32,
                        paddingHorizontal: 24
                      }}
                      to={`/faq/${category.id}`}
                      onClick={() => historyService.push(`/faq/${category.id}`)}
                    >
                      {localStorage.getItem('locale') === 'de' ? category.nameDe : category.name}
                    </Link>
                  )
                }
              })
            )}
            <div className='faq-form' ref={(el) => { this.feedbackRef = el }}>
              <div className='faq-form-title'>
                {t.MAIN_FAQ_FEEDBACK_TEXT}
              </div>
              <Form>
                <Textarea
                  {...this.getAttrs('feedback')}
                  name='feedback'
                  label='Feedback'
                />

                <Form.Group className='text-center'>
                  {this.props.faqFeedbackStore.error && <Alert message={this.props.faqFeedbackStore.errorMsg} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
                  <Button type='submit' className='button primary'
                    onClick={this.submitFeedback}>
                    {t.MAIN_FAQ_SUBMIT}
                  </Button>
                </Form.Group>
              </Form>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default inject('faqFeedbackStore')(observer(FaqCategories))
