import { extendObservable, autorun } from 'mobx'
import browserHistory from '../service/history.service'
import { api } from '../service/api.service'
import t from '../service/translate.service'
import { extractError } from '../service/response.service'
import User from '../store/user.store'

class MenuStore {
  constructor (configStore) {
    extendObservable(this, {
      configStore,
      currentPage: browserHistory.location.pathname,
      christmasGreeting: '',
      christmasGreetingChecked: false,
      linkUrl: '',
      linkText: '',
      loyaltyActive: false,
      energyAndHeatingActive: false,
      ocInspectionActive: false,
      lettersActive: false,
      loyaltyChecked: false,
      lettersEnabledForCondoActive: false,
      emailVerificationEnabled: false,
      consultationsBookingEnabled: false
    })
    autorun(() => {
      this.configStore.getConfig()
    })
  }

  checkSettings = () => {
    if (User.user) {
      if (!this.christmasGreetingChecked) {
        this.showChristmasGreeting()
      }
      this.showEmailVerification()
      this.showLoyalty()
      this.showEnergyAndHeating()
      this.showOCInspection()
      this.showLetters()
      this.showLettersEnabledForCondo()
      this.showConsultationsBooking()
    }
  }

  async showLoyalty () {
    try {
      const res = await api.get('/api/loyalty/show')
      this.loyaltyActive = res.data.active
    } catch (err) {
      extractError(err)
    }
  }
 
  async showOCInspection() {
    try {
      this.ocInspectionActive = this.configStore.ocInspectionEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async showConsultationsBooking() {
    try {
      this.consultationsBookingEnabled = this.configStore.consultationsBookingEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async showLetters() {
    try {
      this.lettersActive = this.configStore.lettersEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async showLettersEnabledForCondo() {
    try {
      this.lettersEnabledForCondoActive = this.configStore.lettersEnabledForCondo
    } catch (err) {
      extractError(err)
    }
  }

  async showEnergyAndHeating () {
    try {
      this.energyAndHeatingActive = this.configStore.heatingAndEnergyEnabled
    } catch (err) {
      extractError(err)
    }
  }

  async showChristmasGreeting () {
    try {
      const res = await api.get('/api/christmas-greeting')
      if (res.data.show) {
        this.christmasGreeting = t.CHRISTMAS_GREETING
        this.linkText = t.CHRISTMAS_GREETING_LINK
        this.linkUrl = res.data.url
      }
      this.christmasGreetingChecked = true
    } catch (err) {
      extractError(err)
    }
  }

  async showEmailVerification () {
    try {
      this.emailVerificationEnabled = this.configStore.verificationMethodEmail
      return this.configStore.verificationMethodEmail
    } catch(err) {
      extractError(err)
    }
  }

  setCurrentPage = href => {
    this.showLoyalty() // Recheck on each screen change because you never know when user might become eligible
    this.currentPage = href
  }
}

export default MenuStore
