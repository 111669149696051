import React from 'react'

const CheckIcon = (<svg width={30} height={30} viewBox='0 0 30 30'>
  <g
    fill=' #26f8ab'
    fillRule='evenodd'
    stroke='#415055'
    strokeWidth={2}>
    <circle cx={15} cy={15} r={13} stroke='none' />
    <path d='M9 13.776l4.949 5.31 8.044-9.446' />
  </g>
</svg>
)

export default CheckIcon
