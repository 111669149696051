import { useEffect, useState } from "react";
import { useParams } from "react-router";
import t from "../../../../service/translate.service";
import AlreadyBookedModal from "./AlreadyBookedModal";
import ConfirmationModal from "./ConfirmationModal";
import TopicModal from "./TopicModal";
import DescriptionModal from "./DescriptionModal";
import SuccessModal from "./SuccessModal";
import LoadingPageOverlay from "../../../../components/LoadingPageOverlay";
import { Alert } from "antd";
import { extractError, extractErrorFromSap } from "../../../../service/response.service";
import SlotInProgressModal from "./SlotInProgressModal";
import moment from 'moment-timezone'
import TimeExceededModal from "./TimeExceededModal";

const slotCount = 300

const DaySlotsPage = (props) => {
  const [slots, setSlots] = useState([]);
  const [showAlreadyBookedModal, setShowAlreadyBookedModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [showBookingConfiramtionModal, setShowBookingConfiramtionModal] = useState(false)
  const [showTimeExceededModal, setShowTimeExceededModal] = useState(false)
  const [showSlotInProgressModal, setShowSlotInProgressModal] = useState(false)
  const [showTopicModal, setShowTopicModal] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [successSubmitted, setSuccessSubmitted] = useState(false)
  const [showDescriptionModal, setShowDescriptionModal] = useState(false)
  const [time, setTime] = useState('')
  const [description, setDescription] = useState('')
  const [selectedTopic, setSelectedTopic] = useState('')
  const [count, setCount] = useState(slotCount)
  const [bookingStarted, setBookingStarted] = useState(false)

  const { contractNumber } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");
  const startTime = urlParams.get("startTime");
  const endTime = urlParams.get("endTime");
  const address = urlParams.get("address");
  const calendarId = urlParams.get("calendarId"); 
  const bookingTimeLeftMessage = `${t.CONSULTATIONS_BOOKING_COUNT_TIME_1}${moment.utc(count*1000).format('mm:ss')}${t.CONSULTATIONS_BOOKING_COUNT_TIME_2}`

  useEffect(() => {
      if (bookingStarted && count !== 0) {
        const interval = setInterval(() => {
          setCount(count - 1);
        }, 1000);
        return () => clearInterval(interval);
      }
  }, [count, bookingStarted]);

  const getDaySlots = async () => {
    setLoading(true)
    try {
      const slots = await props.consultationStore.getDaySlots(
        contractNumber,
        date,
        startTime,
        endTime,
        calendarId
      );
      setSlots(slots || []);
    } catch (err) {
      setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
    }
    setLoading(false)
  }
  
  useEffect(() => {
    getDaySlots()
  }, [contractNumber, props.consultationStore]);

  const onSubmit = async () => {
    setLoading(true)
    if (count === 0) {
      setShowTimeExceededModal(true)
    } else {
      await props.consultationStore.bookAppointment(
        contractNumber,
        date,
        time,
        selectedTopic?.value || '',
        description,
        address,
        calendarId
      ).then((res) => {
        setSuccessSubmitted(true)
        setError('')
      }).catch((err) => {
        setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
      })
    }
    setLoading(false)
  }

  const setSlotInProgress = async (time) => {
    setLoading(true)
    await props.consultationStore.setSlotInProgress(
      contractNumber,
      calendarId,
      date,
      time
    ).then(() => {
      localStorage.setItem('slot_in_progress', `${contractNumber}/${calendarId}/${date}/${time}`)
      setShowBookingConfiramtionModal(true)
      setBookingStarted(true)
      setCount(slotCount)
      setError('')
    }).catch((err) => {
      setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
    })
    setLoading(false)
  }

  const getSlotInProgress = async (time) => {
    setLoading(true)
    await props.consultationStore.getSlotInProgress(
      contractNumber,
      calendarId,
      date,
      time
    ).then(async(res) => {
      if (res) {
        setShowSlotInProgressModal(true)
      } else {
        setSlotInProgress(time)
      }
      setError('')
    }).catch((err) => {
      setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
    })
    setLoading(false)
  }

  const closeAppointment = async (type) => {
    setLoading(true)
    await props.consultationStore.removeSlotInProgress(
      contractNumber,
      calendarId,
      date,
      time
    ).then((res) => {
      localStorage.removeItem('slot_in_progress')
      switch(type) {
        case 'confirmation':
          setShowBookingConfiramtionModal(false)
        break;
        case 'topic':
          setShowDescriptionModal(false)
          setSelectedTopic('')
          setShowTopicModal(false)
        break;
        case 'description':
          setSelectedTopic('')
          setDescription('')
          setShowDescriptionModal(false)
          setSubmitted(false)
        break;
        default:
          return ''
      }
      setBookingStarted(false)
      setError('')
    }).catch((err) => {
      setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
    })
    setLoading(false)
  }

  if (loading) {
    return (
      <LoadingPageOverlay />
    )
  }

  const onTimeExceeded = async () => {
    getDaySlots()
    setShowTimeExceededModal(false)
  }

  return (
    <div>
      {error && (
          <Alert message={error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />
      )}
      {showTimeExceededModal && (
        <TimeExceededModal
          onTimeExceeded={onTimeExceeded}
        />
      )}
      {showSlotInProgressModal && (
        <SlotInProgressModal setShowSlotInProgressModal={setShowSlotInProgressModal} />
      )}
      {showAlreadyBookedModal && (
        <AlreadyBookedModal setShowAlreadyBookedModal={setShowAlreadyBookedModal} />
      )}
      {showBookingConfiramtionModal && (
        <ConfirmationModal
          count={count}
          bookingTimeLeftMessage={bookingTimeLeftMessage}
          closeAppointment={closeAppointment}
          setShowTopicModal={setShowTopicModal}
          setShowBookingConfiramtionModal={setShowBookingConfiramtionModal}
          date={date}
          time={time}
        />
      )}
      {showTopicModal && (
        <TopicModal
          count={count}
          bookingTimeLeftMessage={bookingTimeLeftMessage}
          closeAppointment={closeAppointment}
          setShowTopicModal={setShowTopicModal}
          setShowDescriptionModal={setShowDescriptionModal}
          setSelectedTopic={setSelectedTopic}
          selectedTopic={selectedTopic}
          showDescriptionModal={showDescriptionModal}
        />
      )}
      {showDescriptionModal && selectedTopic && !showTopicModal && (
        <DescriptionModal
          count={count}
          bookingTimeLeftMessage={bookingTimeLeftMessage}
          closeAppointment={closeAppointment}
          setShowDescriptionModal={setShowDescriptionModal}
          setDescription={setDescription}
          description={description}
          onSubmit={onSubmit}
          setSubmitted={setSubmitted}
          submitted={submitted}
        />
      )}
      {successSubmitted && (
        <SuccessModal
          date={date}
          time={time}
        />
      )}
      <h1
        className="heading-address"
      >
        {address}
      </h1>
      <div
        className="day-slots-wrapper"
      >
        {slots.map((slot, index) => {
          if (slot.booked) {
            return (
              <button
                key={index}
                onClick={() => setShowAlreadyBookedModal(true)}
                className="button secondary small"
              >
                {slot.time} {t.CONSULTATIONS_CLOCK}
              </button>
            );
          } else {
            return (
              <button
                key={index}
                onClick={async () => {
                  setTime(slot.time)
                  await getSlotInProgress(slot.time)
                }}
                className="button primary small"
              >
                {slot.time} {t.CONSULTATIONS_CLOCK}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default DaySlotsPage;
