import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'
import { api } from '../../service/api.service'

class FaqQuestionListStore {
  constructor () {
    extendObservable(this, {
      faqQuestions: [],
      model: {},
      availableCategories: [],
      error: null,
      count: 0,
      loading: false,
      query: '',
      dragDisabled: false,
      formSubmittedSuccessfully: false,
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  async load (id) {
    this.loading = true

    try {
      const [faqCategoriesRes, faqQuestionRes] = await Promise.all([
        api.get('/api/faq-categories'),
        api.get('/api/faq-question/' + id)
      ])

      const faqCategories = extractResponse(faqCategoriesRes)
      this.availableCategories.replace(faqCategories.rows)

      const faqQuestion = extractResponse(faqQuestionRes)

      const model = pick(faqQuestion, [
        'id',
        'question',
        'questionDe',
        'answer',
        'answerDe',
        'linksText',
        'linksTextDe',
        'image',
        'position',
        'hideForGuest'
      ])

      this.model = model

      var links = JSON.parse(faqQuestion.links)
      if (!Array.isArray(links)) {
        links = JSON.parse(links)
      }

      this.model.links = links || []

      this.model.category = faqQuestion.faqCategory.id
      this.loading = false
    } catch (err) {
      this.error = extractError(err)
      this.loading = false
    }
  }

  new () {
    this.loading = true
    api
      .get('/api/faq-categories')
      .then(extractResponse)
      .then(res => {
        if (res.count > 0) {
          this.availableCategories = res.rows
        }
      }).finally(() => {
        this.loading = false
      })

    this.model = {
      id: null,
      question: '',
      questionDe: '',
      answer: '',
      answerDe: '',
      linksText: null,
      linksTextDe: null,
      image: null,
      position: 0,
      hideForGuest: false
    }
  }

  deleteFaqQuestion (id) {
    api.delete('/api/faq-question/' + id).then(() => {
      this.getAll()
    })
  }
  getAll () {
    this.loading = true
    api
      .get(`/api/faq-question?q=${this.query}`)
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.faqQuestions.replace(rows)
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    set(this.model, key, val)
  }

  save () {
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    return api
      .post(
        '/api/faq-question',
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/faq-questions/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/faq-question' + path,
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/faq-questions/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  updateQuery (val) {
    this.dragDisabled = !!val
    this.query = val
    this.debouncedGetAll()
  }

  saveOrder = () => {
    const faqQuestions = this.faqQuestions.map(faqQuestion => {
      return { id: faqQuestion.id, position: faqQuestion.position }
    })

    this.loading = true
    api
      .post('/api/faq-question/reorder', { faqQuestions })
      .then(res => {
        this.faqQuestions.replace(extractResponse(res))
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
export default new FaqQuestionListStore()
