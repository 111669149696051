import { api, API_BASE } from '../service/api.service'

const EVENT_ENDPOINT = `${API_BASE}/api/offer`

function getAll (skip, limit) {
  return api.get(EVENT_ENDPOINT, { params: { skip: skip || 0, limit: limit || 0 } })
    .then(res => res.data)
}

function getOne (id) {
  return api.get(EVENT_ENDPOINT + '/' + id)
    .then(res => res.data)
}

export { getAll, getOne }
