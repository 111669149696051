import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Select, Input, Upload, Checkbox, message } from 'antd'
import faqQuestionStore from '../../../store/admin/FaqQuestionList.store.js'
//import 'antd/dist/antd.min.css'
import { availableLinks } from '../../../config'
import TokenService from '../../../service/token.service'
import { Navigate } from 'react-router-dom'

class FaqQuestionForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    faqQuestionStore.save()
  }

  onClone (e) {
    e.preventDefault()
    faqQuestionStore.clone()
  }

  getBase64 (img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  handleImageChange = info => {
    if (info.file.status === 'uploading') {
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => {
        faqQuestionStore.setVal('image', info.file.response.url)
      })
    }
  };

  handleCategoryChange = category => {
    faqQuestionStore.setVal('category', category)
  }
  handleLinksChange = link => {
    faqQuestionStore.setVal('links', link)
  }
  handleHideForGuestChange = e => {
    console.log(`checked = ${e.target.checked}`)
    faqQuestionStore.setVal('hideForGuest', e.target.checked)
  }
  renderImageTooltip = (format) => {
    switch (format) {
      case 'imageWithTitle':
        return <p>Required image resolution: <b>975x600</b></p>
      case 'thumbWithTitle':
        return <p>Required image resolution: <b>276x276</b></p>
      default:
        return ''
    }
  }

  render () {
    const { model, availableCategories } = faqQuestionStore
    if(faqQuestionStore.formSubmittedSuccessfully) {
      faqQuestionStore.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/faq-questions/' }} />
    }
    const token = TokenService.get()
    const uploadButton = (
      <div>
        <div className='ant-upload-text'>Upload</div>
      </div>
    )

    return (
      !faqQuestionStore.loading && (
        <div>
          <h1>Faq Question</h1>
          <form onSubmit={this.onSubmit}>

            <div className='input-div'>
              <Checkbox checked={model.hideForGuest} onChange={this.handleHideForGuestChange}>&nbsp;Hide this question for guest users</Checkbox>
            </div>

            <div className='input-div'>
              <label>Question EN *</label>
              <Input
                value={model.question}
                type='input'
                required
                onChange={e => faqQuestionStore.setVal('question', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Question DE *</label>
              <Input
                value={model.questionDe}
                type='input'
                required
                onChange={e => faqQuestionStore.setVal('questionDe', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Answer EN *</label>
              <Input
                value={model.answer}
                type='input'
                required
                onChange={e => faqQuestionStore.setVal('answer', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Answer DE *</label>
              <Input
                value={model.answerDe}
                type='input'
                required
                onChange={e => faqQuestionStore.setVal('answerDe', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Links text EN</label>
              <Input
                value={model.linksText}
                type='input'
                onChange={e => faqQuestionStore.setVal('linksText', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Links Text DE</label>
              <Input
                value={model.linksTextDe}
                type='input'
                onChange={e => faqQuestionStore.setVal('linksTextDe', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Category *</label>
              <Select
                defaultValue={model.category}
                onChange={this.handleCategoryChange}
              >
                {availableCategories.map(cat => (
                  <Select.Option key={'cat' + cat.id} value={cat.id}>{cat.nameDe}</Select.Option>
                ))}
              </Select>

            </div>
            <div className='input-div'>
              <label>Links</label>
              <Select
                mode='multiple'
                defaultValue={model.links}
                onChange={this.handleLinksChange}
              >
                {availableLinks.map(link => (
                  <Select.Option key={'cat' + link.id} value={link.type}>{link.name}</Select.Option>
                ))}
              </Select>

            </div>
            <div className='input-div'>
              <label>Image</label>
              <div>
                <Upload
                  name='faq_question_image'
                  listType='picture-card'
                  className='avatar-uploader'
                  showUploadList={false}
                  action='/api/faq-question/image/upload'
                  beforeUpload={this.beforeUpload}
                  value={model.image ? model.image : 'test'}
                  data={(file) => { return { 'filename': file.name } }}
                  onChange={this.handleImageChange}
                  headers={{ 'Authorization': token }}
                >
                  {model.image ? <img src={model.image} alt='image' style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </div>
              {this.renderImageTooltip(model.format)}
            </div>

            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/faq-questions'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(FaqQuestionForm)
