import { api, API_BASE } from '../service/api.service'
import { extendObservable } from 'mobx'
import { extractError } from '../service/response.service'
import set from 'lodash/set'

import {
  buildValidators,
  required,
  minLength,
  maxLength,
  password,
  passwordValidation,
  checkPasswordResponse
} from '../service/validate'

const RESET_PASSWORD_ENDPOINT = `${API_BASE}/api/auth/reset-password`

class ResetPasswordStore {
  constructor () {
    extendObservable(this, {
      model: {
        password: '',
        confirmPassword: ''
      },
      status: '',
      hash: '',
      submitted: false,
      passwordErr: null
    })
  }

  setVal = (field, val) => set(this.model, field, val)

  get validators () {
    return buildValidators(
      {
        password: [
          required,
          minLength(10),
          maxLength(50),
          passwordValidation,
          password(this.model.confirmPassword)
        ]
      },
      this.submitted
    )
  }

  checkResetLink (hash) {
    return api.get(RESET_PASSWORD_ENDPOINT + '/' + hash).then(res => {
      this.hash = hash
      this.status = res.data.isValid ? 'INITIAL' : 'INVALID_HASH'
    })
  }

  submit () {
    this.submitted = true

    const isValid = !this.validators.password(this.model.password)
    if (!isValid) return

    return api
      .post(RESET_PASSWORD_ENDPOINT, { password: this.model.password, confirmPassword: this.model.confirmPassword, hash: this.hash })
      .then(res => {
        this.status = 'SUCCESS'
      })
      .catch(err => {
        const error = extractError(err)
        this.passwordErr = checkPasswordResponse(error)
        if (error.message === 'Invalid hash') {
          this.status = 'INVALID_HASH'
        } else if (this.passwordErr) {
          this.status = 'PASSWORD_ERROR'
        } else {
          this.status = 'ERROR'
        }
      })
  }
}

export default ResetPasswordStore
