
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const CancelSuccessModal = (props) => {

  return (
    <Modal
      open={true}
      closeIcon={false}
      closable={false}
      footer={[
        <button
          className="button primary small"
          key="ok"
          onClick={() => props.setSuccessCanceled(false)}
        >
          {t.CONSULTATIONS_OK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className="modal-p">
          {`
            ${t.CONSULTATIONS_CANCEL_SUCCESS_MESSAGE_1}
            ${props.date}
            ${t.CONSULTATIONS_CANCEL_SUCCESS_MESSAGE_2}
            ${props.time}
            ${t.CONSULTATIONS_CANCEL_SUCCESS_MESSAGE_3}
          `}
        </p>
      </div>
    </Modal>
  );
};

export default CancelSuccessModal;
