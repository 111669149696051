import React from 'react'

const ConsultationsImage = (
  <svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='94pt' height='130pt' viewBox='0 0 94 130'>
    <g enableBackground='new'>
      <path transform='matrix(1,0,0,-1,0,130)' d='M -53 759 L 322 759 L 322 -1024 L -53 -1024 Z ' fill='#ffffff' />
      <clipPath id='cp0'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M -28 140 L 122 140 L 122 -10 L -28 -10 L -28 140 Z ' fillRule='evenodd' />
      </clipPath>
      <g clipPath='url(#cp0)'>
        <clipPath id='cp1'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp1)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -28 140 L 122 140 L 122 -10 L -28 -10 L -28 140 Z ' fill='#ffffff' />
        </g>
      </g>
      <clipPath id='cp2'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M -28 140 L 122 140 L 122 -10 L -28 -10 L -28 140 Z ' fillRule='evenodd' />
      </clipPath>
      <g clipPath='url(#cp2)'>
        <clipPath id='cp3'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp3)'>
          <path transform='matrix(1,0,0,1,-28,-10)' strokeWidth='3' strokeLinecap='butt' strokeMiterlimit='10' strokeLinejoin='miter' fill='none' stroke='#ecedee' d='M 0 0 L 150 0 L 150 150 L 0 150 L 0 0 Z ' />
        </g>
      </g>
      <clipPath id='cp4'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 79.2 10.2 C 79.1 11.2 78.5 12.6 77.8 12.7 C 76.8 12.9 75.5 12.3 75.5 12.3 C 75.5 12.3 76.7 9.5 73.7 6.5 C 73.7 6.5 70.9 2.7 71.3 2.1 C 71.7 1.5 74.7 1.4 76.5 2.1 C 77.9 2.6 78.3 5.5 78.8 7.7 L 78.8 5.2 L 79.3 5.2 L 79.3 9.6 C 79.3 9.7 79.2 10.2 79.2 10.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp4)'>
        <clipPath id='cp5'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp5)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 66.26089 17.74082 L 84.3 17.74082 L 84.3 -3.388219 L 66.26089 -3.388219 Z ' />
        </g>
      </g>
      <clipPath id='cp6'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 85.2 12.8 C 84.2 13 82.9 12.4 82.9 12.4 C 82.9 12.4 84 9.6 81.1 6.6 C 81.1 6.6 78.3 2.8 78.7 2.2 C 79.1 1.6 82.1 1.5 83.9 2.2 C 85.2 2.7 85.6 5.3 86.1 7.4 L 86.1 5.2 L 86.6 5.2 L 86.6 9.2 L 86.5 9.1 C 86.5 9.2 86.6 9.3 86.6 9.3 C 86.9 10.2 86.2 12.6 85.2 12.8 Z ' />
      </clipPath>
      <g clipPath='url(#cp6)'>
        <clipPath id='cp7'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp7)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 73.66089 17.84082 L 91.67007 17.84082 L 91.67007 -3.288219 L 73.66089 -3.288219 Z ' />
        </g>
      </g>
      <clipPath id='cp8'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 23 108.2 C 23 108.2 28.4 106.6 28.1 99.3 C 27.7 91.9 23 108.2 23 108.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp8)'>
        <clipPath id='cp9'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp9)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 18 113.2 L 33.11205 113.2 L 33.11205 92.37313 L 18 92.37313 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp10'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 22.5 108.2 C 22.5 108.2 22.9 107.6 22.4 104 C 21.9 100.3 22.9 98.8 23 97.3 C 23.1 95.8 24.9 86 24.9 85.5 C 24.9 85.1 25.2 79.8 25.9 78.5 C 26.6 77.1 31.7 69.3 31.8 68.9 C 31.9 68.5 34.3 70.3 34.3 70.3 C 34.3 70.3 29.7 79.9 29.4 80.8 C 29 82 27.9 84.9 27.8 86.4 C 27.6 90.1 28 98.7 27.8 100.6 C 27.6 104 24.8 107.7 22.5 108.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp10)'>
        <clipPath id='cp11'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp11)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 17.26079 113.2 L 39.3 113.2 L 39.3 63.842 L 17.26079 63.842 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp12'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 29.4 76.9 C 29.4 76.9 30.2 75.1 30.7 75 C 31.2 74.9 31.7 74.3 32.1 74.1 C 32.5 73.9 33.8 73.8 34.1 73.5 C 34.2 73.4 34.8 72.2 35.1 71.9 C 35.4 71.7 36.1 71.2 35.9 71 C 35.7 70.7 35 70.9 35 70.9 C 35 70.9 35.3 70.7 35.2 70.4 C 35 69.8 34.6 69.8 34.6 69.8 C 34.6 69.8 34.7 69.4 34.6 69.2 C 34.3 68.7 33.9 68.7 33.9 68.7 C 33.9 68.7 33.9 68.4 33.6 68.1 C 33.2 67.8 32.7 68 32.7 68 C 32.7 68 32.3 67.5 32 67.6 C 31.6 67.7 31.4 68 31.2 68.1 C 30.9 68.3 29.2 70.3 28.5 72.2 C 27.9 74.1 26.7 75.6 26.7 75.6 L 29.4 76.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp12)'>
        <clipPath id='cp13'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp13)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 21.7 81.9 L 40.93537 81.9 L 40.93537 62.58678 L 21.7 62.58678 Z ' fill='#eea987' />
        </g>
      </g>
      <clipPath id='cp14'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 14.83884 75.08708 L 34.56702 91.08654 L 44.1415 79.2807 L 24.41332 63.28124 L 14.83884 75.08708 Z ' />
      </clipPath>
      <g clipPath='url(#cp14)'>
        <clipPath id='cp15'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp15)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 7.80597 75.82107 L 35.30101 98.11941 L 51.17437 78.54671 L 23.67933 56.24837 Z ' fill='#26f8ab' />
        </g>
      </g>
      <clipPath id='cp16'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 34.9 71.2 C 34.9 71.2 33.4 72.9 32.6 72.9 C 31.8 72.9 32.4 72.2 32.4 72.2 C 32.4 72.2 31.9 72.8 31.4 72.5 C 30.9 72.2 31.5 71.4 31.5 71.4 C 31.5 71.4 31 71.8 30.6 71.3 C 30.2 70.8 30.9 70.3 30.9 70.3 C 30.9 70.3 29.9 70.4 30.1 69.6 C 30.5 68.2 32 67.5 32 67.5 C 32 67.5 32.4 67.4 32.8 67.9 C 32.8 67.9 33.7 67.5 34 68.6 C 34 68.6 35.1 68.9 34.8 69.7 C 34.6 69.8 35.7 70.2 34.9 71.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp16)'>
        <clipPath id='cp17'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp17)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 25.07355 77.9 L 40.19549 77.9 L 40.19549 62.49184 L 25.07355 62.49184 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp18'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 18 129.7 C 18 129.7 10.2 130.3 10.4 122.1 C 10.6 113.9 11.9 112.6 10.4 106.8 C 8.9 101 18 110.1 18 110.1 L 18 129.7 Z ' />
      </clipPath>
      <g clipPath='url(#cp18)'>
        <clipPath id='cp19'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp19)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 5.233028 134.7098 L 23 134.7098 L 23 99.86687 L 5.233028 99.86687 Z ' />
        </g>
      </g>
      <clipPath id='cp20'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 9.5 78.9 C 9.5 78.9 6.6 75.2 5.6 72.8 C 4.6 70.4 2.9 65.1 3.1 60.9 C 3.3 56.8 4.7 49.1 4.3 47.3 C 3.9 45.6 3.5 41.9 3.1 40.7 C 2.7 39.5 1 35.8 .8 32.1 C .6 28.4 3.2 12 3.1 10.5 C 3 9.1 6 10 6 10 C 6 10 7 24.8 7.6 27.3 C 8.2 29.8 8.8 38.5 9.3 39.1 C 9.8 39.7 11.2 46.2 11 47.2 C 10.8 48.2 16.5 63.4 16.9 66.5 C 17.2 69.5 25.7 79 25.7 79 C 25.7 79 18.3 81.7 9.5 78.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp20)'>
        <clipPath id='cp21'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp21)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -4.210979 85.17091 L 30.7 85.17091 L 30.7 4.715234 L -4.210979 4.715234 Z ' fill='#eea987' />
        </g>
      </g>
      <clipPath id='cp22'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 23.2 78.9 C 23.2 78.9 23.3 77.4 23.5 76.8 C 23.7 76.2 27.6 61.9 28 56.3 C 28.4 50.8 29.4 46.1 29.6 44.9 C 29.8 43.7 29.4 39.8 29.1 38.8 C 28.8 37.8 30.8 13.8 31 13.2 C 31.2 12.6 29.6 12.5 28.6 12.6 C 27.5 12.8 22.1 30.2 22.5 33.1 C 22.8 36 23.2 42.3 22.2 44.8 C 21.2 47.3 12.6 65.7 12.6 66.9 C 12.4 68.1 8.7 87.5 23.2 78.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp22)'>
        <clipPath id='cp23'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp23)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 7.034125 86.09185 L 36.0171 86.09185 L 36.0171 7.566216 L 7.034125 7.566216 Z ' fill='#eea987' />
        </g>
      </g>
      <clipPath id='cp24'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 5.9 11.8 C 5.9 11.8 6.2 9.9 6.5 9.3 C 6.8 8.8 7.6 6.1 8 5.6 C 8.4 5.1 9.2 1.3 8 .8 C 6.8 .3 3.9 -.4 3 1.1 C 2 2.6 1.9 4.1 2.1 5.3 C 2.3 6.5 3.4 11.8 3.4 12.3 C 3.3 12.8 5.9 11.8 5.9 11.8 Z ' />
      </clipPath>
      <g clipPath='url(#cp24)'>
        <clipPath id='cp25'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp25)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -2.984634 17.44 L 13.63378 17.44 L 13.63378 -4.807224 L -2.984634 -4.807224 Z ' fill='#eea987' />
        </g>
      </g>
      <clipPath id='cp26'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 7.5 6.6 C 7.5 6.6 7.6 3.7 5.4 3.7 C 3.2 3.7 2.2 6.4 2.2 6.4 C 2.2 6.4 1.4 5.1 2.2 2.3 C 3 -.5 6.1 .1 6.8 .2 C 7.6 .2 10.9 1.1 7.5 6.6 Z ' />
      </clipPath>
      <g clipPath='url(#cp26)'>
        <clipPath id='cp27'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp27)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -3.155556 11.6 L 14.00781 11.6 L 14.00781 -4.922222 L -3.155556 -4.922222 Z ' fill='#a0a7aa' />
        </g>
      </g>
      <clipPath id='cp28'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 30.7 14.4 C 30.7 14.4 32.3 11.6 33.2 11 C 34.4 10.3 36.6 8 37.4 7.9 C 38.2 7.8 41 7.3 40.5 5.7 C 40.1 4.4 33.3 4.6 32.5 5.2 C 31.7 5.9 29.5 8.2 28.5 8.2 C 27.6 8.1 27.9 4.8 27.9 4.8 L 26.9 4.8 C 26.9 4.8 26.5 8.1 26.5 8.6 C 26.4 10.3 27.6 11.5 27.6 12.4 C 27.7 13.3 27.7 14.1 27.7 14.1 L 30.7 14.4 Z ' />
      </clipPath>
      <g clipPath='url(#cp28)'>
        <clipPath id='cp29'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp29)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 21.49408 19.4 L 45.55936 19.4 L 45.55936 -.2670109 L 21.49408 -.2670109 Z ' fill='#a0a7aa' />
        </g>
      </g>
      <clipPath id='cp30'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 37.2 8 C 37.2 8 35 7.2 33.3 8.2 C 31.6 9.2 28.3 10.7 27.1 10.7 C 27.1 10.7 27.7 12.6 27.7 12.9 C 27.7 13.1 27.8 14.1 27.8 14.1 L 30.7 14.6 C 30.7 14.6 32.6 11.9 33.4 11.2 C 34.2 10.5 36.3 8.9 37.2 8 Z ' />
      </clipPath>
      <g clipPath='url(#cp30)'>
        <clipPath id='cp31'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp31)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 22.1 19.6 L 42.2 19.6 L 42.2 2.697041 L 22.1 2.697041 Z ' fill='#eea987' />
        </g>
      </g>
      <clipPath id='cp32'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 25.7 79.1 C 25.7 79.1 26.5 75.8 26.8 74.8 C 27.1 73.8 29 58.7 29.5 49.3 C 29.5 49.3 18.1 45 2.5 45.7 C 2.5 45.7 2.1 63 3.6 68 C 5 72.9 7.6 77.5 9.6 78.9 C 11.3 80.2 25.7 79.1 25.7 79.1 Z ' />
      </clipPath>
      <g clipPath='url(#cp32)'>
        <clipPath id='cp33'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp33)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -2.548393 84.53461 L 34.5 84.53461 L 34.5 40.62295 L -2.548393 40.62295 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp34'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 27.7 98.9 C 27.6 96.9 26.8 95.6 26.6 94.9 C 26.4 94.2 26.6 92.5 26.4 90.9 C 26.2 89.3 25.6 81.4 25.8 79.1 C 26 76.9 10.4 77.9 9.2 78.5 C 9.2 78.5 11.8 83.2 11.9 85.1 C 11.9 85.8 12.3 87.8 11.2 89.4 C 10.6 90.2 9.5 92.8 8.5 94.6 C 8.5 94.6 8.7 95 8.5 94.6 C 8.1 95.3 7.6 96 7.3 96.6 C 6.9 97.3 6.7 98.1 6.7 98.1 C 6.7 98.1 6 106.7 7.9 107.7 C 9.8 108.7 10.8 108.8 12.7 108.9 C 14.6 109 15.8 109.4 15.8 109.4 L 20.2 108.9 C 20.2 108.9 21.7 108.2 22.8 108.1 C 23.9 108 25.2 105.6 25.9 103.9 C 26.4 102.7 27.8 100.9 27.7 98.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp34)'>
        <clipPath id='cp35'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp35)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 1.574013 114.4 L 32.70509 114.4 L 32.70509 72.7448 L 1.574013 72.7448 Z ' fill='#ecedee' />
        </g>
      </g>
      <clipPath id='cp36'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 10.2 108.8 C 10.2 108.8 6.8 108.5 5.5 105.3 C 4.2 102.1 4.3 98.9 4 97.7 C 3.7 96.5 3.3 89.7 3 88.7 C 2.7 87.7 1.7 83.1 1.5 79.3 C 1.3 75.5 1.8 67.6 1.8 67.6 L 3.7 68.5 C 3.7 68.5 5.4 80.2 6.1 82.7 C 6.7 84.8 6.7 88.4 7.1 89.7 C 7.5 91 9.6 98.9 10 99.6 C 10.6 101.1 13.2 108.8 10.2 108.8 Z ' />
      </clipPath>
      <g clipPath='url(#cp36)'>
        <clipPath id='cp37'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp37)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -3.547222 113.8 L 16.61788 113.8 L 16.61788 62.6 L -3.547222 62.6 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp38'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 17.3 128.8 C 19.2 129.1 23.7 128.6 24.9 123.5 C 24.9 123.5 24.9 122.1 24.7 121 C 24.5 119.9 25.8 116.8 25.6 116.6 C 25.4 116.4 24.3 116.1 24.3 116.1 C 24.3 116.1 23.6 112.4 22.3 112.3 C 21.1 112.2 16.3 112.6 13.6 116.7 C 10.9 120.9 9.4 127.6 17.3 128.8 Z ' />
      </clipPath>
      <g clipPath='url(#cp38)'>
        <clipPath id='cp39'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp39)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 6.430973 133.8685 L 30.62099 133.8685 L 30.62099 107.2872 L 6.430973 107.2872 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp40'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 20.5 113.8 C 20.5 113.8 20.4 113.3 20.3 112.5 C 20.3 112.1 20.2 111.7 20.2 111.2 C 20.1 109.9 20.2 108.4 20.6 107.4 C 20.6 107.4 18.2 105.9 15.6 109.5 C 15.6 109.5 15.9 114 14.4 116.4 C 12.8 118.8 20.5 113.8 20.5 113.8 Z ' />
      </clipPath>
      <g clipPath='url(#cp40)'>
        <clipPath id='cp41'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp41)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 9.181204 122.0518 L 25.6 122.0518 L 25.6 102.0901 L 9.181204 102.0901 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp42'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 24.9 123.6 C 24.9 123.6 22 121.1 18.9 120.3 C 15.8 119.5 14.2 116 14.2 116 C 14.2 116 8.7 121.4 12 126.7 C 15.3 132 24.9 129.7 24.9 123.6 Z ' />
      </clipPath>
      <g clipPath='url(#cp42)'>
        <clipPath id='cp43'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp43)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 5.945562 134.6588 L 29.9 134.6588 L 29.9 111 L 5.945562 111 Z ' />
        </g>
      </g>
      <clipPath id='cp44'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 18.3 120.1 C 18.3 120.1 15.1 111.6 15.5 109.6 C 15.5 109.6 13.8 109.9 10.9 109 C 10.9 108.9 12.3 124.1 18.3 120.1 Z ' />
      </clipPath>
      <g clipPath='url(#cp44)'>
        <clipPath id='cp45'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp45)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 5.9 125.7708 L 23.3 125.7708 L 23.3 103.9995 L 5.9 103.9995 Z ' />
        </g>
      </g>
      <clipPath id='cp46'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 14.5 109.3 C 14.5 109.3 15.5 102.9 19.4 103.7 C 23.2 104.4 20.3 109.1 20.3 109.1 C 20.3 109.1 18.6 110.5 14.5 109.3 Z ' />
      </clipPath>
      <g clipPath='url(#cp46)'>
        <clipPath id='cp47'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp47)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 9.5 114.786 L 26.35855 114.786 L 26.35855 98.6308 L 9.5 98.6308 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp48'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 3.8 69 C 3.8 69 4.1 68 4.6 67 C 4.8 66.5 5.2 63.2 5.2 62.7 C 5.2 62.3 5 62.5 4.8 62.8 C 4.7 62.9 4.4 64.1 4.2 64.7 C 4 65.2 3.8 65.4 3.7 65.2 C 3.6 65 3.6 62.9 3.9 62 C 4.2 61.1 4.1 59 3.6 59.2 C 3.1 59.5 1.7 63.2 1.7 64.7 C 1.7 66.1 1.9 67.9 1.9 67.9 L 3.8 69 Z ' />
      </clipPath>
      <g clipPath='url(#cp48)'>
        <clipPath id='cp49'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp49)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -3.3 74 L 10.2 74 L 10.2 54.18665 L -3.3 54.18665 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp50'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 76.2 128.4 C 76.2 128.4 80 128.8 81.4 125.1 C 82.8 121.4 84.1 120.2 84.3 118.2 C 85 110.3 88.6 112.1 87.6 107.5 C 86.6 103 85.8 102.3 85.8 102.3 L 77 104 L 68.1 104 C 68.1 104 71.6 105.7 70.8 109.5 C 70 113.2 68.1 119.3 69.2 123.4 C 70.3 127.5 73.9 128.1 76.2 128.4 Z ' />
      </clipPath>
      <g clipPath='url(#cp50)'>
        <clipPath id='cp51'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp51)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 63.1 133.4126 L 92.77424 133.4126 L 92.77424 97.3 L 63.1 97.3 Z ' />
        </g>
      </g>
      <clipPath id='cp52'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 75.6 12.2 C 75.6 12.2 75.4 10.4 75 9.1 C 74.7 7.8 72.8 5 72.9 4.6 C 73 4.3 75.3 3.9 76.3 4.8 C 77.3 5.7 78 8.3 78.1 9.9 C 78.2 11.5 77.9 12.5 77.9 12.5 C 77.9 12.5 76.5 13.2 75.6 12.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp52)'>
        <clipPath id='cp53'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp53)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 67.89619 17.73819 L 83.12041 17.73819 L 83.12041 -.7530305 L 67.89619 -.7530305 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp54'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 82.8 12.2 C 82.8 12.2 82.6 10.4 82.2 9.1 C 81.9 7.8 80 5 80.1 4.6 C 80.2 4.3 82.5 3.9 83.5 4.8 C 84.5 5.7 85.2 8.3 85.3 9.9 C 85.4 11.5 85.1 12.5 85.1 12.5 C 85.1 12.5 83.7 13.2 82.8 12.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp54)'>
        <clipPath id='cp55'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp55)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 75.09619 17.73819 L 90.32041 17.73819 L 90.32041 -.7530305 L 75.09619 -.7530305 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp56'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 68.7 75.1 C 68.7 75.1 66.6 63.6 66 60.7 C 65.4 57.8 63.9 43.9 63.7 41.5 C 63.5 39.1 68.9 27.7 69.2 26.9 C 74.8 13.7 75.4 10.7 75.4 10.7 C 75.4 10.7 77.2 9.6 78.1 10.9 C 78.4 11.3 74.7 26.4 74.1 30.6 C 73.5 34.5 72.1 40.5 72.5 41.9 C 72.9 43.3 76.5 62.1 77.2 62.9 C 77.9 63.7 78.9 62.4 79.3 60.7 C 79.7 59 80.5 43.8 81 41.9 C 81.5 40 81.1 33.5 81.4 30.2 C 81.7 26.9 82.9 11.6 82.9 11.6 C 82.9 11.6 85.2 11.2 85.4 11.6 C 85.6 12 87.6 28.6 87.7 32.5 C 87.8 36.4 87.8 41.8 87.9 43.5 C 88.9 59.6 90.9 61.8 87.5 73.4 C 78.9 101.8 68.7 75.1 68.7 75.1 Z ' />
      </clipPath>
      <g clipPath='url(#cp56)'>
        <clipPath id='cp57'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp57)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 58.69458 91.47856 L 94.4005 91.47856 L 94.4005 5.265388 L 58.69458 5.265388 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp58'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 68.7 75.1 C 68.7 75.1 66.6 63.6 66 60.7 C 65.4 57.8 63.9 43.9 63.7 41.5 C 63.5 39.1 68.7 27.7 68.8 26.9 C 68.9 26.1 73.1 13.9 73.1 13.9 C 73.1 13.9 77.4 14 78.5 15.1 C 78.7 15.3 74.6 26.5 74 30.7 C 73.4 34.6 72 40.6 72.4 42 C 72.8 43.4 76.4 62.2 77.1 63 C 77.8 63.8 78.8 62.5 79.2 60.8 C 79.6 59.1 80.4 43.9 80.9 42 C 81.4 40.1 81 33.6 81.3 30.3 C 81.6 27 81 15.4 81 15.4 C 81 15.4 86.6 15.1 86.8 15.5 C 87 15.9 87.4 28.7 87.5 32.6 C 87.6 36.5 87.6 41.9 87.7 43.6 C 88.7 59.7 90.7 61.9 87.3 73.5 C 78.9 101.8 68.7 75.1 68.7 75.1 Z ' />
      </clipPath>
      <g clipPath='url(#cp58)'>
        <clipPath id='cp59'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp59)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 58.69438 91.50643 L 94.2005 91.50643 L 94.2005 8.9 L 58.69438 8.9 Z ' fill='#fab900' />
        </g>
      </g>
      <clipPath id='cp60'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 88.1 71.2 C 88.4 71.3 85.8 80 85.3 82.6 C 84.8 84.9 83.2 87.1 84.8 91 C 85.7 93.3 85.6 96 85.6 96 C 85.6 96 88 90.7 88.2 89.4 C 88.4 88 80 90.5 79.9 90.3 C 79.7 89.9 79.4 87.7 79.4 86.9 C 79.4 86.2 91.7 83.3 93.1 85.9 C 93.5 86.6 90.8 100.6 89.1 102.4 C 86.3 105.4 78.9 108 78.9 108 C 78.9 108 76.9 109.1 73.3 108 C 69.7 106.9 64.7 103.4 63.9 102.5 C 63 101.6 59.6 88 59.3 86.2 C 59 84.5 59.1 81.1 62.9 81.5 L 73.1 83.8 L 72.5 87 L 64.8 86.4 C 63.2 86.1 64 87.5 64.2 88.3 C 64.4 89 66.3 96 66.3 96 C 66.2 94.1 67.6 87.8 68.4 85.6 C 69.2 83.4 67.5 71.7 67.4 70.5 C 67.4 70.4 73.5 65.3 88.1 71.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp60)'>
        <clipPath id='cp61'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp61)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 54.19435 113.4889 L 98.13989 113.4889 L 98.13989 63.35789 L 54.19435 63.35789 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp62'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 80.6 107.4 C 80.6 107.4 78.1 102.2 76 100.7 C 76 100.7 72.2 106.4 72.4 107.7 C 72.4 107.7 76.6 109.5 80.6 107.4 Z ' />
      </clipPath>
      <g clipPath='url(#cp62)'>
        <clipPath id='cp63'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp63)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 67.39237 113.418 L 85.6 113.418 L 85.6 95.7 L 67.39237 95.7 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp64'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 80.7 89.9 C 80.7 89.9 79.5 90.4 79.2 90.8 C 78.9 91.2 77.9 91.5 77.1 91.2 C 76.3 91 76.8 87.7 76.8 87.7 L 80.8 87.2 L 80.7 89.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp64)'>
        <clipPath id='cp65'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp65)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 71.64568 96.32187 L 85.8 96.32187 L 85.8 82.2 L 71.64568 82.2 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp66'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 73.4 87.4 C 73.4 87.4 73.8 89.5 73.7 89.9 C 73.6 90.3 74.4 90.7 74.5 89.7 C 74.6 88.7 74.7 88.1 74.7 88.1 L 73.4 87.4 Z ' />
      </clipPath>
      <g clipPath='url(#cp66)'>
        <clipPath id='cp67'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp67)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 68.4 95.32762 L 79.7 95.32762 L 79.7 82.4 L 68.4 82.4 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp68'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 75.1 85.9 L 78 86 C 78.4 86 78.7 86.3 78.7 86.7 L 78.8 93.1 C 78.8 93.5 78.5 93.8 78.1 93.8 L 74.6 93.7 C 74.2 93.7 73.9 93.3 73.9 92.9 L 74.4 86.4 C 74.4 86.1 74.7 85.9 75.1 85.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp68)'>
        <clipPath id='cp69'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp69)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 68.9 98.8 L 83.8 98.8 L 83.8 80.9 L 68.9 80.9 Z ' fill='#ffffff' />
        </g>
      </g>
      <clipPath id='cp70'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 73.3 87.2 C 73.3 87.2 73.2 87.8 74.4 88.4 C 75.9 89.1 78 89.9 78.3 89.7 C 78.8 89.4 78.5 89 77.6 88.7 C 76.6 88.4 76.3 88.3 76.3 88.3 C 76.3 88.3 78.2 88.5 78.6 88.3 C 79 88.1 79.1 87.5 78.7 87.5 C 78.4 87.4 77.7 87.4 77.7 87.4 C 77.7 87.4 78.5 87.4 78.6 87 C 78.7 86.8 78.7 86.4 77.9 86.3 C 77.9 86.3 78.4 85.7 77.6 85.4 C 76.8 85 75.4 84.7 74.7 84.6 C 73.2 84.4 69.9 84.5 69.9 84.5 L 71.3 86.8 C 71.3 86.8 72.1 86.7 72.6 86.9 C 73.4 87 73.3 87.2 73.3 87.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp70)'>
        <clipPath id='cp71'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp71)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 64.9 94.7312 L 83.95464 94.7312 L 83.95464 79.475 L 64.9 79.475 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp72'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 72.5 87 L 73.1 83.9 L 66.6 82.4 L 66.3 86.5 L 72.5 87 Z ' />
      </clipPath>
      <g clipPath='url(#cp72)'>
        <clipPath id='cp73'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp73)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 61.3 92 L 78.1 92 L 78.1 77.4 L 61.3 77.4 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp74'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 79.9 90.3 C 79.9 90.3 79.4 89.1 79.4 86.9 C 79.4 86.9 83.4 85.4 86.6 85.1 L 86.6 89.1 L 79.9 90.3 Z ' />
      </clipPath>
      <g clipPath='url(#cp74)'>
        <clipPath id='cp75'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp75)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 74.4 95.3 L 91.6 95.3 L 91.6 80.1 L 74.4 80.1 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp76'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 73.1 83.9 L 68.5 81.4 C 68.5 81.9 68.6 82.4 68.6 82.9 L 73.1 83.9 Z ' />
      </clipPath>
      <g clipPath='url(#cp76)'>
        <clipPath id='cp77'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp77)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 63.5 88.9 L 78.1 88.9 L 78.1 76.4 L 63.5 76.4 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp78'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 85.1 83.5 C 81.4 85 80 86.3 79.6 86.7 L 79.5 86.8 L 84.7 85.3 C 84.8 84.7 85 84.1 85.1 83.5 Z ' />
      </clipPath>
      <g clipPath='url(#cp78)'>
        <clipPath id='cp79'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp79)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 74.5 91.8 L 90.1 91.8 L 90.1 78.5 L 74.5 78.5 Z ' fill='#415055' />
        </g>
      </g>
      <clipPath id='cp80'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 76.7 126.2 C 76.7 126.2 81.8 125.5 81 117.9 C 80.3 110.4 75.8 111 75.5 111 C 75.2 111 71 110.8 70.6 120 C 70.6 120.1 70.4 126.8 76.7 126.2 Z ' />
      </clipPath>
      <g clipPath='url(#cp80)'>
        <clipPath id='cp81'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp81)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 65.59929 131.238 L 86.08516 131.238 L 86.08516 105.9886 L 65.59929 105.9886 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp82'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 78.9 112.4 C 78.9 112.4 78.6 108.5 79.3 107.3 C 80.1 106.1 78.7 104.5 75.8 105.1 C 72.9 105.6 73.1 107.3 73.1 107.3 C 73.1 107.3 73.9 109.7 73.7 111.6 C 73.5 113.5 78.9 112.4 78.9 112.4 Z ' />
      </clipPath>
      <g clipPath='url(#cp82)'>
        <clipPath id='cp83'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp83)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 68.09706 117.7167 L 84.52258 117.7167 L 84.52258 99.96972 L 68.09706 99.96972 Z ' fill='#eea886' />
        </g>
      </g>
      <clipPath id='cp84'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 76.1 123.5 C 76.1 123.5 78.5 121 79.6 119.7 C 80.7 118.4 80.8 116 80.8 116 C 80.8 116 82.7 118.3 81.9 120.9 C 81.1 123.5 79.3 128.7 74.6 127.1 C 69.9 125.5 69.8 118.5 70.3 115.1 C 70.3 115.1 72.4 122.8 76.1 123.5 Z ' />
      </clipPath>
      <g clipPath='url(#cp84)'>
        <clipPath id='cp85'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp85)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 65.09881 132.3999 L 87.0966 132.3999 L 87.0966 110.1 L 65.09881 110.1 Z ' />
        </g>
      </g>
      <clipPath id='cp86'>
        <path transform='matrix(1,0,0,-1,0,130)' d='M 73.3 110 C 73.3 110 66.3 103.8 68.1 99.9 C 69.7 96.6 75.2 98.6 74.2 102.1 C 73.4 105.6 73.3 110 73.3 110 Z ' />
      </clipPath>
      <g clipPath='url(#cp86)'>
        <clipPath id='cp87'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M -53 -1024 L 322 -1024 L 322 759 L -53 759 Z ' />
        </clipPath>
        <g clipPath='url(#cp87)'>
          <path transform='matrix(1,0,0,-1,0,130)' d='M 62.80409 115 L 79.32062 115 L 79.32062 93.21924 L 62.80409 93.21924 Z ' fill='#415055' />
        </g>
      </g>
    </g>
  </svg>)

export default ConsultationsImage
