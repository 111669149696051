import React from 'react'
import PropTypes from 'prop-types'
import {useState, useEffect} from 'react'

const AlertCard = ({ title, message, success, linkUrl, linkText, hide }) =>  {
  const [shouldShow, setShouldShow] = useState(true)
  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        setShouldShow(false)
      }, 5000)
    }
  }, [hide])
  
  return (
    <div className={`${!shouldShow && 'hidden'} item notification-group'`} >
    {/* This component is to be used in full once we implement notifications
        Right now we only use it to show if user has a pending contract */}
    {/* <div className='title secondary'>Here's what you've missed</div> */}
    <div className='stack'>
      <div className={`notification ${success && 'confirmation'}`}>
        <div className='content'>
          {title && <div className='header'>
            {/* <span className='priority'>URGENT</span>
            <span className='info'>NEW IN INBOX</span> */}
            <span className='priority'>{title}</span>
          </div>}
          <div className='message'>
            {message}
            <br />
            <a href={linkUrl} rel="nofollow noreferrer noopener" target='_blank'>{linkText}</a>
          </div>
          {/* <div className='time'>Today at 09:52</div>
          <div className='close'>
            <img src={iconsCloseSmall} />
          </div> */}
        </div>
      </div>
    </div>
    { /* <div className='text-center'>
      <a className='button tertiary'>See all</a>
    </div> */}
  </div>
  )
}



AlertCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  success: PropTypes.bool,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  hide: PropTypes.bool
}
export default AlertCard
