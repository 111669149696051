import React, { Component } from 'react'
import { getAll } from '../../../store/offer.store'
import LoadingPageOverlay from '../../../components/LoadingPageOverlay'
import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import './index.css'
import withRouter from '../../../components/withRouter'

class Offers extends Component {
  constructor (props) {
    super(props)
    extendObservable(this, {
      offers: [],
      isLoading: true,
      total: 0,
      lastFetchSkip: 0
    })
    this.getAllOffers()
  }
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  handleClick = (id) => {
    this.props.navigate('/offer/' + id)
  }

  getAllOffers = (skip = 0, limit = 10) => {
    getAll(skip, limit)
      .then(({ offers, total }) => {
        const remapedOffers = offers.map(o => {
          var offer = {}
          offer.id = o.id
          offer.image = 'https://www.grandcityproperty.de' + o.image.thumbnails['news-overview-image']
          offer.title = o.headline
          offer.description = o.text
          return offer
        })
        const updatedOffers = this.offers.concat(remapedOffers)
        this.offers = updatedOffers
        this.total = total

        if (this.total > this.offers.length) {
          this.lastFetchSkip = this.lastFetchSkip + remapedOffers.length
          this.getAllOffers(this.lastFetchSkip, 10)
        }

        this.isLoading = false
      })
      .catch(() => {
        this.isLoading = false
      })
  }

  render () {
    if (this.isLoading) {
      return (
        <LoadingPageOverlay />
      )
    }
    return (
      <div className='voucher-page-holder'>
        {this.offers.map(item => (
          <div key={item.id} className='container voucher-item-holder' onClick={() => { this.handleClick(item.id) }}>
            <div className={`image ${item.id === 4 && 'loyalty'}`}>
              <img src={item.image} />
              {item.id === 3 && (
                <div>
                  <p>{item.image_text_1}</p>
                  <p>{item.image_text_2}</p>
                  <p>{item.image_text_3}</p>
                </div>)}
            </div>
            <div className='voucher-item-text'>
              <h3 className='title'>{item.title}</h3>
              <p className='description'>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

export default withRouter(observer(Offers))
