import React from 'react'
import PropTypes from 'prop-types'

const NoContent = props => {
  const { message, image } = props
  return (
    <div className='no-content'>
      <div className='message'>{message}</div>
      <div className='image'>
        {image}
      </div>
    </div>
  )
}

NoContent.propTypes = {
  message: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired

}
export default NoContent
