import { observer, inject } from 'mobx-react'
import React, { Component } from 'react'
import User from '../../store/user.store'
import t from '../../service/translate.service'
import { extendObservable } from 'mobx'
import faqCategoryStore from '../../store/admin/FaqCategory.store'
import { Form, Image, Button } from 'react-bootstrap'
import history from '../../service/history.service'
import { availableLinks } from '../../config'
import PropTypes from 'prop-types'
import { Textarea } from '../../components/formComponents'
import Emitter from '../../service/event.emitter'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import withRouter from '../../components/withRouter'

class FaqList extends Component {

    static propTypes = {
      faqFeedbackStore: PropTypes.object.isRequired
    }
    constructor (props) {
      super(props)

      extendObservable(this, {
        category: null,
        locale: 'De',
        feedbackRef: null
      })

      this.getCategory()

      if (t.locale === 'en') this.locale = ''
      else this.locale = t.locale.charAt(0).toUpperCase() + t.locale.slice(1)
    }

    getCategory = () => {
    const id = this.props.useParams.id
    faqCategoryStore.findOne(parseInt(id)).then((category) => {
      this.category = category
    })
  }

    getAttrs = field => {
      const { model, setVal, validators } = this.props.faqFeedbackStore
      return {
        value: model[field],
        onChange: e => setVal(field, e.target.value),
        validate: validators[field]
      }
    }

    getAttrs = field => {
      const { model, setVal, validators } = this.props.faqFeedbackStore
      return {
        value: model[field],
        onChange: e => setVal(field, e.target.value),
        validate: validators[field]
      }
    }

    handleLinkClick = (link) => {

      if (link.includes('phone_numbers') || link.includes('contact')) {
        if (this.feedbackRef) this.feedbackRef.scrollIntoView({ behavior: 'smooth' })
      } else if (link.includes('chat')) {
        Emitter.emit('openChat', {})
      } else if (link.includes('apartment_search')) {
        window.open('https://www.grandcityproperty.de/wohnungssuche', '_blank')
      } else {
        this.props.navigate(availableLinks.find(l => l.type === link).to)
      }
    }

    submitFeedback = (e) => {
      e.preventDefault()
      this.props.faqFeedbackStore.submit()
    }
    handleBack = () => {
      this.props.faqFeedbackStore.resetForm()
    }

    render () {
      return (
        <div>
          {this.props.faqFeedbackStore.showThankYouPage ? <ThankYouOverlay
            title={t.THANK_YOU}
            description={t.FEEDBACK_THANK_YOU}
            buttonText={t.BACK_TO_FAQ}
            buttonLink='/faq'
            handleClick={this.handleBack}
          />
            : <div className='faq-questions-list'>

              {this.category && (
                <div>
                  <div className='title secondary'>{this.category[`name${this.locale}`]}</div>
                  {this.category && (
                    this.category?.faqQuestions?.map((question) => {
                      let links = []
                      if (question.links) {
                        var translated = JSON.parse(question.links)
                        if (translated && translated !== null) {
                          links = translated
                          if (!User.user.tenant) {
                            links = links?.filter(link => link === 'contact' || link === 'apartment_search' || link === 'phone_numbers')
                          }
                        }
                      }
                      return (
                        <div className='faq-question-wrap' key={question.id}>
                          <div className='faq-question'>{question[`question${this.locale}`]}</div>
                          <div className='faq-answer'>{question[`answer${this.locale}`]}</div>

                          {question.image && (
                            <Image className='faq-image' src={question.image} />
                          )}
                          <div className='faq-links'>
                            {question.linksText && <div className='faq-links-text'>{question[`linksText${this.locale}`]}&nbsp;</div>}
                            {!!links.length &&
                            links?.map((link, index) => (
                              <div className='faq-link' key={link}>
                                <div onClick={() => this.handleLinkClick(link)}>
                                  {availableLinks.find(l => l.type === link)[`name${this.locale}`]}
                                </div>
                                {index !== links.length - 1 && <span>,&nbsp;</span>}
                              </div>
                            ))}
                          </div>

                          <div className='faq-contact-text'>
                            <div>
                              <span>{t.MAIN_FAQ_CONTACT_US_1}</span>&nbsp;
                              <span className='faq-link' onClick={() => this.handleLinkClick('contact')}>{t.MAIN_FAQ_CONTACT_US_2}</span>&nbsp;
                              <span>{t.MAIN_FAQ_CONTACT_US_3}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  )}
                </div>
              )}
              <div className='faq-form' ref={(el) => { this.feedbackRef = el }}>
                <div className='faq-form-title'>
                  {t.MAIN_FAQ_FEEDBACK_TEXT}
                </div>
                <Form>
                  <Textarea
                    {...this.getAttrs('feedback')}
                    name='feedback'
                    label='Feedback'
                  />
                  <Form.Group className='text-center'>
                    <Button type='submit' className='button primary'
                      onClick={this.submitFeedback}>
                      {t.MAIN_FAQ_SUBMIT}
                    </Button>
                  </Form.Group>
                </Form>
              </div>
            </div>}
        </div>
      )
    }
}

export default inject('faqFeedbackStore')(withRouter(observer(FaqList)))
