import { action, extendObservable } from 'mobx'
import { isAndroid, isIOS } from '../service/deviceDetection.service'
import { MIN_DESKTOP_SIZE } from '../const'
class MobileRedirectPopUp {
  constructor() {
    extendObservable(this, {
      isOpen: window.innerWidth < MIN_DESKTOP_SIZE,
      link: ''
    })
    this.setLink()
    window.addEventListener('resize', this.handleResize)
  }

  dispose() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize() {
    this.isOpen = window.innerWidth < MIN_DESKTOP_SIZE
  }

  setIsOpen = () => {
    this.isOpen = false
  }

  setLink() {
    if (isAndroid) {
      this.link = 'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.gcpapp'
    } else if (isIOS) {
      this.link = 'https://apps.apple.com/us/app/gcp-service-app/id1190303260'
    }
  }
}

export default MobileRedirectPopUp
