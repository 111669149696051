import { api } from '../service/api.service'
import { extractResponse, extractError } from '../service/response.service'
import { extendObservable } from 'mobx'
import {
  buildValidators,
  email,
  required,
  betweenLength,
  phone,
  birthday,
  phone2
} from '../service/validate'
import find from 'lodash/find'
import pick from 'lodash/pick'
import moment from 'moment'
import t from '../service/translate.service'

export default class ProfileStore {
  constructor () {
    extendObservable(this, {
      model: {
        birthday: '',
        confirmPassword: '',
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        password: '',
        phone: '',
        preferredLanguage: '',
        subscribed: null
      },
      submitErr: null,
      submitted: false,
      successSubmit: false,
      errorMsg: '',
      loader: true,
      invalidEmails: []
    })
    api
      .get('/api/user/profile')
      .then(extractResponse)
      .then(user => {
        this.model = Object.assign(
          this.model,
          pick(user, Object.keys(this.model))
        )

        this.model.birthday = user.birthday && moment(user.birthday).format('YYYY/MM/DD')
        this.loader = false
      })
  }

  get validators () {
    return buildValidators(
      {
        email: [required, email],
        firstName: [betweenLength(1, 32)],
        lastName: [betweenLength(1, 32)],
        mobile: [phone2],
        phone: [phone],
        birthday: [birthday]
      },
      this.submitted
    )
  }

  setVal = (field, val) => {
    this.model[field] = val
  }

  submit = async () => {
    this.submitted = true
    this.submitErr = null
    const isValid = !find(this.validators, (validate, field) =>
      validate(this.model[field])
    )
    if (!isValid) return

    this.loader = true

    return api
      .put('/api/user/profile', { ...this.model })
      .then(() => {
        this.loader = false
        this.successSubmit = true
      })
      .catch(err => {
        this.loader = false
        this.submitErr = true
        if(t[extractError(err)]) {
          this.errorMsg = t[extractError(err)]
        } else this.errorMsg = t.RESEND_VERIFICATION_EMAIL_ERROR
        throw err
      })
  }

  deleteAccount = async () => {
    this.loader = true

    return api.put('/api/user/deactivate-profile', {active: false}).finally(() => this.loader = false)
  }
}
