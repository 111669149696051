import React from 'react'
import moment from 'moment'
import { Button, DatePicker } from 'antd'
import PropTypes from 'prop-types'

const UserActivityForm = ({ model, setVal, onSubmit }) => {
  return (
    <div>
      <h1>User activity</h1>
      <form onSubmit={onSubmit} className='user-activity'>
        <div className='input-div'>
          <label>Start date</label>
          <DatePicker
            disabledDate={date => date && date > moment().toDate()}
            defaultValue={model.startDate ? moment(model.startDate) : null}
            onChange={(date, dateString) => setVal('startDate', dateString === '' ? null : dateString)}
          />
        </div>
        <div className='input-div'>
          <label>End date</label>
          <DatePicker
            disabledDate={date => date && date > moment().toDate()}
            defaultValue={model.endDate ? moment(model.endDate) : null}
            onChange={(date, dateString) => setVal('endDate', dateString === '' ? null : dateString)}
          />
        </div>
        <div className='input-div'>
          <div>
            <Button type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

UserActivityForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  setVal: PropTypes.func
}
export default UserActivityForm
