import React, { useState } from 'react'
import moment from 'moment-timezone'
import t from '../../../../service/translate.service'
import CancelSuccessModal from './CancelSuccessModal';
import CancelAppointmentConfirmationModal from './CancelAppointmentConfirmationModal';

export const Booking = (props) => {
  const navigate = props.navigate
  const [showCancelConfiramtionModal, setShowCancelConfiramtionModal] = useState(false)
  const [successCanceled, setSuccessCanceled] = useState(false)
  const startDate = props?.store?.event?.start ? moment(
    props.store.event.start.dateTime).format("DD.MM.YYYY") : ''
  const startTime = props?.store?.event?.start ? moment(
    props.store.event.start.dateTime).format("HH:mm") : ''
  const eventId = props?.store?.event?.id || ''

  return (
    <div>
      {showCancelConfiramtionModal && (
        <CancelAppointmentConfirmationModal
          setShowCancelConfiramtionModal={setShowCancelConfiramtionModal}
          setSuccessCanceled={setSuccessCanceled}
          date={startDate}
          time={startTime}
          eventId={eventId}
          cancelEvent={props.cancelEvent}
          contractNumber={props.contractStore.selectedContract.contractNumber}
        />
      )}
      {successCanceled && (
        <CancelSuccessModal
          setSuccessCanceled={setSuccessCanceled}
          date={startDate}
          time={startTime}
        />
      )}
      {props.isAppointmentRequested === "requested" &&
        props.store.event && (
          <div className="text-center">
            <p
              style={{
                fontSize: "18px",
                marginBottom: "60px",
              }}
            >
              {t.CONSULTATIONS_YOU_ALREADY_BOOKED_DATE_1}
              {startDate}
              {t.CONSULTATIONS_YOU_ALREADY_BOOKED_DATE_2}
              {startTime}
              {t.CONSULTATIONS_YOU_ALREADY_BOOKED_DATE_3}
            </p>
          </div>
        )}
        <div className="text-center">
          {props.isAppointmentRequested === "not_requested" && (
            <button
              onClick={() =>
                navigate(
                  `/consultations/${props.contractStore.selectedContract.contractNumber}/slots`
                )
              }
              className="button primary"
            >
              {t.CONSULTATIONS_PAGE_BOOK_APPOINTMENT}
            </button>
          )}
          {props.isAppointmentRequested === "requested" && (
            <button onClick={() => setShowCancelConfiramtionModal(true)} className="button primary">
              {t.CONSULTATIONS_PAGE_REMOVE_APPOINTMENT}
            </button>
          )}
        </div>
    </div>
  );
};
