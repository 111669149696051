import React from 'react'

const InboxIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='20pt'
    height='20pt'
    viewBox='0 0 20 20'
  >
    <g enableBackground='new'>
      <clipPath id='svg-inbox'>
        <path
          transform='matrix(1,0,0,-1,0,20)'
          d='M 20 18 L 20 2 L 0 2 L 0 18 L 20 18 Z M 18.8 15.882 L 10.00006 6.103092 L 1.199 15.882 L 1.2 3.2 L 18.8 3.2 L 18.8 15.882 Z M 18.012 16.8 L 1.987 16.8 L 10 7.898 L 18.012 16.8 Z '
        />
      </clipPath>
      <g clipPath='url(#svg-inbox)'>
        <path
          transform='matrix(1,0,0,-1,0,20)'
          d='M -5 23 L 25 23 L 25 -3 L -5 -3 Z '
          fill='white'
        />
      </g>
    </g>
  </svg>
)

export default InboxIcon
