import React from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import { Input } from '../../components/formComponents'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'
import { Alert } from 'antd'

const LoyaltyRedeemCode = observer(({ store, onCancel, onSubmit, uploadError }) => {
  const { setCode, code } = store
  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.UPLOAD_CODE_BUTTON} />
        <p>{t.LOYALTY_CODE_SUBTITLE}</p>
      </div>
      <Form onSubmit={(e) => onSubmit(e)}>
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Input
              value={code}
              placeholder={t.LOYALTY_CODE_PLACEHOLDER}
              onChange={e => setCode(e.target.value)}
              styles={{ marginBottom: '1rem' }}
            />

            <Button type='submit' className='button primary'>{t.LOYALTY_SUBMIT_CODE}</Button>
          </div>
        </Form.Group>
      </Form>
      {uploadError && <Alert message={uploadError} type='error' />}
      <Button className='button tertiary' onClick={(e) => onCancel(e)}>{t.FORM_CANCEL}</Button>
    </div>
  )
})

export default LoyaltyRedeemCode
