import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { getOne } from '../store/offer.store'
import { extendObservable } from 'mobx'
import LoadingPageOverlay from '../components/LoadingPageOverlay'
import { PropTypes } from 'prop-types'
import withRouter from '../components/withRouter'

class OfferItem extends Component {
  constructor (props) {
    super(props)
    this.id = props.useParams.id

    extendObservable(this, {
      article: {}
    })

    getOne(this.id).then(res => (this.article = res))
  }
  render () {
    const { image, content } = this.article
    return (
      <div>
        {image ? (
          <div className='offer-item'>
            <div className='image'>
              <img src={`https://www.grandcityproperty.de${image.realFullPath}`} />
            </div>
            <div
              className='news-article'
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        ) : <LoadingPageOverlay />}
      </div>
    )
  }
}

OfferItem.propTypes = {
  useParams: PropTypes.object
}

export default withRouter(observer(OfferItem))
