import { action, extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'
import { api } from '../../service/api.service'

class FaqCategoryStore {
  constructor () {
    extendObservable(this, {
      faqCategories: [],
      model: {},
      error: null,
      page: 1,
      count: 0,
      loading: false,
      query: '',
      dragDisabled: false,
      formSubmittedSuccessfully: false
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  load = action(id => {
    if (id === 'new') return
    this.loading = true
    api
      .get('/api/faq-category/' + id)
      .then(extractResponse)
      .then(res => {
        const model = pick(res, [
          'id',
          'name',
          'nameDe',
          'position'
        ])

        this.model = model
      })
      .finally(() => {
        this.loading = false
      })
  })

  new () {
    this.model = {
      id: null,
      name: null,
      nameDe: null,
      position: 0
    }
  }

  deleteFaqCategory (id) {
    api.delete('/api/faq-category/' + id).then(() => {
      this.getAll()
    })
  }
  getAll (includeFaqQuestions = false) {
    this.loading = true
    return api.get(`/api/faq-categories?q=${this.query}&includeFaqQuestions=${includeFaqQuestions}`)
      .then(res => {
        const { count, rows } = res.data
        this.faqCategories = rows
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  async findOne (id) {
    const includeFaqQuestions = true
    if (this.faqCategories.length === 0) await this.getAll(includeFaqQuestions)
    return this.faqCategories.find(category => category.id === id)
  }

  setVal = (key, val) => {
    console.log('Setting', key, val)
    set(this.model, key, val)
  }

  save () {
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    return api
      .post(
        '/api/faq-category',
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/faq-categories/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/faq-category' + path,
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/faq-categories/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  updateQuery (val) {
    this.dragDisabled = !!val
    this.query = val
    this.debouncedGetAll()
  }

  saveOrder = () => {
    const faqCategories = this.faqCategories.map(faqCategory => {
      return { id: faqCategory.id, position: faqCategory.position }
    })

    this.loading = true
    api
      .post('/api/faq-category/reorder', { faqCategories })
      .then(res => {
        this.faqCategories.replace(extractResponse(res))
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
export default new FaqCategoryStore()
