import { api, API_BASE } from '../service/api.service'

const EVENT_ENDPOINT = `${API_BASE}/api/address`

function find (q) {
  return api.get(EVENT_ENDPOINT, { params: { q, limit: 25 } })
    .then(res => res.data)
}

export { find }
