import React, { Component } from 'react'
import t from '../../service/translate.service'
import { MaintenanceImage } from '../../components/icons'

class LoyaltyMaintenance extends Component {
  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    return (
      <div className='message-text'>
        <p>{t.LOYALTY_MAINTENANCE_P1}</p>
        <p>{t.LOYALTY_MAINTENANCE_P2}</p>
        <p>{t.LOYALTY_MAINTENANCE_P3}</p>
        <div className='image' display='flex'>
          {MaintenanceImage}
        </div>
      </div>
    )
  }
}

export default LoyaltyMaintenance
