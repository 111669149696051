import React from 'react'

const CalendarIcon = (
  <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' version='1.1' viewBox='0 0 752 752'>
    <g fill='#888'>
      <path d='m202.38 589.11h291.88c5.8633 0 11.488-2.3203 15.648-6.4492l71.402-70.914c4.1953-4.168 6.5586-9.8398 6.5586-15.758v-256.63c0-21.117-17.121-38.238-38.238-38.238h-38.227l-0.003906-18.551c0-10.867-8.8086-19.68-19.68-19.68h-8.3359c-10.867 0-19.676 8.8086-19.676 19.676v18.551h-174.57l-0.003906-18.547c0-10.867-8.8086-19.68-19.676-19.68h-8.3359c-10.867 0-19.684 8.8125-19.684 19.684v18.551h-39.074c-21.117 0-38.238 17.121-38.238 38.238v311.52c0.003907 21.113 17.121 38.234 38.246 38.234zm-17.824-38.238v-263.39h382.89v189.32l-47.703-0.003906c-21.082 0-38.23 17.152-38.23 38.238v53.656h-279.13c-9.8281 0-17.824-7.9922-17.824-17.816zm381.45-52.676-64.074 67.438v-50.602c0-9.8203 7.9922-17.816 17.812-17.816h47.402c-0.39453 0.30859-0.79297 0.61328-1.1406 0.98047z' />
      <path d='m290.54 307.04h-44.684c-13.863 0-25.137 12.152-25.137 27.078v20.145c0 14.934 11.277 27.078 25.137 27.078h44.684c13.867 0 25.141-12.152 25.141-27.078v-20.145c0-14.926-11.273-27.078-25.141-27.078zm9.8242 47.23c0 6.4883-4.4102 11.766-9.8281 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.145c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.418 0 9.8281 5.2773 9.8281 11.766z' />
      <path d='m398.77 307.04h-44.684c-13.863 0-25.137 12.152-25.137 27.078v20.145c0 14.934 11.277 27.078 25.137 27.078h44.684c13.863 0 25.137-12.152 25.137-27.078v-20.145c0-14.926-11.277-27.078-25.137-27.078zm9.8242 47.23c0 6.4883-4.4102 11.766-9.8203 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.145c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.4141 0 9.8203 5.2773 9.8203 11.766z' />
      <path d='m462.32 381.34h44.684c13.863 0 25.137-12.152 25.137-27.078v-20.145c0-14.934-11.277-27.078-25.137-27.078h-44.684c-13.863 0-25.137 12.152-25.137 27.078v20.145c0 14.926 11.273 27.078 25.137 27.078zm-9.8203-47.23c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.4141 0 9.8203 5.2773 9.8203 11.766v20.145c0 6.4883-4.4102 11.766-9.8203 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766z' />
      <path d='m290.54 391.55h-44.684c-13.863 0-25.137 12.148-25.137 27.078v20.152c0 14.934 11.277 27.078 25.137 27.078h44.684c13.867 0 25.141-12.148 25.141-27.078v-20.152c0-14.934-11.273-27.078-25.141-27.078zm9.8242 47.23c0 6.4883-4.4102 11.766-9.8281 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.152c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.418 0 9.8281 5.2773 9.8281 11.766z' />
      <path d='m398.77 391.55h-44.684c-13.863 0-25.137 12.148-25.137 27.078v20.152c0 14.934 11.277 27.078 25.137 27.078h44.684c13.863 0 25.137-12.148 25.137-27.078v-20.152c0-14.934-11.277-27.078-25.137-27.078zm9.8242 47.23c0 6.4883-4.4102 11.766-9.8203 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.152c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.4141 0 9.8203 5.2773 9.8203 11.766z' />
      <path d='m532.13 438.79v-20.152c0-14.934-11.277-27.078-25.137-27.078h-44.684c-13.863 0-25.137 12.148-25.137 27.078v20.152c0 14.934 11.277 27.078 25.137 27.078h44.684c13.859 0 25.137-12.152 25.137-27.078zm-79.637 0v-20.152c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.4141 0 9.8203 5.2773 9.8203 11.766v20.152c0 6.4883-4.4102 11.766-9.8203 11.766h-44.684c-5.4141-0.003906-9.8203-5.2773-9.8203-11.766z' />
      <path d='m290.54 476.07h-44.684c-13.863 0-25.137 12.148-25.137 27.078v20.152c0 14.934 11.277 27.078 25.137 27.078h44.684c13.867 0 25.141-12.148 25.141-27.078v-20.152c0-14.93-11.273-27.078-25.141-27.078zm9.8242 47.227c0 6.4883-4.4102 11.766-9.8281 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.152c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.418 0 9.8281 5.2773 9.8281 11.766z' />
      <path d='m398.77 476.07h-44.684c-13.863 0-25.137 12.148-25.137 27.078v20.152c0 14.934 11.277 27.078 25.137 27.078h44.684c13.863 0 25.137-12.148 25.137-27.078v-20.152c0-14.93-11.277-27.078-25.137-27.078zm9.8242 47.227c0 6.4883-4.4102 11.766-9.8203 11.766h-44.684c-5.4141 0-9.8203-5.2773-9.8203-11.766v-20.152c0-6.4883 4.4102-11.766 9.8203-11.766h44.684c5.4141 0 9.8203 5.2773 9.8203 11.766z' />
    </g>
  </svg>
)

export default CalendarIcon
