import t from '../../service/translate.service'

export default {
  get rating() {
    return {
      label: t['FEEDBACK_TYPE'],
      rules: 'required|integer|between:1,5'
    }
  },
  get description() {
    return {
      label: t['FEEDBACK_TEXT'],
      rules: 'required|string|between:1,1024',
      value: '',
      element: 'Textarea'
    }
  },
  get allowPublishing() {
    return {
      label: t['FEEDBACK_CAN_PUBLISH'],
      element: 'Checkbox',
      value: false
    }
  }
}
