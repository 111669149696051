import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Popconfirm, Spin, Input, Button } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import t from '../../../service/translate.service'
import faqQuestionListStore from '../../../store/admin/FaqQuestionList.store'

const paginationContainerStyle = {
  marginTop: 10,
  marginBottom: 20,
  display: 'flex'
}

const queryInputStyle = {
  float: 'right',
  width: 300,
  marginTop: -40
}

class FaqQuestionsList extends Component {
  constructor (props) {
    super(props)

    faqQuestionListStore.getAll()
  }

  getItemStyle = (draggableStyle, isDragging) => {
    return {
      userSelect: 'none',
      border: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottom: '1px solid #e9e9e9',
      padding: '8px 20px',
      alignItems: 'center',
      background: 'white',
      boxShadow: isDragging ? '2px 2px 25px -10px black' : '',
      ...draggableStyle
    }
  }

  getListStyle = () => ({
    transition: 'background 0.2s ease-out'
  })

  onDragEnd = (result) => {
    const { source, destination } = result
    if (!destination) return

    const [removed] = faqQuestionListStore.faqQuestions.splice(source.index, 1)
    faqQuestionListStore.faqQuestions.splice(destination.index, 0, removed)
    faqQuestionListStore.faqQuestions.map((faqQuestion, index) => {
      faqQuestion.position = index + 1
      return faqQuestion
    })
  }

  render () {
    console.log(faqQuestionListStore.faqQuestions)
    return (
      <div>
        <h1>Faq Questions</h1>
        <div style={queryInputStyle}>
          <div className='table-inputs'>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={faqQuestionListStore.query}
              onChange={e => faqQuestionListStore.updateQuery(e.target.value)}
            />
          </div>
        </div>
        <div style={{ minWidth: 900 }}>
          <div className='table__header'>
            <div style={{ width: 130 }}>{t['PARTNER_NAME']}</div>
            <div style={{ width: 130 }}>With Image</div>
            <div style={{ width: 130 }}>Category</div>
            <div style={{ width: 60 }} />
            <div style={{ width: 60 }} />
          </div>
          {faqQuestionListStore.loading
            ? <div className='spinner-container'><Spin /></div>
            : <div style={{ minHeight: 400 }}>
              <DragDropContext
                onDragEnd={this.onDragEnd}
              >
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={this.getListStyle(snapshot.isDraggingOver)}
                    >

                      {faqQuestionListStore.faqQuestions.length
                        ? faqQuestionListStore.faqQuestions.map((p, index) => (
                          <Draggable
                            index={index}
                            key={p.id}
                            draggableId={`ala-${index}`}
                            isDragDisabled={faqQuestionListStore.dragDisabled}
                          >
                            {(provided, snapshot) => {
                              return (
                                <div>
                                  <div ref={provided.innerRef}
                                    {...provided.draggableProps} {...provided.dragHandleProps}
                                    style={this.getItemStyle(
                                      provided.draggableProps.style,
                                      snapshot.isDragging
                                    )}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ width: 130 }}>
                                      <Link to={`/admin/faq-question/${p.id}`}>
                                        {p.questionDe}
                                      </Link>
                                    </div>
                                    <div style={{ width: 130 }}>{p.image ? '1' : '0'}</div>
                                    <div style={{ width: 130 }}>{p.faqCategory.nameDe}</div>
                                    <div style={{ width: 60 }}>
                                      <Link to={`/admin/faq-question/${p.id}`}>Edit</Link>
                                    </div>
                                    <div className='m-r-sm' style={{ width: 60 }}>
                                      <Popconfirm
                                        placement='topRight'
                                        title={`Are you sure you want to delete this faqQuestion (${p.questionDe})?`}
                                        onConfirm={() => faqQuestionListStore.deleteFaqQuestion(p.id)}
                                        okText='Yes'
                                        cancelText='No'
                                      >
                                        <Button className='ant-btn ant-sm'>Delete</Button>
                                      </Popconfirm>
                                    </div>
                                  </div>
                                  {provided.placeholder}
                                </div>
                              )
                            }}
                          </Draggable>
                        ))
                        : <div style={paginationContainerStyle}>No results</div>
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <div style={paginationContainerStyle}>
                <Link to={'/admin/faq-question/new'}>
                  <Button className='ant-btn ant-btn-primary m-r-sm'>New Faq Question</Button>
                </Link>
                <Button
                  className='ant-btn ant-btn-secondary'
                  onClick={faqQuestionListStore.saveOrder}
                >
                  Save reordering
                </Button>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default observer(FaqQuestionsList)
