import React, { Component, useState } from 'react'
import { observer } from 'mobx-react'
import { Popconfirm, Pagination, Input, Button } from 'antd'
import moment from 'moment'
import sapInvalidCallsListStore from '../../../store/admin/SapInvalidCallsList.store'
import PropTypes from 'prop-types'

const paginationContainerStyle = {
  textAlign: 'center',
  marginTop: 10
}

const queryInputStyle = {
  float: 'right',
  display: 'flex',
  width: 500,
  marginTop: -40
}

class SapInvalidCallsList extends Component {
  constructor (props) {
    super(props)
    sapInvalidCallsListStore.getAll()
  }

  render () {
    return (
      <div>
        <h1>Sap invalid calls</h1>
        <div style={queryInputStyle}>
          <div style={{ margin: 3 }} className='table-inputs'>
            <label>Name/Email</label>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={sapInvalidCallsListStore.query}
              onChange={e => sapInvalidCallsListStore.updateQuery(e.target.value)}
            />
          </div>
        </div>
        {(!sapInvalidCallsListStore.loading || sapInvalidCallsListStore.calls.length) && (
          <div>
            <table className='table sap-invalid-calls-table'>
              <thead>
                <tr>
                  <th className='order-results'>Endpoint</th>
                  <th className='order-results'>User</th>
                  <th className='order-results'>Payload</th>
                  <th className='order-results'>Status Code</th>
                  <th className='order-results'>Response message</th>
                  <th className='order-results'>Status</th>
                  <th className='order-results' onClick={e => sapInvalidCallsListStore.updateQueryOrdering('created_at')}>Created at</th>
                  <th className='order-results' onClick={e => sapInvalidCallsListStore.updateQueryOrdering('updated_at')}>Updated at</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {sapInvalidCallsListStore.calls.map((c, index) => (
                  <TableRow c={c} key={index} sapInvalidCallsListStore={sapInvalidCallsListStore} />
                ))}
              </tbody>
            </table>
            <div style={paginationContainerStyle}>
              <Pagination
                current={sapInvalidCallsListStore.page}
                total={sapInvalidCallsListStore.count}
                pageSize={10}
                onChange={p => sapInvalidCallsListStore.onPageChange(p)}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

const TableRow = (props) => {
  const [showPayload, setShowPayload] = useState(false)
  const showPayloadModal = () => {
    setShowPayload(!showPayload)
  }
  const callStatus = (isResolved) => {
    if (isResolved) {
      return (
        <div>
          <div className='statusIcon active' />{' '}
          <span>Resolved</span>
        </div>
      )
    } else {
      return (
        <div>
          <div className='statusIcon inactive' />{' '}
          <span>Not resolved</span>
        </div>
      )
    }
  }
  const { c, sapInvalidCallsListStore } = props
  return (
    <tr style={{ position: 'relative' }}>
      <td>{c.endpoint}</td>
      <td style={{ maxWidth: '150px' }}>{c.userFirstName} {c.userLastName} {c.userEmail}</td>
      <td>
        <div style={{ display: `${showPayload ? 'block' : 'none'}`, ...payloadModalStyle }} className='payload-modal'>
          <Button style={{ marginBottom: '12px', marginLeft: 'auto', display: 'block' }} className='ant-btn ant-sm' onClick={() => showPayloadModal()}>X</Button>
          <p>{c.payload}</p>
          <Button style={{ marginTop: '12px' }} className='ant-btn ant-sm' onClick={() => showPayloadModal()}>Close</Button>
        </div>
        <Button className='ant-btn ant-sm' onClick={() => showPayloadModal()}>Show payload</Button>
      </td>
      <td>{c.responseStatusCode}</td>
      <td>{c.responseMessage && c.responseMessage}</td>
      <td>{callStatus(c.isResolved)}</td>
      <td>{moment(c.createdAt).format('DD-MM-YYYY')}</td>
      <td>{moment(c.updatedAt).format('DD-MM-YYYY')}</td>
      <td>
        {!c.isResolved && <Popconfirm
          placement='topRight'
          title={`Are you sure you want to try again`}
          onConfirm={() => sapInvalidCallsListStore.updateCall({ id: c.id, payload: c.payload })}
          okText='Yes'
          cancelText='No'
        >
          <Button className='ant-btn ant-sm'>Try again</Button>
        </Popconfirm>}
      </td>
      <td>
        <Popconfirm
          placement='topRight'
          title={`Are you sure you want to delete this call`}
          onConfirm={() => sapInvalidCallsListStore.deleteCall(c.id)}
          okText='Yes'
          cancelText='No'
        >
          <Button className='ant-btn ant-sm'>Delete</Button>
        </Popconfirm>
      </td>
    </tr>
  )
}

const payloadModalStyle = {
  position: 'absolute',
  maxWidth: '80%',
  left: '50%',
  transform: 'translateX(-50%)',
  zIndex: 5,
  backgroundColor: '#fff',
  border: '1px solid #f0f0f0',
  padding: '18px',
  wordWrap: 'break-word',
  top: '-120px',
  maxHeight: '620px',
  overflowY: 'auto'
}

TableRow.propTypes = {
  c: PropTypes.object,
  sapInvalidCallsListStore: PropTypes.object
}

export default observer(SapInvalidCallsList)
