import React from 'react'

const FreshChatIcon = (
  <svg version='1.1' id='freshchat-icon' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px'
    viewBox='-78 223 10 117'>
    <g id='Dev-guide'>
      <g id='freshdesk-design-guide-ui-components'>
        <g id='freshchat-light-bg'>
          <path id='Shape_9_' fill='#415055' d='M-74.1,343L-74.1,343c-31.9,0-57.6-25.8-57.6-57.8l0,0c0-32,25.8-57.8,57.6-57.8h43.4
            c8.1,0,14.6,6.5,14.6,14.6v43.2C-16.2,317.2-41.9,343-74.1,343z' />
          <path id='Shape_10_' fill='#FFFFFF' d='M-55.9,261.4h-28.9c-8.2,0-14.6,6.5-14.6,14.6v24.8c0,1.7,1.4,3.1,3.1,3.1h40.1
            c8.2,0,14.6-6.5,14.6-14.6V276C-41.7,267.9-48.1,261.4-55.9,261.4z M-66.5,292.3h-21.1c-1.7,0-2.7-1.4-2.7-2.7s1.4-2.7,2.7-2.7
            h21.1c1.7,0,2.7,1.4,2.7,2.7S-64.8,292.3-66.5,292.3z M-53.6,278.7h-34c-1.7,0-2.7-1.4-2.7-2.7c0-1.7,1.4-2.7,2.7-2.7h34
            c1.7,0,2.7,1.4,2.7,2.7S-52.2,278.7-53.6,278.7z' />
        </g>
      </g>
    </g>
  </svg>
)

export default FreshChatIcon
