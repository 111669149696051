import React from 'react'
import PropTypes from 'prop-types'

const RepairsAndInquiriesSideBar = (props) => {
  const { itemContentComponent, selectedItem, items } = props
  return (
    items.map((item) => (
      item.id === selectedItem.id && (
        itemContentComponent && (
          <div key={item.id} className='item'>
            <div className='container'>
              {React.cloneElement(itemContentComponent, { item })}
            </div>
          </div>
        )
      )
    ))
  )
}

RepairsAndInquiriesSideBar.propTypes = {
  itemContentComponent: PropTypes.object,
  selectedItem: PropTypes.object,
  items: PropTypes.array
}

export default RepairsAndInquiriesSideBar
