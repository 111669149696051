import React from 'react'
import { Form, Button } from 'react-bootstrap'
import { CustomSelect, Textarea } from '../../components/formComponents'
import PrimaryTitle from '../../components/PrimaryTitle'
import ListSavedFields from '../../components/ListSavedFields'
import t from '../../service/translate.service'
import moment from 'moment'
import PropTypes from 'prop-types'

const ConsultationContent = props => {
  const {
    item,
    onSelect,
    onSubjectInput,
    handleSubmit,
    validators
  } = props

  const weekday = moment(item.date).format('dddd')
  const availableSlots = item.schedules.filter(slot => !slot.created_at).map(slot => {
    return {
      label: slot.startTime + ' - ' + slot.endTime,
      value: slot
    }
  })
  return (
    <div className='content'>
      {item.scheduled ? (
        <div className='scheduled'>
          <div className='header'>
            <PrimaryTitle title={t.SCHEDULED} />
          </div>
          <ListSavedFields
            fields={[
              {
                label: t.SCHEDULED_AT,
                value: item.scheduled.startTime + ' - ' + item.scheduled.endTime
              },
              {
                label: t.REASON,
                value: item.scheduled.subject
              },
              {
                label: t.PROPERTY_MANAGERS,
                value: item.employees.map((employee, index) => (<span key={index}>{employee.partner_name}{index !== item.employees.length - 1 && ', ' }</span>))
              }
            ]}
          />
        </div>
      ) : (
        <div>
          <div className='header'>
            {t.SLOT_SUBTITLE} {weekday}, {moment(item.date).format('DD.MM.YYYY')}
          </div>
          <Form onSubmit={e => handleSubmit(e, item)}>
            <CustomSelect
              value={item.timeslot || null}
              label={t.TIME}
              placeholder={t.TIME_PLACEHOLDER}
              options={availableSlots}
              validate={validators.timeslot}
              onChange={timeslot => onSelect(item.id, timeslot)}
            />

            <Textarea
              label={t.REASON}
              placeholder={t.REASON_PLACEHOLDER}
              value={item.subject}
              validate={validators.subject}
              onChange={e =>
                onSubjectInput(item.id, e.currentTarget.value)
              }
            />

            <Form.Group>
              <Form.Label>{t.PROPERTY_MANAGERS}</Form.Label>
              <div className='form-employees'>{item.employees.map((employee, index) => (<span key={index}>{employee.partner_name}{index !== item.employees.length - 1 && ', ' }</span>))}</div>
            </Form.Group>
            <div className='text-center'>
              <Button type='submit' className='button primary'>
                {t.SCHEDULE}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  )
}

ConsultationContent.propTypes = {
  item: PropTypes.object,
  onSelect: PropTypes.func,
  onSubjectInput: PropTypes.func,
  handleSubmit: PropTypes.func,
  validators: PropTypes.object
}

export default ConsultationContent
