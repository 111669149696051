import t from '../service/translate.service'
const DATA_KEY = 'deimos_contract_data' // localstorage key

const setActiveContract = ({ contractNumber }) => {
  const contractData = JSON.stringify({ contractNumber })
  localStorage.setItem(DATA_KEY, contractData)
}

const removeActiveContract = () => localStorage.removeItem(DATA_KEY)

const getActiveContract = () => {
  let stringifiedData = localStorage.getItem(DATA_KEY)

  try {
    let contractData = JSON.parse(stringifiedData)
    return contractData.contractNumber
  } catch (ex) {
    return null
  }
}

const checkIsContractCondominium = (contractNumber) => {
  return contractNumber?.toLocaleUpperCase().startsWith('W')
}

const getContractType = contractNumber => {
  const testForType = /A+\w+.([MPGKLETS]).\w+.\w+/
  const matches = testForType.exec(contractNumber)
  const typeLetter = matches && matches[1]
  const contractTypes = {
    M: t.CONTRACT_TYPE_APARTMENT,
    P: t.CONTRACT_TYPE_PARKING_SPOT,
    G: t.CONTRACT_TYPE_COMMERCIAL_USE,
    K: t.CONTRACT_TYPE_CELLAR,
    L: t.CONTRACT_TYPE_STORAGE,
    E: t.CONTRACT_TYPE_CONDOMINIUM,
    T: t.CONTRACT_TYPE_GARAGE_OR_PARKING_SPACE,
    S: t.CONTRACT_TYPE_SPECIAL_AREA
  }
  return contractTypes[typeLetter]
}

export default {
  setActiveContract,
  getActiveContract,
  removeActiveContract,
  getContractType,
  checkIsContractCondominium
}
