import { api } from '../../service/api.service'
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'

class SapInvalidCallsListStore {
  constructor () {
    extendObservable(this, {
      calls: [],
      error: null,
      page: 1,
      count: 0,
      query: '',
      loading: false,
      orderBy: 'created_at',
      order: 'DESC'
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  getAll () {
    this.loading = true
    api
      .get('/api/sap-invalid-calls', { params: { page: this.page, orderBy: this.orderBy, order: this.order, q: this.query } })
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.calls = rows
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  deleteCall (id) {
    api.delete('/api/sap-invalid-calls/' + id).then(() => {
      this.getAll()
    })
  }

  updateCall (data) {
    api.put('/api/sap-invalid-calls', data).then(() => {
      this.getAll()
    })
  }

  updateQueryOrdering (val) {
    console.log(val)
    this.orderBy = val
    this.order = this.order === 'DESC' ? 'ASC' : 'DESC'
    this.debouncedGetAll()
  }

  updateQuery (val) {
    this.query = val
    this.page = 1
    this.debouncedGetAll()
  }

  onPageChange (page) {
    this.page = page
    this.getAll()
  }
}
export default new SapInvalidCallsListStore()
