import React, {useState, useEffect} from 'react'
import { inject, observer } from 'mobx-react'
import t from '../../service/translate.service'
import './css/index.css'
import BellIcon from './assets/bellIcon.png'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { toJS } from 'mobx'
import HeatingAndEnergyStore from '../../store/heatingAndEnergy.store'

const EnergyConsumptionNotification = (props) => {
  const [visible, setVisible] = useState(false)
  const [loaded, setLoaded] = useState(false)
  
  const navigate = useNavigate()
  const isHomePage = useLocation().pathname === '/' ? true : false

  const remindMeLater = async () => {
    const viewedNumber = localStorage.getItem('viewedNotification')
    if (viewedNumber) {
      const newNumber = Number(viewedNumber) + 1
      localStorage.setItem('viewedNotification', newNumber.toString())
    } else {
      localStorage.setItem('viewedNotification', '1')
    }
    setVisible(false)
  }

  const learnMore = () => {
    localStorage.setItem('viewedNotification', '6')
    navigate('/heating-and-energy')
    setVisible(false)
  }

  useEffect(() => {
      let energyRegistered = false;
      (async () => {
        if (props.user && props.heatingAndEnergyVisibility) {
          const viewedNumber = localStorage.getItem('viewedNotification')
          const contracts = props.contractStore.contracts
          const profile = props.user
          const energyStore = new HeatingAndEnergyStore()
          for (let i = 0; i < contracts.length; i++) {
            const contract = contracts[i];
            const result = await energyStore.getEnergyRegistrationInfo(contract)
            if (result) {
              energyRegistered = true
              break
            }
          }
          const heatingAndEnergyVisibility = props.heatingAndEnergyVisibility        
          if (heatingAndEnergyVisibility && profile && !loaded && Number(viewedNumber) <= 5 && !energyRegistered && isHomePage) {
            setTimeout(() => {
              setVisible(true)
            }, 8000)
            setLoaded(true)
          } 
        }
      })()

  }, [props.user, props.heatingAndEnergyVisibility])

  return (
    <div className='energy-consumption-notification-holder' style={{display: visible ? 'block' : 'none'}}>
      <div className='notification-header'>
        <img src={BellIcon} />
        <h3>{t.HEATING_AND_ENERGY_NOTIFICATION}</h3>
      </div>
      <div>
        <p className='notification-text'>{t.HEATING_AND_ENERGY_NOTIFICATION_TEXT}</p>
      </div>
      <div className='notification-buttons-holder'>
        <button type='button' className='secondary-notification-button' onClick={remindMeLater}>
          {t.HEATING_AND_ENERGY_REMIND}
        </button>
        <button type='button' className='primary-notification-button' onClick={learnMore}>
          {t.HEATING_AND_ENERGY_LEARN_MORE}
        </button>
      </div>
    </div>
  )
}

export default inject('contractStore')(observer(EnergyConsumptionNotification))
