import { inject, observer } from 'mobx-react'
import withRouter from '../../../components/withRouter'
import t from '../../../service/translate.service'
import WeekSlotsPage from "./components/WeekSlotsPage";
import DaySlotsPage from './components/DaySlotsPage';
import './index.css'

const ConsultationSlotsPage = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  return (
    <div>
      {type ? (
        <DaySlotsPage consultationStore={props.consultationStore} />
      ) : (
        <WeekSlotsPage consultationStore={props.consultationStore} />
      )}
      <p className='disclaimer-text'>
        {t.CONSULTATIONS_PAGE_DISCLAIMER}
      </p>
    </div>
  );
};

export default inject('consultationStore')(withRouter(observer(ConsultationSlotsPage)))
