import React, { Component } from 'react'
import { extendObservable, computed } from 'mobx'
import { observer } from 'mobx-react'
import user from '../../store/user.store'
import t from '../../service/translate.service'
import { PropTypes } from 'prop-types'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import withRouter from '../../components/withRouter'

class EmailChanged extends Component {
  constructor(props) {
    super(props)
    extendObservable(this, {
      status: ''
    })
  }

  componentDidMount() {
    const hash = window.location.pathname.replace('/change-email/', '')
    user.changeEmail(hash).then(res => {
      if (res.data === 'Invalid hash') {
        this.status = 'Invalid hash...'
      } else {
        this.status = 'changed'
        user.logout()
        setTimeout(() => {
          this.props.navigate('/login')
        }, 5000)
      }
    })
      .catch(err => {
        this.status = 'Invalid hash...'
        throw new Error(err)
      })
  }

  render() {
    return (
      <div>
        {
          this.status.length > 0 && this.status === 'changed' && <ThankYouOverlay
            title={t.THANK_YOU}
            description={t.CHANGE_EMAIL_THANK_YOU_TEXT}
            buttonText={t.REGISTRATION_BACK_TO_LOGIN}
            buttonLink='/login'
          />
        }
        {
          this.status.length > 0 && this.status !== 'changed' && <ThankYouOverlay
            title={t.DAMAGE_REPORT_ON_ERROR}
            description={t.CHANGE_EMAIL_INVALID_HASH}
            buttonText={t.BACK_HOME}
            buttonLink='/'
          />
        }
      </div>
    )
  }
}

EmailChanged.propTypes = {
  router: PropTypes.object
}

export default withRouter(observer(EmailChanged))
