import React from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, Checkbox, DateInput, ImageUploader } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'
import resizer from 'react-image-file-resizer'
import { Alert } from 'antd'

const Fixtures = (props) => {
  const { model, setVal, tenantInstallationsValidators, tenantInstalationFiles, fileUploadError } = DocumentStore
  const { file } = props

  const resizeFile = (file) =>
    new Promise((resolve) => {
      resizer.imageFileResizer(file, 1600, 1600, 'jpeg', 90, 0,
        (uri) => {
          resolve(uri)
        },
        'file'
      )
    })

  const onImageDrop = async (files) => {
    const resizedImg = files.map(async (file) => {
      if (file.type === 'application/pdf') {
        return file
      }
      const image = await resizeFile(file)
      image.preview = file.preview
      return image
    })
    const resized = await Promise.all(resizedImg)
    DocumentStore.addFile(resized)
  }
  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: tenantInstallationsValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.FIXTURES} />
      <Form onSubmit={onSubmit}>
        <ContractSelector label padding page='documents' />
        <h3 className='inputs-text'>{t.FIXTURES_HEAD_ONE}</h3>
        <Input name='description' {...getAttrs('fixturesDesignation')} label={t.DIGITAL_CONTRACT_FORM_DESIGNATION + '*'} />
        <p className='inputs-text'>{t.DIGITAL_CONTRACT_DESCRIPTION_DESIGNATION}</p>
        <Input name='scope' {...getAttrs('fixturesScope')} label={t.DIGITAL_CONTRACT_FORM_SCOPE + '*'} />
        <p className='inputs-text'>{t.DIGITAL_CONTRACT_DESCRIPTION_SCOPE}</p>
        <Input name='placeOfModification' {...getAttrs('fixturesPlaceOfConversion')} label={t.DIGITAL_CONTRACT_FORM_PLACE_OF_CONVERSION + '*'} />
        <p className='inputs-text'>{t.DIGITAL_CONTRACT_DESCRIPTION_PLACE_OF_CONVERSION}</p>
        <Input name='specialistCompany' {...getAttrs('fixturesSpecialistCompany')} label={t.DIGITAL_CONTRACT_FORM_SPECIALIST_COMPANY} />
        <p className='inputs-text'>{t.DIGITAL_CONTRACT_DESCRIPTION_SPECIALIST_COMPANY}</p>
        <h3 style={{ marginBottom: '20px' }} className='inputs-text'>{t.FIXTURES_HEAD_TWO}</h3>
        {fileUploadError && <Alert message={fileUploadError} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <Form.Group>
          <ImageUploader label='File upload' files={tenantInstalationFiles} onImageDrop={onImageDrop} />
        </Form.Group>
        <Checkbox
          label={t['DIGITAL_CONTRACT_FORM_COST_ESTIMATE']}
          value={model.fixturesCostEstimate}
          onChange={e => setVal('fixturesCostEstimate', !model.fixturesCostEstimate)}
        />
        <Checkbox
          label={t['DIGITAL_CONTRACT_FORM_DETAILED_DRAWING']}
          value={model.fixturesDetailedDrawing}
          onChange={e => setVal('fixturesDetailedDrawing', !model.fixturesDetailedDrawing)}
        />
        <Checkbox
          label={t['DIGITAL_CONTRACT_FORM_DESCRIPTION_OF_THE_MEASURE']}
          value={model.fixturesDescriptionOfTheMeasure}
          onChange={e => setVal('fixturesDescriptionOfTheMeasure', !model.fixturesDescriptionOfTheMeasure)}
        />
        <p className='input-para'>{t.FIXTURES_PARA_ONE}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={tenantInstallationsValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button
              type='submit'
              className='button primary'
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </Button>
            <Button
              type='button'
              className='button secondary'
              onClick={() => {
                DocumentStore.onCloseForm()
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

Fixtures.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(Fixtures))
