import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import { Link } from 'react-router-dom'
import MobxReactForm from 'mobx-react-form'
import validator from 'validatorjs'
import fields from './feedbackFields'
import { Form, Row, Button } from 'react-bootstrap'
import { Alert, Rate } from 'antd'
import former from '../../components/former'
import pick from 'lodash/pick'
import t from '../../service/translate.service'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { PropTypes } from 'prop-types'
import withRouter from '../../components/withRouter'
const plugins = { dvr: validator }

class FeedbackForm extends Component {
  constructor(props) {
    super(props)
    this.form = new MobxReactForm({ fields }, { plugins })
    this.form.set({
      rating: Number(this.props.rating || 5)
    })

    extendObservable(this, {
      submitted: false,
      submitting: false,
      error: false,
      errorMessage: ''
    })
  }

  feedback = rating => {
    this.form.set({ rating })
  }

  submitData = form => {
    const { feedback } = this.props
    const feedbackData = form.values()

    return feedback
      .send(feedbackData)
      .then(() => {
        this.submitted = true
      })
      .catch(err => {
        this.submitting = false
        this.form.invalidate(err)
        this.error = true
        if(t[err]) {
          this.errorMessage = t[err]
        } else this.errorMessage = t.RESEND_VERIFICATION_EMAIL_ERROR
      })
  }

  onSubmit = e => {
    e.preventDefault()
    this.submitting = true

    return this.form.submit({
      onSuccess: this.submitData,
      onError: () => {
        this.submitting = false
        this.form.invalidate()
      }
    })
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.navigate(-2)
  }
  onChange = (rating) => {
    this.form.set({
      rating: Number(rating || 5)
    })
  }
  render() {
    const fForm = (
      <Form onSubmit={this.onSubmit}>
        <Form.Group className='feedback-form-btn text-center form-group'>
          <Rate value={this.form.$('rating').value} onChange={this.onChange} />
        </Form.Group>
        <Row>
          {former({
            form: this.form,
            fields: pick(fields, ['description', 'allowPublishing']),
            def: {
              labels: false
            }
          })}
        </Row>

        <Form.Group className='actions text-center'>
          <Link className='button secondary' onClick={() => this.props.navigate(-2)}>
            {t.FORM_BACK}
          </Link>
          <Button
            disabled={this.submitting}
            type='submit'
            className='button primary'
            onClick={this.onSubmit}>
            {t.FORM_SUBMIT}
          </Button>
        </Form.Group>
        {this.error && <Alert message={this.errorMessage} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      </Form>
    )

    return this.submitted ? <ThankYouOverlay
      title={t.THANK_YOU}
      description={t.FEEDBACK_THANK_YOU}
      buttonText={t.FORM_BACK}
      buttonLink='#'
      handleClick={this.goBack}
    /> : fForm
  }
}

FeedbackForm.propTypes = {
  feedback: PropTypes.object,
  rating: PropTypes.string
}

export default withRouter(observer(FeedbackForm))
