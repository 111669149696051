import React, { Component } from 'react'
import { observer } from 'mobx-react'
import FaqCategoryForm from './FaqCategoryForm'
import faqCategoryStore from '../../../store/admin/FaqCategory.store.js'
import PropTypes from 'prop-types'
import withRouter from '../../../components/withRouter'

class FaqCategoryEdit extends Component {
  static propTypes = {
    params: PropTypes.object
  }

  constructor(props) {
    super(props)
    faqCategoryStore[this.props.useParams.id === 'new' ? 'new' : 'load'](
      this.props.useParams.id
    )
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <FaqCategoryForm store={faqCategoryStore} id={this.props.useParams.id} />
        </div>
      </div>
    )
  }
}

export default withRouter(observer(FaqCategoryEdit))
