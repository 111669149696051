import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Pagination, Input, Button } from 'antd'
import t from '../../../service/translate.service'
import moment from 'moment'
import userListStore from '../../../store/admin/UserList.store'

const paginationContainerStyle = {
  textAlign: 'center',
  marginTop: 10
}

const queryInputStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  marginTop: 20,
  marginBottom: 10
}

class UserList extends Component {
  constructor (props) {
    super(props)
    userListStore.getAll()
  }

  contractStatus = (contract, index) => {
    if (contract.active && contract.valid) {
      return (
        <div key={index}>
          <div className='statusIcon active' />{' '}
          <span>{t.PROFILE_CONTRACTS_STATUS_ACTIVE}</span>
        </div>
      )
    } else if (contract.active && !contract.valid) {
      return (
        <div key={index}>
          <div className='statusIcon pending' />{' '}
          <span>{t.PROFILE_CONTRACTS_STATUS_PENDING_SHORT}</span>
        </div>
      )
    } else {
      return (
        <div key={index}>
          <div className='statusIcon inactive' />{' '}
          <span>{t.PROFILE_CONTRACTS_STATUS_INACTIVE}</span>
        </div>
      )
    }
  }
  render () {
    return (
      <div>
        <h1>Users</h1>
        <div style={queryInputStyle}>
          <div style={{ margin: 3, marginTop: '25px' }}>
            <Button
              onClick={() => userListStore.updateActiveFilter()}
              style={{height: 28, borderColor: '#d9d9d9' }}
              className='ant-btn ant-sm yellow-btn'
              size='small'
            >
              {userListStore.activeFilter === 0 && 'Showing all users'}
              {userListStore.activeFilter === 1 && 'Showing unactive users'}
              {userListStore.activeFilter === 2 && 'Showing active users'}
            </Button>
          </div>
          <div style={{ margin: 3, maxWidth: 200 }} className='table-inputs'>
            <label>Name/Email/Phone/Mobile</label>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={userListStore.query}
              onChange={e => userListStore.updateQuery(e.target.value)}
            />
          </div>
          <div style={{ margin: 3, maxWidth: 200 }} className='table-inputs'>
            <label>Rent Contract/Address</label>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={userListStore.contractQuery}
              onChange={e => userListStore.updateContractQuery(e.target.value)}
            />
          </div>
        </div>
        {(!userListStore.loading || userListStore.users.length) && (
          <div>
            <table className='table'>
              <thead>
                <tr>
                  <th className='order-results' onClick={e => userListStore.updateQueryOrdering('last_name')}>{t['ADMIN_FULL_NAME']}</th>
                  <th className='order-results' onClick={e => userListStore.updateQueryOrdering('email')}>{t['USER_EMAIL']}</th>
                  <th className='order-results' onClick={e => userListStore.updateQueryOrdering('created_at')}>{t['CREATED_AT']}</th>
                  <th>{t['ADMIN_CONTRACT_NUMBER']}</th>
                  <th>{t['USER_ADDRESS']}</th>
                  <th>Status</th>
                  <th></th>
                  <th />
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {userListStore.users.map((u, index) => (
                  <tr key={index}>
                    <td>
                      <Link to={`/admin/user/${u.id}`}>
                        {u.lastName}, {u.firstName}
                      </Link>
                    </td>
                    <td>{u.email}</td>
                    <td>{moment(u.created_at).format('DD-MM-YYYY')}</td>
                    <td>
                      {u.contracts.map((c, index) => <div key={index}>{c.contractNumber}</div>)}
                    </td>
                    <td>{u.contracts.map((c, index) => <div key={index}>{c.address}</div>)}</td>
                    <td>{u.contracts.map((c, index) => this.contractStatus(c, index))}</td>
                    <td><Link to={`/admin/user/${u.id}`}>
                        View
                      </Link></td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={paginationContainerStyle}>
              <Pagination
                current={userListStore.page}
                total={userListStore.count}
                pageSize={10}
                onChange={p => userListStore.onPageChange(p)}
                showSizeChanger={false}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default observer(UserList)
