import React, { Component } from 'react'
import t from '../service/translate.service'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import ThankYouOverlay from './ThankYouOverlay'

class RegistrationThankYou extends Component {
  render () {
    return <ThankYouOverlay
      title={t.THANK_YOU}
      description={this.props.userStore.mailError ? t[this.props.userStore.mailError] : t.REGISTRATION_THANK_YOU_TEXT}
      buttonText={t.REGISTRATION_BACK_TO_LOGIN}
      buttonLink='/login'
      cancelButtonText={t.RESEND_VERIFICATION_EMAIL}
      handleCancel={() => this.props.userStore.resendVerificationEmail(true)}
    />
  }
}
RegistrationThankYou.propTypes = {
  userStore: PropTypes.object.isRequired
}
export default inject('userStore')(observer(RegistrationThankYou))
