import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import FaqQuestionForm from './FaqQuestionForm'
import faqQuestionListStore from '../../../store/admin/FaqQuestionList.store.js'
import withRouter from '../../../components/withRouter'

class FaqQuestionEdit extends Component {
  static propTypes = {
    params: PropTypes.object
  }

  constructor(props) {
    super(props)
    const id = props.useParams.id
    if (id === 'new') {
      faqQuestionListStore.new(id)
    } else {
      faqQuestionListStore.load(id)
    }
  }

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <FaqQuestionForm store={faqQuestionListStore} id={this.props.useParams.id} />
        </div>
      </div>
    )
  }
}

export default withRouter(observer(FaqQuestionEdit))
