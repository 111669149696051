import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import RepairsAndInquiriesForm from './RepairsAndInquiriesForm'
import RepairsAndInquiriesFormStore from '../../store/repairsAndInquiriesForm.store'
import PropTypes from 'prop-types'

const RepairsAndInquiriesNewRequest = observer(class RepairsAndInquiriesNewRequestClass extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  };

  constructor (props) {
    super(props)

    this.repairsAndInquiriesFormStore = new RepairsAndInquiriesFormStore()

    extendObservable(this, {
      getProfile: undefined
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  render () {
    const { user } = this.context

    return (
      <div className='container white report'>
        <RepairsAndInquiriesForm user={user} store={this.repairsAndInquiriesFormStore} />
      </div>
    )
  }
})

export default RepairsAndInquiriesNewRequest
