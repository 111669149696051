
import t from "../../../../service/translate.service";
import { Modal } from "antd";
import { CustomSelect } from '../../../../components/formComponents'
import { topics } from "../const/topics";


const TopicModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => {
        props.closeAppointment('topic')
      }}
      footer={[
        <button
          style={{
            marginRight: '10px'
          }}
          className="button secondary small"
          key="cancel"
          onClick={() => {
            props.closeAppointment('topic')
          }}
        >
          {t.CONSULTATIONS_CANCEL}
        </button>,
        <button
          className="button primary small"
          key="ok"
          onClick={() => {
            props.setShowDescriptionModal(true)
            if (props.selectedTopic) {
              props.setShowTopicModal(false)
            }
          }}
        >
          {t.CONSULTATIONS_OK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className={`${props.count === 0 ? 'error-message' : ''}`}>
          {props.bookingTimeLeftMessage}
        </p>
        <CustomSelect
          value={props.selectedTopic || null}
          options={topics}
          label={t.CONSULTATIONS_SELECT_TOPIC}
          placeholder={t.CONSULTATIONS_SELECT_TOPIC_PLACEHOLDER}
          defaultValue={topics[0]}
          onChange={selectedTopic => props.setSelectedTopic(selectedTopic)}
        />
        {!props.selectedTopic && props.showDescriptionModal && (
          <div className='error-message'>
            {t.CONSULTATIONS_SELECT_TOPIC}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default TopicModal;
