import React from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'

const LoyaltyPaymentDeposit = observer(({ store, onCancel }) => {
  // const { setCode, code } = store

  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.LOYALTY_PAYMENT_DEPOSIT_TITLE} />
        <p>{t.LOYALTY_PAYMENT_DEPOSIT_TEXT1}</p>
      </div>
      <Form>
        <Form.Group className='actions text-center'>
          <div className='group'>
            <p style={{ marginBottom: '10px' }}>{t.LOYALTY_PAYMENT_DEPOSIT_TEXT2}</p>
            <Button type='submit' className='button primary'>{t.LOYALTY_DEPOSIT_BTN}</Button>
          </div>
        </Form.Group>
      </Form>
      <Button className='button tertiary' onClick={(e) => onCancel(e)}>{t.FORM_CANCEL}</Button>
    </div>
  )
})

export default LoyaltyPaymentDeposit
