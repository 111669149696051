import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Select, Input, Upload, message, DatePicker } from 'antd'
import TokenService from '../../../service/token.service'
import ps from '../../../store/admin/PartnerList.store.js'
import moment from 'moment'
import { Navigate } from 'react-router-dom'
//import 'antd/dist/antd.min.css'

class PartnerForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    ps.save()
  }

  onClone (e) {
    e.preventDefault()
    ps.clone()
  }

  getBase64 (img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  beforeUpload (file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  handleImageChange = info => {
    if (info.file.status === 'uploading') {
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => {
        ps.setVal('image', info.file.response.url)
      })
    }
  };

  handleFormatChange = format => {
    ps.setVal('format', format)
  }

  handleCategoryChange = categories => {
    ps.setVal('categories', categories)
  }

  renderImageTooltip = (format) => {
    switch (format) {
      case 'imageWithTitle':
        return <p>Required image resolution: <b>975x600</b></p>
      case 'thumbWithTitle':
        return <p>Required image resolution: <b>276x276</b></p>
      default:
        return ''
    }
  }
  disabledDate = (current) => {
    // Can not select days before today
    return current < moment().subtract(1, 'd')
  }

  render () {
    const { model, availableCategories } = ps

    if(ps.formSubmittedSuccessfully) {
      ps.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/partners/' }} />
    }

    const token = TokenService.get()
    const uploadButton = (
      <div>
        <div className='ant-upload-text'>Upload</div>
      </div>
    )

    return (
      !ps.loading && (
        <div>
          <h1>Partner</h1>
          <form onSubmit={this.onSubmit}>
            <div className='input-div'>
              <label>Name *</label>
              <Input
                value={model.name}
                type='input'
                required
                onChange={e => ps.setVal('name', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Categories *</label>
              <Select
                mode='multiple'
                defaultValue={model.categories}
                onChange={this.handleCategoryChange}
              >
                {availableCategories.map(cat => (
                  <Select.Option key={'cat' + cat.id} value={cat.id}>{cat.name}</Select.Option>
                ))}
              </Select>

            </div>
            <div className='input-div'>
              <label>Format *</label>
              <Select
                defaultValue={model.format}
                onChange={this.handleFormatChange}
              >
                <Select.Option value='imageWithTitle' >Large image with Title</Select.Option>
                <Select.Option value='thumbWithTitle' >Thumb image with Title</Select.Option>
              </Select>

            </div>
            <div className='input-div'>
              <label>Title *</label>
              <Input
                value={model.title}
                type='input'
                required
                onChange={e => ps.setVal('title', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Title German *</label>
              <Input
                value={model.titleDe}
                type='input'
                required
                onChange={e => ps.setVal('titleDe', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>URL *</label>
              <Input
                value={model.url}
                type='input'
                onChange={e => ps.setVal('url', e.target.value)}
                required
              />
            </div>
            <div className='input-div'>
              <label>Image *</label>
              <div>
                <Upload
                  name='partner_image'
                  listType='picture-card'
                  className='avatar-uploader'
                  showUploadList={false}
                  action='/api/partner/image/upload'
                  beforeUpload={this.beforeUpload}
                  value={model.image ? model.image : 'test'}
                  data={(file) => { return { 'filename': file.name } }}
                  onChange={this.handleImageChange}
                  headers={{ 'Authorization': token }}
                > 
                  {model.image ? <img src={model.image} alt='image' style={{ width: '100%' }} /> : uploadButton}
                </Upload>
              </div>
              {this.renderImageTooltip(model.format)}
            </div>
            <div className='input-div'>
              <label>Featured</label>
              <Select
                defaultValue={model.featured ? 'yes' : 'no'}
                onChange={e => ps.setVal('featured', e === 'yes')}
              >
                <Select.Option value='no' >No</Select.Option>
                <Select.Option value='yes' >Yes</Select.Option>
              </Select>
            </div>
            <div className='input-div'>
              <label>Expiry date</label>
              <DatePicker
                defaultValue={model.expiryDate ? moment(model.expiryDate) : null}
                onChange={(date, dateString) => ps.setVal('expiryDate', dateString === '' ? null : dateString)}
                disabledDate={this.disabledDate}
                style={{ width: 120 }}
              />
            </div>

            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/partners'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(PartnerForm)
