import t from '../../service/translate.service'

export default {
  get terms () {
    return {
      label: t.TERMS_AND_CONDITIONS_REQUIRED,
      rules: 'required',
      value: false
    }
  },
  get acceptanace () {
    return {
      label: t.PARTICIPATE,
      rules: 'required',
      value: true
    }
  }
}
