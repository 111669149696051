import React, { Component } from 'react'
import { observer } from 'mobx-react'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import BasicSlider from '../../components/BasicSlider'
import { MenuArrowIcon } from '../../components/icons'
import PrimaryTitle from '../../components/PrimaryTitle'
import Categories from './Categories'
import BenefitList from './BenefitList'
import { extendObservable } from 'mobx'
import { getAll } from '../../store/offer.store'
import { api, API_BASE } from '../../service/api.service'
import { Link } from 'react-router-dom'
import withRouter from '../../components/withRouter'
import historyService from '../../service/history.service'
import DOMPurify from 'dompurify'

class Benefits extends Component {
  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  constructor (props, context) {
    super(props)
    extendObservable(this, {
      offers: [],
      categories: [],
      partners: [],
      isLoading: true,
      total: 0,
      lastFetchSkip: 0,
      selectedCategory: { id: 0, name: 'All', nameDe: 'Alle' },
      toggleHowItWorks: false
    })

    this.getAllOffers()
    this.getCategories()
    this.getPartners()
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  getAllOffers = (skip = 0, limit = 10) => {
    getAll(skip, limit)
      .then(({ offers, total }) => {
        const remapedOffers = offers.map(o => {
          var offer = {}
          offer.id = o.id
          offer.image = 'https://www.grandcityproperty.de' + o.image.thumbnails['news-overview-image']
          offer.title = o.headline
          offer.description = o.text
          return offer
        })
        const updatedOffers = this.offers.concat(remapedOffers)
        this.offers = updatedOffers
        this.total = total

        // USE THIS IF ALL OFFERS NEED TO BE FETCHED
        /*
        if (this.total > this.offers.length) {
          this.lastFetchSkip = this.lastFetchSkip + remapedOffers.length
          this.getAllOffers(this.lastFetchSkip, 10)
        }
        */
        this.isLoading = false
      })
      .catch(() => {
        this.isLoading = false
      })
  }

  getCategories = () => {
    api.get(`${API_BASE}/api/partner-categories?includePartners=true`)
      .then(res => {
        const filteredCategories = res.data.rows.filter(
          category => category.partners.length > 0
        )
        this.categories = [this.selectedCategory, ...filteredCategories]
        this.selectedCategory = this.categories[0]
      })
  }

  getPartners = () => {
    api.get(`${API_BASE}/api/partner`).then(res => {
      this.partners = res.data.rows
    })
  }

  handleClick = (id) => {
    this.props.navigate('/offer/' + id)
  }

  handleCategoryClick = (category) => {
    this.selectedCategory = category
  }

  handleToggleHowItWorks = (e) => {
    this.toggleHowItWorks = !this.toggleHowItWorks
  }
  async openLink (e, link) {
    e.preventDefault()
    if (link === '/links/mivo') {
      const res = await api.get(`${API_BASE}/api/links/mivo`)
      link = DOMPurify(res.data.url)
    }
    window.open(link, '_blank')
  }
  render () {
    const expandedClass = this.toggleHowItWorks ? '' : 'expanded'
    return (
      <div>
        <PrimaryTitle title={t.BENEFITS} />
        <div className={`how-it-works ${expandedClass}`}>
          <div className='header' onClick={this.handleToggleHowItWorks}>
            <div className='title secondary'>{t.BENEFITS_SUBTITLE}</div>
            {MenuArrowIcon}
          </div>
          <div className='description'>{t.BENEFITS_TEXT}</div>
        </div>
        <Categories
          categories={this.categories}
          selectedCategoryId={this.selectedCategory.id}
          handleClick={this.handleCategoryClick}
        />
        {this.selectedCategory.id === 0 ? (
          <div>
            <BenefitList items={this.partners.slice(0, 3)} openLink={this.openLink} />
            <div className='text-center'>
              <div className='title secondary max'>{t.BENEFITS_OFFERS_TITLE}</div>
              <BasicSlider items={this.offers} handleClick={this.handleClick} settings={{
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                className: 'slider variable-width',
                centerMode: true,
                centerPadding: '0px',
                variableWidth: true }} />
            </div>
            <div className='text-center' style={{ marginTop: '100px' }}>
              <Link className='button tertiary' to='/offers' onClick={() => { historyService.push('/offers')}}>{t.BENEFITS_VOUCHERS_LINK}</Link>
            </div>
            <div className='other-benefits'>
              <div className='title secondary max'>{t.BENEFITS_OTHER_TITLE}</div>
              <BenefitList items={this.partners.slice(3)} openLink={this.openLink} />
            </div>
          </div>
        ) : (<BenefitList items={this.selectedCategory.partners} openLink={this.openLink} />)
        }
      </div>
    )
  }
}

export default withRouter(observer(Benefits))
