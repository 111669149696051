import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from '../../service/translate.service'
import withRouter from '../../components/withRouter'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import moment from 'moment'
import { Alert } from 'antd'
import ContractSelector from '../../components/contractSelector'
import { Form, Button } from 'react-bootstrap'
import { Checkbox, Input } from '../../components/formComponents'
import PrepaymentsFormStore from '../../store/prepaymentsForm.store'
import { Modal } from 'antd'
import { Link } from 'react-router-dom'
const { Table } = require("react-bootstrap")

const dateFormat = 'DD.MM.YYYY'
class Prepayments extends Component {
  constructor(props) {
    super(props)
    this.prepaymentsFormStore =  new PrepaymentsFormStore(this.props.useParams.contractNumber)
  }

  handleBack = () => {
    this.props.menuStore.setCurrentPage('/')
    this.props.navigate('/')
  }

  onSubmit = e => {
    e.preventDefault()
    this.prepaymentsFormStore.submit()
  }

  render() {
    const { contractNumber } = this.props.useParams;

    if (this.props.contractStore.loading || this.prepaymentsFormStore.loading || this.prepaymentsFormStore.submitting) {
      return <LoadingPageOverlay />
    }

    const columns = [
      {
        key: 'condtype',
        label: t.PREPAYMENT_COST_TYPE
      },
      {
        key: 'unitprice',
        label: t.PREPAYMENT_COST_AMOUNT
      },
      {
        key: 'condvalidfrom',
        label: t.PREPAYMENT_VALID_FROM
      },
      {
        key: 'condvalidto',
        label: t.PREPAYMENT_VALID_TO
      }
    ]

    return (
      <Form>
        {
          this.prepaymentsFormStore.error
            ? <Alert message={this.prepaymentsFormStore.error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />
            : this.prepaymentsFormStore.responses.map(response => {
              const message = response.success
                ? t.PREPAYMENT_SUBMIT_SUCCESS
                : response.errorCode.includes('ZCL_BEST')
                  ? t[response.errorCode]
                  : t.PREPAYMENT_UNEXPECTED_ERROR
              const type = response.success ? 'success' : 'error'
              return <Alert message={message} type={type} style={{marginTop: '12px', marginBottom: '12px'}} />
            })
        }
        <ConfirmDialogue
          store={this.prepaymentsFormStore}
          onSubmit={this.onSubmit}
        />
        <ContractSelector label padding isDisabled={true} contractNumber={this.props.useParams.contractNumber} />
        <div className='form-group' style={{fontSize: '12px'}}>
          {t.PREPAYMENT_NOTE}
          <Link
            style={{
              color: 'var(--brand-dark-grey)',
              borderBottom: '1px solid var(--brand-green)',
              textDecoration: 'none'
            }}
            to={`/repairs-and-inquiries/new-request?contractNumber=${contractNumber}`}
          >
            {t.PREPAYMENT_NOTE_LINK}.
          </Link>
        </div>
        <EditablePrepayments
          editablePrepayments={this.prepaymentsFormStore.model.editablePrepayments}
          currentPrepayments={this.prepaymentsFormStore.currentPrepayments}
          togglePrepayments={this.prepaymentsFormStore.togglePrepayments}
          changePrepayments={this.prepaymentsFormStore.changePrepayments}
        />
        <div className='form-group' style={{fontSize: '12px'}}>{`${t.PREPAYMENT_REQUEST_MADE_AT_1} ${moment().format(dateFormat)}, ${t.PREPAYMENT_REQUEST_MADE_AT_2} ${this.prepaymentsFormStore.contract.address}`}</div>
        {this.prepaymentsFormStore.futurePrepayments.length > 0 && (
          <div className='form-group'>
            <div style={{fontSize: '12px'}}>
              {t.PREPAYMENT_FUTURE_COSTS}
              <Link
                style={{
                  color: 'var(--brand-dark-grey)',
                  borderBottom: '1px solid var(--brand-green)',
                  textDecoration: 'none'
                }}
                to={`/repairs-and-inquiries/new-request?contractNumber=${contractNumber}`}
              >
                {t.PREPAYMENT_NOTE_LINK}.
              </Link>
            </div>
            <Table>
                <thead>
                    <tr>
                      {columns.map(column => <th>{column.label}</th>)}
                    </tr>
                </thead>
                <tbody>
                  {this.prepaymentsFormStore.futurePrepayments.map(prepayment => (
                    <tr>{columns.map(column => (
                      <td>{['condvalidfrom', 'condvalidto'].includes(column.key)
                        ? prepayment[column.key].match('9999')
                          ? ''
                          : moment(prepayment[column.key]).format(dateFormat)
                        : column.key === 'condtype'
                          ? prepayment.label
                          : column.key === 'unitprice'
                            ? `${prepayment.formattedUnitprice} €`
                            : prepayment[column.key]}</td>
                    ))}</tr>
                  ))}
                </tbody>
            </Table>
          </div>
        )}
        <div className='text-center'>
          <Button
            type='button'
            className='button primary'
            disabled={!this.prepaymentsFormStore.model.editablePrepayments.find(prepayment => prepayment.checked)}
            onClick={this.prepaymentsFormStore.onSubmitClick}
          >
            {t.SAVE}
          </Button>
        </div>
      </Form>
    )
  }
}

const ConfirmDialogue = observer(({store, onSubmit}) => {
  const message = store.model.editablePrepayments.filter(prepayment => prepayment.checked).map(prepayment => {
    const currentPrepayment = store.currentPrepayments.find(p => p.condtype === prepayment.condtype)
    
    const sentence = [
      t.PREPAYMENT_CONFIRM_DIALOGUE_1,
      t.locale === 'en' ? prepayment.label.toLowerCase() : prepayment.label,
      t.PREPAYMENT_CONFIRM_DIALOGUE_2,
      store.displayCurrency(prepayment.unitprice - Number(currentPrepayment.originalUnitprice)),
      t.PREPAYMENT_CONFIRM_DIALOGUE_3
    ]
    return <p>{sentence.join(' ')}</p>
  })
  return <Modal onCancel={() => store.onSubmitClick()} onOk={onSubmit} open={store.confirmSubmit}>
    <label className="editContractLabelContract">{message}</label>
  </Modal>
})

const EditablePrepayments = observer(({editablePrepayments, currentPrepayments, togglePrepayments, changePrepayments}) => (
  editablePrepayments.map((prepayment, index) => {
    const unchangedPrepayment = currentPrepayments.find(c => c.condtype === prepayment.condtype)
    return (
      <div key={prepayment.condtype} className='form-group' style={{display: "flex"}}>
        <div className='checkbox-holder' style={{flex: 1}}>
          <Checkbox
            label={<span>{`${unchangedPrepayment.label} (${t.CURRENTLY} `}
              <span className='bold'>{`${unchangedPrepayment.formattedUnitprice} €`}</span>
              {moment(unchangedPrepayment.condvalidto).format(dateFormat).includes("9999")
                ? null
                : (
                  <>
                    {`, ${t.PREPAYMENT_VALID_TO.toLowerCase()} `}
                    <span className='bold'>{moment(unchangedPrepayment.condvalidto).format(dateFormat)}</span>
                  </>
                )}
              )</span>}
            value={prepayment.checked}
            onChange={() => togglePrepayments(prepayment)}
          />
        </div>
        <div className='form-group' style={{flex: 1}}>
          <div style={{display: 'flex', alignItems: 'center', position: 'relative', justifyContent: 'right'}}>
            <Input
              styles={{flex: 1}}
              key={index}
              name={prepayment.condtype}
              value={prepayment.unitprice}
              label={`Adjust ${prepayment.condtype} Costs`}
              isLabel={false}
              onChange={e => changePrepayments(e.target.value, prepayment)}
              autoComplete="off"
              disabled={!prepayment.checked}
            />
            <span style={{position: 'absolute', marginRight: '20px'}}>€</span>
          </div>
        </div>
      </div>
    )
  })
))

export default inject('contractStore')(withRouter(observer(Prepayments)))