import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import ContractSelector from '../../components/contractSelector'
import { Link } from 'react-router-dom'
import MobxReactForm from 'mobx-react-form'
import validator from 'validatorjs'
import fields from './repairsAndInquiriesFields'
import { Input, CustomSelect, Textarea, Checkbox, ImageUploader } from '../../components/formComponents'
import t from '../../service/translate.service'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { Form, Button } from 'react-bootstrap'
import { PropTypes } from 'prop-types'
import resizer from 'react-image-file-resizer'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

const plugins = { dvr: validator }

class RepairsAndInquiriesForm extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      store: this.props.store,
      files: [],
      urgent: false
    })

    this.form = new MobxReactForm({ fields }, { plugins })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
  }

  onSubmit = e => {
    e.preventDefault()
    this.store.submit(this.props.contractStore.selectedContract.contractNumber)
  }

  removeImg = (e, img) => {
    e.preventDefault()
  }

  resizeFile = (file) =>
    new Promise((resolve) => {
      resizer.imageFileResizer(file, 1600, 1600, 'jpeg', 90, 0,
        (uri) => {
          resolve(uri)
        },
        'file'
      )
    })

  onImageDrop = async (files) => {
    const resizedImg = files.map(async (file) => {

      if(file.type.includes('image/')) {
        const image = await this.resizeFile(file)
        image.preview = file.preview
        return image        
      }

      return file
    })
    const resized = await Promise.all(resizedImg)
    this.store.addFile(resized)
  }

  onError() {
    return true
  }

  render() {
    if (this.store.submitting) {
      return <LoadingPageOverlay />
    }
    if (this.store.submitSuccess) {
      return <ThankYouOverlay
        title={t.THANK_YOU}
        description={t.DAMAGE_REPORT_SUCCESS}
        buttonText={t.BACK_TO_REPAIRS_AND_INQUIRES}
        buttonLink='/repairs-and-inquiries'
      />
    }
    return (
      <RenderForm
        store={this.store}
        onSubmit={this.onSubmit}
        onImageDrop={this.onImageDrop}
      />
    )
  }
}

const RenderForm = observer(({ onSubmit, onImageDrop, store }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryParamValue = urlParams.get('contractNumber');
  const { model, setVal, files, validators, categories, error } = store

  return (
    <Form onSubmit={onSubmit}>
      {error && <Alert message={error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      <ContractSelector contractNumber={queryParamValue || undefined} label padding />
      <Input
        name='subject'
        value={model.subject}
        validate={validators['subject']}
        label={t['DAMAGE_REPORT_SUBJECT']}
        onChange={e => setVal('subject', e.target.value)}
      />

      <CustomSelect
        value={model.repairAndInquiryCategoryId}
        label={t['DAMAGE_REPORT_CATEGORY']}
        options={categories}
        onChange={val => {
          setVal('repairAndInquiryCategoryId', val)
        }}
      />
      <Textarea
        name='description'
        value={model.description}
        validate={validators['description']}
        label={t['DAMAGE_REPORT_DESCRIPTION']}
        placeholder={t['DAMAGE_REPORT_DESCRIPTION_PLACEHOLDER']}
        onChange={e => setVal('description', e.target.value)}
      />

      <ImageUploader label={t['DAMAGE_REPORT_UPLOAD_IMAGE']} files={files} onImageDrop={onImageDrop} />

      <Checkbox
        label={t['DAMAGE_REPORT_URGENT']}
        value={model.urgent}
        onChange={e => setVal('urgent', !model.urgent)}
        validate={validators['urgent']}
      />

      <Form.Group className='actions text-center'>
        <div className='group'>
          <Button type='submit' className='button primary'>{t.FORM_SUBMIT}</Button>
          <Link to='/repairs-and-inquiries' className='button secondary' onClick={() => historyService.push('/repairs-and-inquiries')}>{t.FORM_CANCEL}</Link>
        </div>
      </Form.Group>
    </Form>
  )
})

RepairsAndInquiriesForm.propTypes = {
  contractStore: PropTypes.object,
  repairsAndInquiriesFormStore: PropTypes.object
}

export default inject('contractStore')(observer(RepairsAndInquiriesForm))
