import React from 'react'

const BenefitsIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='20pt'
    height='19pt'
    viewBox='0 0 20 19'
  >
    <g enableBackground='new'>
      <clipPath id='svg-benefits'>
        <path
          transform='matrix(1,0,0,-1,0,19)'
          d='M 10.1297 18.33333 L 7.205298 12.40817 L .6666667 11.45795 L 5.398837 6.846513 L 4.281546 .3333333 L 10.1297 3.408494 L 15.97851 .3333333 L 14.86122 6.846513 L 19.59274 11.45795 L 13.05345 12.40817 L 10.1297 18.33333 Z M 6.685158 6.625273 L 6.800672 7.302044 L 6.309247 7.781069 L 3.471641 10.54689 L 7.393254 11.11728 L 8.071982 11.21517 L 8.376104 11.8306 L 10.1297 15.38413 L 11.8833 11.8306 L 12.18742 11.21517 L 12.86615 11.11728 L 16.78776 10.54689 L 13.95016 7.781069 L 13.45808 7.302044 L 13.5749 6.625273 L 14.24449 2.719324 L 10.73729 4.562984 L 10.1297 4.88277 L 9.522111 4.562984 L 6.014914 2.719324 L 6.685158 6.625273 Z '
          fillRule='evenodd'
        />
      </clipPath>
      <g clipPath='url(#svg-benefits)'>
        <path
          transform='matrix(1,0,0,-1,0,19)'
          d='M -4.333333 23.33333 L 24.59274 23.33333 L 24.59274 -4.666667 L -4.333333 -4.666667 Z '
          fill='white'
        />
      </g>
    </g>
  </svg>
)

export default BenefitsIcon
