import React, { Component } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import AdventForm from './AdventForm'
import adventStore from '../../../store/admin/Advent.store'
import withRouter from '../../../components/withRouter'

class AdventEdit extends Component {
  static propTypes = {
    params: PropTypes.object
  }
  constructor(props) {
    super(props)
    const id = props.useParams.id
    if (id === 'new') {
      adventStore.new(id)
    } else {
      adventStore.load(id)
    }
  }


  render() {
    return (

      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <AdventForm store={adventStore} id={this.props.useParams.id} />
        </div>
      </div>
    )
  }
}

export default withRouter(observer(AdventEdit))
