import React from 'react'
import { Link } from 'react-router-dom'
import PrimaryTitle from '../../components/PrimaryTitle'
import t from '../../service/translate.service'
import PropTypes from 'prop-types'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import { Alert } from 'antd'

export const HeatingAndEnergyUnregister = ({
  handleCancel,
  handleClick,
  loading,
  error
}) => {
  return (
      <div className='wrapper heating-energy-modal'>
        {loading && (
          <LoadingPageOverlay />
        )}
        <PrimaryTitle title={t.HEATING_AND_ENERGY_TITLE} />
        <div className='description'>{t.HEATING_AND_ENERGY_UNREGISTER_TEXT_ONE}</div>
        <div className='description'>{t.HEATING_AND_ENERGY_UNREGISTER_TEXT_TWO}</div>
        {error && (
          <Alert message={error} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
        )}
        <div className='btns-holder'>
          <Link to='' onClick={handleClick} className='button primary'>{t.HEATING_AND_ENERGY_CONFIRM}</Link>
          <Link className='button tertiary' to='' onClick={handleCancel}>{t.HEATING_AND_ENERGY_CANCEL}</Link>
        </div>
      </div>
  )
}
HeatingAndEnergyUnregister.propTypes = {
  handleCancel: PropTypes.func,
  handleClick: PropTypes.func,
  loading: PropTypes.bool
}