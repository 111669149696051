/**
 * Page contains the whole login form, it's small enough not to be separated.
 */
import React, { Component } from 'react'
import history from '../service/history.service'
import { Link } from 'react-router-dom'

import { extendObservable } from 'mobx'
import { observer, inject } from 'mobx-react'
import { Input } from '../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import MobxReactForm from 'mobx-react-form'
// import dvr from 'mobx-react-form/lib/validators/DVR'
import validator from 'validatorjs'
import t from '../service/translate.service'
import userFields from '../components/userFields'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import PrimaryTitle from '../components/PrimaryTitle'
import LoginRegisterSwitcher from '../components/LoginRegisterSwitcher'
import { extractError } from '../service/response.service'
import AlertCard from '../components/AlertCard'
import openedEye from '../assets/eye-regular.png'
import closedEye from '../assets/eye-slash-solid.png'
import {
  buildValidators,
  email,
  minLength,
  maxLength,
  emptyPass,
  passwordValidation,
} from '../service/validate'
import find from 'lodash/find'

const fields = pick(userFields, ['loginEmail', 'loginPassword'])
const plugins = { dvr: validator }

class LoginPage extends Component {
  constructor (props) {
    super(props) // always call super before using 'this'

    this.state = {
      showPassword: false,
      submitted: false,
      validators: this.validators(false),
    }
    this.toggleVisibility = this.toggleVisibility.bind(this)

    extendObservable(this, {
      loginInProgress: false
    })

    this.form = new MobxReactForm({ fields }, { plugins })
  }

  validators(submitted) {
    return buildValidators(
      {
        loginEmail: [email],
        loginPassword: [
          emptyPass,
          minLength(8),
          maxLength(50),
        ],
      },
      submitted
    )
  }

  toggleVisibility () {
    this.setState({ showPassword: !this.state.showPassword })
  }

  static contextTypes = {
    user: PropTypes.object.isRequired
  }

  submitForm (event) {
    event.preventDefault()
    const { user } = this.context
    const formData = this.form.values()

    const login = formData.loginEmail
    const loginPassword = formData.loginPassword
    user.loginError = null

    const submitted = true
    const validators = this.validators(submitted)
    const isValid = !find(validators, (validate, field) => validate(formData[field]))
    this.setState({submitted})

    if (!isValid) return

    this.loginInProgress = true
      this.props.configStore.getConfig()
      user
        .login(login, loginPassword)
        .then(data => {
          this.loginInProgress = false
          if (user.contracts.length) {
            this.props.menuStore.setCurrentPage('/')
            this.props.navigate('/') // go to home page
          } else {
            try {
              const wasShown = window.localStorage.getItem('welcomeWizardShown')
              if (wasShown === null) {
                window.localStorage.setItem('welcomeWizardShown', true)
                this.props.navigate('/onboarding')
              } else {
                this.props.menuStore.setCurrentPage('/')
                this.props.navigate('/')
              }
            } catch (err) {
              this.error = extractError(err)
              this.loading = false
            }
          }
        })
        .catch((err) => {
          console.log(err)
          console.log(user.loginError)
          this.loginInProgress = false
        })
  }

  render () {
    const { user } = this.context

    // check if there's an error message and display it
    const validationMessage = user.loginError ? user.loginError === 'EMAIL_NOT_CONFIRMED' ? (<AlertCard message={<div>{t.EMAIL_NOT_CONFIRMED_1}<Link onClick={() => user.resendVerificationEmail(false)}>{t.EMAIL_NOT_CONFIRMED_2}</Link>{t.EMAIL_NOT_CONFIRMED_3}</div>} />) : <AlertCard message={t[user.loginError] || user.loginError} /> : null

    return (
      <div>
        <PrimaryTitle title={t.WELCOME_TITLE} />

        <LoginRegisterSwitcher />

        <Form
          onSubmit={e => this.submitForm(e)}
          ref='loginForm'
          formNoValidate
        >
          {validationMessage}
          <Input
            label={this.form.$('loginEmail').label + '*'}
            type='text'
            name={this.form.$('loginEmail').name}
            value={this.form.$('loginEmail').value}
            onChange={this.form.$('loginEmail').sync}
            validate={this.validators(this.state.submitted)['loginEmail']}
          />

          <div className='login-password-group form-group'>
            <Input
              label={this.form.$('loginPassword').label + '*'}
              type={this.state.showPassword ? 'text' : 'password'}
              name={this.form.$('loginPassword').name}
              value={this.form.$('loginPassword').value}
              onChange={this.form.$('loginPassword').sync}
              validate={this.validators(this.state.submitted)['loginPassword']}
            />
            <img className='loginShowPassword' src={this.state.showPassword ? openedEye : closedEye} onClick={this.toggleVisibility} alt='' />
            <Link to={'/forgot-password'}>
              {t.LOGIN_PAGE_FORGOT_PASSWORD}
            </Link>
          </div>

          <Form.Group className='text-center'>
            <Button type='submit' className='button primary'>
              {t.LOGIN_PAGE_REGISTRATION}
            </Button>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

LoginPage.propTypes = {
  menuStore: PropTypes.object.isRequired,
  configStore: PropTypes.object.isRequired
}

export default inject('menuStore', 'configStore')(observer(LoginPage))
