import { observer } from 'mobx-react'
import React, { Component } from 'react'
import { extendObservable } from 'mobx'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import { Input } from '../../components/formComponents'
import { Link } from 'react-router-dom'
import ChangeEmailStore from '../../store/ChangeEmail.store'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import { extractError } from '../../service/response.service'
import User from '../../store/user.store'
import historyService from '../../service/history.service'
import { Alert } from 'antd'
class EmailForm extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      sending: false,
      changeEmailStore: new ChangeEmailStore(),
      successSubmit: false,
      errorMessage: '',
      submitErr: false
    })
  }

  onSubmit = e => {
    e.preventDefault()
    this.sending = true
    this.submitErr = false
    const submit = this.changeEmailStore.submit()
    if (submit) {
      submit.then(() => {
        this.sending = false
        this.successSubmit = true
      }).catch((err) => {
        // email already in use
        this.submitErr = true
        if(t[extractError(err)]) {
          this.errorMessage = t[extractError(err)]
        } else this.errorMessage = t.RESEND_VERIFICATION_EMAIL_ERROR
      })
    }
    this.sending = false
  }

  getAttrs = field => {
    const { model, setVal, validators } = this.changeEmailStore
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: validators[field]
    }
  }

  render() {
    if (this.successSubmit) {
      return (
        <ThankYouOverlay
          title={t.THANK_YOU}
          description={t.SAVE_EMAIL}
          buttonText={t.BACK_TO_PROFILE}
          buttonLink='/profile'
        />
      )
    }
    return (
      <div>
        <p style={{ fontSize: 16, marginBottom: 30 }}>Email: {User.user.email}</p>
        <Form onSubmit={this.onSubmit}>
          <Input
            name='email'
            {...this.getAttrs('email')}
            label={t['NEW_USER_EMAIL'] + '*'}
            placeholder={t.FORM_PLACEHOLDER(t['NEW_USER_EMAIL'])}
          />
          <Input
            name='emailConfirm'
            {...this.getAttrs('emailConfirm')}
            label={t['NEW_USER_EMAIL_CONFIRM'] + '*'}
            placeholder={t.FORM_PLACEHOLDER(t['NEW_USER_EMAIL_CONFIRM'])}
          />
        </Form>
        <Form.Group style={{ margin: 30 }} className='actions text-center'>
          <div className='group'>
            <Button
              style={{ marginRight: 20 }}
              type='submit'
              className='button primary'
              onClick={this.onSubmit}
              disabled={this.sending}
            >
              {t['EMAIL_SAVE']}
            </Button>
            <Link to='/profile'
              className='button secondary'
              onClick={() => historyService.push('/profile')}>
              {t['FORM_CANCEL']}
            </Link>
          </div>
        </Form.Group>
        {this.submitErr && <Alert message={this.errorMessage} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      </div>
    )
  }
}

export default observer(EmailForm)
