import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import UserActivityForm from './UserActivityForm'
import moment from 'moment'
import { api } from '../../../service/api.service'
// import {
//  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
// } from 'recharts'

class UserActivityPage extends Component {
  constructor (props) {
    super(props)

    extendObservable(this, {
      results: [],
      totalUsers: 0,
      model: {
        startDate: moment().subtract(30, 'days'),
        endDate: moment(),
        type: 'all'
      }
    })
    this.onSubmit = this.onSubmit.bind(this)
    this.onSubmit()
  }

  setVal = (field, val) => {
    this.model[field] = val
  }

  async onSubmit (e) {
    e && e.preventDefault()
    if (moment(this.model.startDate) > moment(this.model.endDate)) {
      const tmp = this.model.startDate
      this.model.startDate = this.model.endDate
      this.model.endDate = tmp
    }
    try {
      const response = await api
        .get('/api/user-activity', { params: { startDate: moment(this.model.startDate).format('YYYY-MM-DD'), endDate: moment(this.model.endDate).format('YYYY-MM-DD'), type: this.model.type } })

      const results = response.data.rows
      this.totalUsers = response.data.totalUsers

      var startDate = moment(this.model.startDate)
      var endDate = moment(this.model.endDate)
      var days = endDate.diff(startDate, 'days')

      var filledResults = []

      var Activities
      var Users

      for (var i = 0; i <= days; i++) {
        Activities = 0
        Users = 0
        for (var j = 0; j < results.length; j++) {
          if (results[j].date === startDate.format('DD-MM-YYYY')) {
            Activities = results[j].Activities
            Users = results[j].Users
          }
        }
        filledResults[i] = { 'date': startDate.format('DD.MM.YYYY.'), Activities, Users }
        startDate = moment(startDate).add(1, 'days')
      }
      this.results = filledResults
    } catch (err) {
      throw err
    }
  }
  render() {
    return (
      <div>
        <UserActivityForm model={this.model} setVal={this.setVal} onSubmit={this.onSubmit} />
        {!!this.results.length && renderResults(this.results, this.totalUsers, this.model)}
      </div>
    )
  }
}

const renderResults = (results, totalUsers) => {
  // const chartWidth = window.innerWidth - 330
  var totalActivities = results.reduce((a, b) => a + b.Activities, 0)

  return (
    <div>
      {/* <div className='user-activity-chart'>
        <LineChart width={chartWidth} height={250} data={results}>
          <CartesianGrid strokeDasharray='1 1' />
          <XAxis dataKey='date' />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line dataKey='Activities' stroke='#26f8ab' strokeWidth='2' dot={{ stroke: '#26f8ab', strokeWidth: 1 }} />
          <Line dataKey='Users' stroke='#fab900' strokeWidth='2' dot={{ stroke: '#fab900', strokeWidth: 1 }} />
        </LineChart>
      </div> */}
      <table className='table'>
        <thead>
          <tr>
            <th width={200}>Date</th>
            <th width={200}>Activities</th>
            <th width={200}>Users</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {results.map((r, index) => (
            <tr key={index}>
              <td>
                {r.date}
              </td>
              <td>{r.Activities}</td>
              <td>
                {r.Users}
              </td>
              <td colSpan={9} />
            </tr>
          ))}
          <tr key={results.length}>
            <td style={{ fontWeight: 'bold' }}>Total</td>
            <td style={{ fontWeight: 'bold' }}>{totalActivities}</td>
            <td style={{ fontWeight: 'bold' }}>{totalUsers}</td>
            <td colSpan={9} />
          </tr>
        </tbody>
      </table>
    </div>)
}
export default observer(UserActivityPage)
