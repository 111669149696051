import t from '../service/translate.service'

export default {
  // user and accont info
  get firstName () {
    return {
      label: t['USER_FIRST_NAME'],
      rules: 'required|string|between:1,100',
      value: ''
    }
  },
  get lastName () {
    return {
      label: t['USER_LAST_NAME'],
      rules: 'required|string|between:1,100',
      value: ''
    }
  },
  get email () {
    return {
      label: t['USER_EMAIL'],
      rules: 'required|string|between:1,100',
      value: ''
    }
  },

  get loginEmail () {
    return {
      label: t['USER_EMAIL'],
      rules: 'required|string|between:1,100',
      value: '',
      error: t['USER_EMAIL'] + t['IS_REQUIRED']
    }
  },

  get password () {
    return {
      label: t['USER_PASSWORD'],
      rules: 'required|string|between:10,50',
      value: ''
    }
  },

  get loginPassword () {
    return {
      label: t['USER_PASSWORD'],
      rules: 'required|string',
      value: '',
      error: t['USER_PASSWORD'] + t['IS_REQUIRED']
    }
  },

  get confirmPassword () {
    return {
      label: t['USER_PASSWORD_CONFIRM'],
      rules: 'required|string|same:password',
      value: ''
    }
  },

  get birthday () {
    return {
      label: t['USER_DATE_OF_BIRTH'],
      rules: 'required',
      value: '',
      element: 'DatePicker'
    }
  },

  // address info
  get contractNumber () {
    return {
      label: t['USER_TENANT_NUMBER'],
      rules: 'required|contract',
      value: '',
      disabled: true
    }
  },
  get street () {
    return {
      label: t['USER_STREET_NAME'],
      rules: 'required',
      span: 8,
      value: '',
      disabled: true
    }
  },
  get plz () {
    return {
      label: t['USER_ZIP'],
      rules: 'required',
      value: '',
      disabled: true
    }
  },
  get city () {
    return {
      label: t['USER_CITY'],
      rules: 'required',
      value: '',
      disabled: true
    }
  },

  get address () {
    return {
      label: t['USER_ADDRESS'],
      rules: 'required',
      value: ''
    }
  },

  // contact info
  get phone () {
    return {
      label: t['USER_PHONE'],
      rules: 'phone',
      value: ''
    }
  },
  get mobile () {
    return {
      label: t['USER_MOBILE'],
      rules: 'phone',
      value: ''
    }
  },
  // lang info
  get preferredLanguage () {
    return {
      label: t['USER_PREFERRED_LANGUAGE'],
      // rules: 'required',
      value: ''
    }
  },
  // lang info
  get active () {
    return {
      label: t['USER_ACCEPTED'],
      // rules: 'required',
      element: 'Checkbox'
    }
  },
  get subscribe () {
    return {
      label: t['USER_SUBSCRIBE'],
      element: 'Checkbox',
      value: true,
      rules: 'required|boolean|accepted',
      span: 24
    }
  }
}
