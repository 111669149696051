import React from 'react'

const BackIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='11'
    height='18'
    viewBox='0 0 11 18'
  >
    <g>
      <g>
        <path
          fill='#677377'
          d='M3.994 9L11 16.006l-2 2L-.006 9 9-.006l2 2z'
        />
      </g>
    </g>
  </svg>
)

export default BackIcon
