import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { Popconfirm, Input, Button } from 'antd'
import partnerCategoryStore from '../../../store/admin/PartnerCategory.store'

const paginationContainerStyle = {
  marginTop: 10
}

const queryInputStyle = {
  float: 'right',
  width: 300,
  marginTop: -40
}

class PartnerCategoriesList extends Component {
  constructor (props) {
    super(props)

    partnerCategoryStore.getAll()
  }

  render () {
    console.log(partnerCategoryStore.loading)
    return (
      <div>
        <h1>Partner Categories</h1>
        <div style={queryInputStyle}>
          <div  className='table-inputs'>
            <Input
              className='ant-input'
              placeholder='Type to search'
              value={partnerCategoryStore.query}
              onChange={e => partnerCategoryStore.updateQuery(e.target.value)}
            />
          </div>
        </div>
        {(!partnerCategoryStore.loading || partnerCategoryStore.partnerCategories.length) && (
          <div>
            <table className='table'>
              <thead>
                <th>Name</th>
                <th>Position</th>
                <th colSpan={2} />
              </thead>
              <tbody>
                {partnerCategoryStore.partnerCategories.map(c => (
                  <tr key={c.id}>
                    <td>
                      <Link to={`/admin/partner-category/${c.id}`}>
                        {c.name}
                      </Link>
                    </td>
                    <td>{c.position}</td>
                    <td>
                      <Link to={`/admin/partner-category/${c.id}`}>Edit</Link>
                    </td>
                    <td>
                      <Popconfirm
                        placement='topRight'
                        title={`Are you sure you want to delete this category (${c.name})?`}
                        onConfirm={() => partnerCategoryStore.deleteCategory(c.id)}
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='ant-btn ant-sm' size='small'>Delete</Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div style={paginationContainerStyle}>
              <Link to={'/admin/partner-category/new'}>
                <Button className='ant-btn ant-btn-primary'>New Category</Button>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default observer(PartnerCategoriesList)
