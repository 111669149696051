import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import propTypes from "prop-types";
import t from "../service/translate.service";
import get from "lodash/get";
import User from "../store/user.store";
import { Button, Popconfirm, Modal, Input, Select } from "antd";

class ContractCard extends Component {
  static propTypes = {
    contract: propTypes.object.isRequired,
    rentAccEnabled: propTypes.bool,
    onEditConfirm: propTypes.func,
    deleteUserContract: propTypes.func,
    editContractData: propTypes.func,
    user: propTypes.object,
    isAdmin: propTypes.bool,
    contractStore: propTypes.any,
    onClick: propTypes.func,
    contract: propTypes.object,
  };
  constructor(props) {
    super(props);
    this.status = () => {

      if(!this.props.menuStore.emailVerificationEnabled) return this.checkContractStatus()
      let statusClass = "";
      if (this.props.contract.status === 'active') {
        statusClass = "active";
      } else if (this.props.contract.status === 'pending') {
        statusClass = "pending";
      } else {
        statusClass = t.CONTRACT_VERIFICATION_BTN;
      }
      return <span className={`status ${statusClass}`}>{statusClass}</span>;
    };

    this.state = {
      verificationStarted: false,
      showModal: false,
      editedContractNumber: this.props.contract.contractNumber,
      editedContactNumber: this.props.contract.aimsContactId,
      editedLoyaltyRegistration: this.props.user ? this.props.user?.registeredToLoyaltyProgram : null,
      editedLoyaltySerialNum: this.props.user ? this.props.user?.loyaltySerialNumber : null,
      areYouSure: false,
      contractVerified: false,
    }
  }

  componentDidMount() {
    (async() => {
       if(this.props.menuStore.emailVerificationEnabled && !this.props.isAdmin && this.props.contract.status !== 'pending' ) {
        const res = await this.props.contractStore.getContractVerificationInfo(this.props.contract.contractNumber)
        if(res) this.setState({contractVerified: true})
       }
    })()
  }


  checkContractStatus () {
    let statusClass = "";
    if (this.props.contract.active && this.props.contract.valid) {
      statusClass = "active";
    } else if (this.props.contract.active && !this.props.contract.valid) {
      statusClass = "pending";
    } else {
      statusClass = "inactive";
    }
    return <span className={`status ${statusClass}`}>{statusClass}</span>;
  }

  onConfirmEdit () {
    this.setState({showModal: false})
    this.setState({areYouSure: true})
  }

  startVerificationProcess = () => {
    if(this.props.contract.valid)
   this.props.contractStore.verificationStarted = true
   this.props.contractStore.contract.contractNumber = this.props.contract.contractNumber
  }

   onEditSubmit () {
    this.setState({showModal: false})
    this.setState({areYouSure: false})
    const data = {
      contractNumber: this.state.editedContractNumber,
      contactId: this.state.editedContactNumber,
      appId: this.props.contract.id,
      userId: this.props.contract.userId,
      loyaltySerialNumber: this.state.editedLoyaltyRegistration === false ? null : this.state.editedLoyaltySerialNum,
      registeredToLoyalty: this.state.editedLoyaltyRegistration

    }
    this.props.editContractData(data)
  }

  getInitialDropdownValue () {
    if(this.state.editedLoyaltyRegistration === true)  {
      return this.dropdownOptions[0]}
   else return this.dropdownOptions[1]}

  dropdownOptions = [
    {label: 'yes', value: true}, {label: 'no', value: false}
  ]

  checkIfUserCanOpen (contract) {
    if(this.props.isAdmin) return
    else this.props.onClick(contract)
  }


  render() {
    return (
      <div style={{cursor: 'pointer'}} className={`item ${this.props.isAdmin && 'adminContractEditContainer'} `}>
        <div className={`container ${this.props.clickable ? 'green-border' : ''}`}>
         {this.props.clickable && (
          <div className="info-mark">?</div>
         )}
         {!this.props.isAdmin && this.props.menuStore.emailVerificationEnabled  && !this.state.contractVerified && this.props.contract.status !== 'pending' &&
           <Button onClick={() => this.startVerificationProcess()} className="contractVerificationStatus">{t.CONTRACT_VERIFICATION_BTN}</Button>
         }
          <div
          onClick={() => this.checkIfUserCanOpen(this.props.contract)}
            className="adminEditContainer"
            style={{ display: "flexbox", direction: "row" }}
          >
            <div className="details">
              <div className="group">
                {this.status()}
                <span className="contract-number">
                  {this.props.contract.contractNumber}
                </span>
                {(get(User, "user.admin", false) ||
                  get(User, "user.operator", false)) && (
                  <span className="contract-number">{`(app_id: ${this.props.contract.id}`}, {`contact_id: ${this.props.contract.aimsContactId})`}</span>
                )}
              </div>
              <div className="address">{this.props.contract.address}</div>
            </div>

            {this.props.isAdmin &&
            <div className="adminEditBtnContainer">
              <Popconfirm
                placement="topRight"
                title={`Are you sure you want to edit ${this.props.user && this.props.user.firstName} ${this.props.user && this.props.user.lastName}?`}
                onConfirm={() =>  this.setState({showModal: true}) }
                okText="Yes"
                cancelText="No"
              >
                <Button className="ant-btn ant-sm">Edit</Button>
              </Popconfirm>
              <Popconfirm
                placement="topRight"
                title={`Are you sure you want to delete ${this.props.user && this.props.user.firstName} ${this.props.user && this.props.user.lastName}?`}
                onConfirm={() => this.props.deleteUserContract(this.props.contract.contractNumber)}
                okText="Yes"
                cancelText="No"
              >
                <Button className="ant-btn ant-sm" style={{ marginTop: 5 }}>
                  Delete
                </Button>
              </Popconfirm>
            </div>}
            {this.props.isAdmin && this.state.showModal &&
            <Modal onCancel={() => this.setState({showModal: false})} onOk={() => this.onConfirmEdit()} open={this.state.showModal}>
              <label className="editContractWarning">WARNING, editing this may result in data corruption, use with caution</label>
              <div className="editContractModal">
              <label className="editContractLabelContract">Contract number:</label>
              <Input onChange={(e) => this.setState({editedContractNumber: e.target.value})} defaultValue={this.props.contract.contractNumber} label='contract number' style={{width: '70%'}}></Input>
              <label className="editContractLabelContact">Contact id:</label>
              <Input onChange={(e) => this.setState({editedContactNumber: e.target.value})} defaultValue={this.props.contract.aimsContactId} label='contact number' style={{width: '70%'}}></Input>
              <label className="editContractLabelContact">Loyalty serial number:</label>
              <Input onChange={(e) => this.setState({editedLoyaltySerialNum: e.target.value})} defaultValue={this.props.user.loyaltySerialNumber} label='loyaltySerialNumber' style={{width: '70%',}}></Input>
              <label className="editContractLabelContact">Registered to loyalty:</label>
              <Select value={this.getInitialDropdownValue()} onChange={(e) => this.setState({editedLoyaltyRegistration: e})} label="Registered to loyalty:" options={this.dropdownOptions} style={{width: '70%'}}></Select>
              </div>
              </Modal>}
              {this.props.isAdmin && this.state.areYouSure &&
                <Modal onCancel={() => this.setState({areYouSure: false})} onOk={() => this.onEditSubmit()} open={this.state.areYouSure}>
                <label className="editContractLabelContract">Are you sure you want to edit {this.props.user && this.props.user.firstName} {this.props.user && this.props.user.lastNamr} contract data?</label>
                </Modal>
              }
          </div>
        </div>
      </div>
    );
  }
}
export default inject('contractStore', 'menuStore')(observer(ContractCard))
