import React, { Component } from 'react'
import { observer } from 'mobx-react'
import InboxMessagesForm from './InboxMessagesForm'
import adminAlertStore from '../../../store/admin/AdminAlert.store'
import PropTypes from 'prop-types'
import withRouter from '../../../components/withRouter'

class InboxMessagesEdit extends Component {
  static propTypes = {
    params: PropTypes.object
  }
  constructor(props) {
    super(props)
    adminAlertStore[this.props.useParams.id === 'new' ? 'new' : 'load'](
      this.props.useParams.id
    )
  }
  render() {
    return (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          <InboxMessagesForm store={adminAlertStore} id={this.props.useParams.id} />
        </div>
      </div>
    )
  }


}

export default withRouter(observer(InboxMessagesEdit))
