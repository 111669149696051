// 'use strict';
import React, { Component } from 'react'
import ForgotPasswordForm from './ForgotPasswordForm'
import t from '../../service/translate.service'

class ForgotPasswordPage extends Component {
  render () {
    return (
      <div className='reset-password'>
        <div className='text-center'>
          <div className='title-primary-wrapper'>
            <h1>{t.FORGOT_PASSWORD_TITLE}</h1>
          </div>
        </div>
        <div className='text-center'>
          <p className='text'>{t.FORGOT_PASSWORD_TEXT}</p>
        </div>
        <ForgotPasswordForm />
      </div>
    )
  }
}

export default ForgotPasswordPage
