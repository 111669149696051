import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Menu } from 'antd'

const Navigation = ({ user, logout }) => {
  if (!user.user.operator) {
    return (
      <Menu mode='inline' defaultOpenKeys={['sub1']}>
        <Menu.Item key='1'>
          <Link to={'/admin/user'}>Users</Link>
        </Menu.Item>
        <Menu.Item key='11'>
          <Link to={'/admin/sap-invalid-calls'}>Sap invalid calls</Link>
        </Menu.Item>
        {/*<Menu.Item key='3'>
          <Link to={'/admin/consultation'}>Consultations</Link>
        </Menu.Item>
        <Menu.Item key='7'>
          <Link to={'/admin/scheduled-consultation'}>Scheduled Consultations</Link>
        </Menu.Item>*/}
        <Menu.Item key='4'>
          <Link to={'/admin/partner-categories'}>Partner Categories</Link>
        </Menu.Item>
        <Menu.Item key='5'>
          <Link to={'/admin/partners'}>Partners</Link>
        </Menu.Item>
        <Menu.Item key='9'>
          <Link to={'/admin/faq-categories'}>Faq Categories</Link>
        </Menu.Item>
        <Menu.Item key='10'>
          <Link to={'/admin/faq-questions'}>Faq Questions</Link>
        </Menu.Item>
        <Menu.Item key='2'>
          <a href='/admin/admin-alert'>Inbox Messages</a>
        </Menu.Item>
        <Menu.Item key='12'>
          <Link to={'/admin/advents'}>Extra content</Link>
        </Menu.Item>
        <Menu.Item key='13'>
          <Link to={'/admin/app-settings'}>App settings</Link>
        </Menu.Item>
        <Menu.Item key='8'>
          <a href='/admin/user-activity'>User activity</a>
        </Menu.Item>
        <Menu.Item key='6'>
          <a href='#' onClick={e => logout()}>Log out</a>
        </Menu.Item>
      </Menu>
    )
  }
  return (
    <Menu mode='inline' defaultOpenKeys={['sub1']}>
      <Menu.Item key='3'>
        <Link to={'/admin/consultation'}>Consultations</Link>
      </Menu.Item>
      <Menu.Item key='7'>
        <Link to={'/admin/scheduled-consultation'}>Scheduled Consultations</Link>
      </Menu.Item>
      <Menu.Item key='6'>
        <a href='#' onClick={e => logout()}>Log out</a>
      </Menu.Item>
    </Menu>
  )
}

class Header extends Component {

  logoutUser = () => {
    const { user } = this.props
    user.logout()
  };

  render () {
    const { user } = this.props
    const nav = user.user
      ? <Navigation user={user} logout={this.logoutUser} />
      : null

    return (
      <div className=''>
        {nav}
      </div>
    )
  }
}

Navigation.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func
}

Header.propTypes = {
  user: PropTypes.object
}

export default observer(Header)
