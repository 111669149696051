import React, { Component } from 'react'
import { observer } from 'mobx-react'
import adminAlertStore from '../../../store/admin/AdminAlert.store'
import PropTypes from 'prop-types'
import './css/inboxMessagesDetails/inboxMessagesDetails.css'
import withRouter from '../../../components/withRouter'

class InboxMessagesDetails extends Component {
  static propTypes = {
    params: PropTypes.object
  }
  constructor(props) {
    super(props)
    adminAlertStore['load'](
      this.props.useParams.id
    )
  }
  render() {
    const { model, messageType, usersByAlert } = adminAlertStore
    return (
      <div style={{ display: 'flex' }} className='messages-details-wrap'>
        <div style={{ flexGrow: 1 }}>
          <h1>Message Details</h1>
          <h2>Users List</h2>
          <ul className='users-list-holder'>
            {usersByAlert.map(user => {
              return (
                <li key={user.id} className='users-list-item'>
                  <p>{user.firstName} {user.lastName} {`(${user.email})`}</p>
                </li>
              )
            })}
          </ul>
          <p style={{ marginTop: '-15px', marginBottom: '40px' }}>{messageType}</p>
          <h2>Content</h2>
          <div className='input-div'>
            <label>En</label>
            <p>{model.content}</p>
          </div>
          <div className='input-div'>
            <label>De</label>
            <p>{model.contentDe}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(InboxMessagesDetails))
