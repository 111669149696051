import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Popconfirm, Spin, Button } from "antd";
import appSettingsStore from "../../../store/admin/AppSettings.store";

class AppSettingsList extends Component {
  constructor(props) {
    super(props);

    appSettingsStore.getAll();
  }

  getItemStyle = () => {
    return {
      userSelect: "none",
      border: "none",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: "1px solid #e9e9e9",
      padding: "8px 20px",
      alignItems: "center",
      background: "white",
    };
  };

  getListStyle = () => ({
    transition: "background 0.2s ease-out",
  });

  render() {
    return (
      <div>
        <h1>App settings</h1>
        <div style={{ minWidth: 900 }}>
          <div
            style={{
              marginBottom: "30px",
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              gap: "18px",
            }}
          >
            <Link to={"/admin/app-settings/new"}>
              <Button className="ant-btn ant-btn-primary m-r-sm">New</Button>
            </Link>
          </div>
          <div className="table__header">
            <div style={{ width: 170 }}>Name</div>
            <div style={{ width: 200 }}>Value</div>
            <div style={{ width: 150 }}>Type</div>
            <div style={{ width: 10 }} />
            <div style={{ width: 40 }} />
          </div>
          {appSettingsStore.loading ? (
            <div className="spinner-container">
              <Spin />
            </div>
          ) : (
            <div style={{ minHeight: 400 }}>
              <div tyle={this.getListStyle()}>
                {appSettingsStore.appSettings.length ? (
                  appSettingsStore.appSettings.map((p, index) => (
                    <div index={index} key={p.id}>
                      <div style={this.getItemStyle()}>
                        <div style={{ width: 170 }}>
                          <Link to={`/admin/app-settings/${p.id}`}>
                            {p.name}
                          </Link>
                        </div>
                        <div style={{ width: 200 }}>
                          <span>{p.value}</span>
                        </div>
                        <div style={{ width: 150 }}>
                          <span>{p.type}</span>
                        </div>
                        <div style={{ width: 10 }}>
                          <Link to={`/admin/app-settings/${p.id}`}>Edit</Link>
                        </div>
                        <div className="m-r-sm" style={{ width: 60 }}>
                          <Popconfirm
                            placement="topRight"
                            title={`Are you sure you want to delete this avent (${p.name})?`}
                            onConfirm={() =>
                              appSettingsStore.deleteAppSetting(p.id)
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button className="ant-btn ant-sm">Delete</Button>
                          </Popconfirm>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div style={{ textAlign: "center", marginTop: "80px" }}>
                    No results
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(AppSettingsList);
