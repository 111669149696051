import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Input } from 'antd'
import faqCategoryStore from '../../../store/admin/FaqCategory.store.js'
import { Navigate } from 'react-router-dom'

class FaqCategoryForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    faqCategoryStore.save()
  }

  onClone (e) {
    e.preventDefault()
    faqCategoryStore.clone()
  }

  render () {
    const { model } = faqCategoryStore

    if(faqCategoryStore.formSubmittedSuccessfully) {
      faqCategoryStore.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/faq-categories/' }} />
    }

    return (
      !faqCategoryStore.loading && (
        <div>
          <h1>Faq Category</h1>
          <form onSubmit={this.onSubmit}>
            <div className='input-div'>
              <label>Category Name EN *</label>
              <Input
                value={model.name}
                type='input'
                onChange={e => faqCategoryStore.setVal('name', e.target.value)}
                required
              />
            </div>
            <div className='input-div'>
              <label>Category Name DE *</label>
              <Input
                value={model.nameDe}
                type='input'
                onChange={e => faqCategoryStore.setVal('nameDe', e.target.value)}
                required
              />
            </div>
            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/faq-categories'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(FaqCategoryForm)
