import axios from 'axios'
import TokenService from './token.service'
// just a holder for the API endpoint
export const API_BASE = ''

export const api = axios.create({
  baseURL: API_BASE,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    'GCP-App-Info': `GCP App - web`
  }
})

api.interceptors.request.use(
  async (config) => {

    const token = TokenService.get()
    if (token) {
      config.headers['Authorization'] = token // for Node.js Express back-end
    }

    await TokenService.generateCsrfToken().then(res => {
      config.headers['X-CSRF-Token'] = res
    })

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config

    if ((err.response && err.response.status >= 500) || err.message.includes('timeout') || err.message.includes('Network Error')) {
      /* history.push({pathname: '/error', state: err }) */
      return Promise.reject(err)
    }

    if (originalConfig.url !== '/api/auth/login' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          const rs = await api.post('/api/auth/refresh-token', {
            refreshToken: TokenService.getRefreshToken()
          })

          const { token, refreshToken } = rs.data
          TokenService.create({ token, refreshToken })

          return api(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)
