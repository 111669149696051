import { api } from '../../service/api.service'
import { action, extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'

class PartnerCategoryStore {
  constructor () {
    extendObservable(this, {
      partnerCategories: [],
      model: {},
      error: null,
      page: 1,
      count: 0,
      loading: false,
      query: '',
      formSubmittedSuccessfully: false
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  load = action(id => {
    if (id === 'new') return
    this.loading = true
    api
      .get('/api/partner-category/' + id)
      .then(extractResponse)
      .then(res => {
        const model = pick(res, [
          'id',
          'name',
          'nameDe',
          'position'
        ])

        this.model = model
      })
      .finally(() => {
        this.loading = false
      })
  })

  new () {
    this.model = {
      id: null,
      name: null,
      nameDe: null,
      position: 0
    }
  }

  deleteCategory (id) {
    api.delete('/api/partner-category/' + id).then(() => {
      this.getAll()
    })
  }
  getAll () {
    this.loading = true
    api
      .get('/api/partner-categories', { params: { q: this.query } })
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.partnerCategories = rows
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    console.log('Setting', key, val)
    set(this.model, key, val)
  }

  save () {
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    return api
      .post(
        '/api/partner-category',
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/partner-categories/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/partner-category' + path,
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/partner-categories/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  updateQuery (val) {
    this.query = val
    this.debouncedGetAll()
  }
}
export default new PartnerCategoryStore()
