import React from 'react'

const FaqIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 36 36'
    version='1.1'
    width='36pt'
    height='36pt'
  >
    <path
      d='M24 13V4H3v14h5v4l5-4h2v10h8.828L29 32v-4h4V13zm-11.35 4L9 19.92V17H4V5h19v12zM32 27h-4v2.978L24.17 27H16v-9h8v-4h8z'
      fill='white'
      strokeWidth={1}
      stroke='white'
    />
  </svg>
)

export default FaqIcon
