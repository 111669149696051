import { api } from '../../service/api.service'
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import pick from 'lodash/pick'
import set from 'lodash/set'
import omit from 'lodash/omit'
import history from '../../service/history.service'

class AdventStore {
  constructor () {
    extendObservable(this, {
      advents: [],
      model: {},
      error: null,
      count: 0,
      loading: false,
      query: '',
      formSubmittedSuccessfully: false,
      isSubmitted: false,

    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  async load (id) {
    this.loading = true

    try {
      const [adventRes] = await Promise.all([
        api.get('/api/advent/' + id)
      ])

      const advent = extractResponse(adventRes)
      const model = pick(advent, [
        'id',
        'name',
        'nameDe',
        'url',
        'icon',
        'active',
        'position'
      ])
      this.isSubmitted = false
      this.model = model
      this.loading = false
    } catch (err) {
      this.error = extractError(err)
      this.loading = false
    }
  }

  new () {
    this.isSubmitted = false
    this.model = {
      id: null,
      name: null,
      nameDe: '',
      url: null,
      icon: null,
      active: false,
      position: 0
    }
  }

  deleteAdvent (id) {
    api.delete('/api/advent/' + id).then(() => {
      this.getAll()
    })
  }
  getAll () {
    this.loading = true
    api
      .get('/api/advent')
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.advents.replace(rows)
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  setVal = (key, val) => {
    set(this.model, key, val)
  }

  save () {
    if (!this.model.icon) {
      this.isSubmitted = true
      return false
    }
    if (this.model.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  create () {
    return api
      .post(
        '/api/advent',
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/advents/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }

  update () {
    let path = '/' + this.model.id
    return api
      .put(
        '/api/advent' + path,
        omit(this.model, ['id', 'position'])
      )
      .then(res => {
        this.formSubmittedSuccessfully = true
        history.push('/admin/advents/')
      })
      .catch(err => {
        this.error = extractError(err)
      })
  }
  saveOrder = () => {
    const advents = this.advents.map(advent => {
      return { id: advent.id, position: advent.position }
    })

    this.loading = true
    api
      .post('/api/advent/reorder', { advents })
      .then(res => {
        this.advents.replace(extractResponse(res))
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}
export default new AdventStore()
