import React, { Component } from 'react'
import t from '../service/translate.service'

class TermsAndConditionsPage extends Component {
  render () {
    return (
      <div className='terms-and-conditions'>
        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_1}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_3}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_4}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_5}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_1_6}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_2}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_2_3}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_3}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_3}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_4}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_3_5}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_4}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_2}</p>
        <ul>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_1}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_2}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_3}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_4}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_5}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_6}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_7}</li>
          <li>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_LIST_8}</li>
        </ul>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_3}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_4}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_4_5}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_5}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_5_3}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_6}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_6_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_6_2}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_7}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_7_3}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_8}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_8_1}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_8_2}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_9}</h2>
        <p>
          {t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_1a}
          <a href='https://www.grandcityproperty.de/datenschutz'>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_LINK}</a>
          {t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_1b}
        </p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_2}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_3}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_4}</p>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_9_5}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_10}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_10_1}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_11}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_11_1}</p>

        <h2>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TITLE_12}</h2>
        <p>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_TEXT_12_1}</p>
        <div id='bottom-text'><span>{t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_BOLD_NOTE}</span>  {t.LOYALTY_PROGRAM_TERMS_AND_CONDITIONS_NOTE}</div>
      </div>
    )
  }
}
export default TermsAndConditionsPage
