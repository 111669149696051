import {autorun, computed, makeObservable, observable} from "mobx";
import {api, API_BASE} from "../service/api.service";

class ReceiptsStore {
  loadingReceipts = false;
  receipts = [];
  preview = false;
  selectedContractNumber = null;

  constructor() {
    makeObservable(this, {
      receipts: observable,
      loadingReceipts: observable,
      receiptContracts: computed,
      receiptsCount: computed,
      selectedContractReceipts: computed,
      selectedContractData: computed,
      preview: observable,
      selectedContractNumber: observable,
    });
  }

  get selectedContractData() {
    return this.receipts.find(r => `${r.contractNumber}-${r.BK_Jahr}-${r.caseNumber}` === this.selectedContractNumber);
  }

  get selectedContractReceipts() {
    return this.selectedContractData?.documents || [];
  }

  get receiptsCount() {
    return this.selectedContractReceipts.length;
  }

  get receiptContracts() {
    return this.receipts.map(r => `${r.contractNumber}-${r.BK_Jahr}-${r.caseNumber}`);
  }

  async fetchReceipts() {
    this.loadingReceipts = true;
    const res = await api.get(`${API_BASE}/api/receipts`);
    this.receipts = res.data;
    // preselect first contract
    if (this.receipts.length) {
      const receipt = this.receipts[0]
      this.selectContractNumber(`${receipt.contractNumber}-${receipt.BK_Jahr}-${receipt.caseNumber}`);
    }
    this.loadingReceipts = false;
  }

  selectContractNumber(number) {
    this.selectedContractNumber = number;
  }
}

const receiptsStore = new ReceiptsStore();

export default receiptsStore;