import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Input } from 'antd'
import pcs from '../../../store/admin/PartnerCategory.store.js'
import { Navigate } from 'react-router-dom'

class PartnerCategoryForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    pcs.save()
  }

  onClone (e) {
    e.preventDefault()
    pcs.clone()
  }

  render () {
    const { model } = pcs
    if(pcs.formSubmittedSuccessfully) {
      pcs.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/partner-categories/' }} />
    }

    return (
      !pcs.loading && (
        <div>
          <h1>Partner Category</h1>
          <form onSubmit={this.onSubmit}>
            <div className='input-div'>
              <label>Name *</label>
              <Input
                value={model.name}
                type='input'
                onChange={e => pcs.setVal('name', e.target.value)}
                required
              />
            </div>
            <div className='input-div'>
              <label>Name German *</label>
              <Input
                value={model.nameDe}
                type='input'
                onChange={e => pcs.setVal('nameDe', e.target.value)}
                required
              />
            </div>
            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/partner-categories'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(PartnerCategoryForm)
