
import t from "../../../../service/translate.service";
import { Modal } from "antd";

const TimeExceededModal = (props) => {

  return (
    <Modal
      open={true}
      onCancel={() => props.onTimeExceeded()}
      footer={[
        <button
          className="button primary small"
          key="ok"
          onClick={() => props.onTimeExceeded()}
        >
          {t.CONSULTATIONS_OK}
        </button>
      ]}
    >
      <div className="modal-body-wrapper">
        <p className="modal-p">
          {t.CONSULTATIONS_EXCEEDED_BOOKING_TIME}
        </p>
      </div>
    </Modal>
  );
};

export default TimeExceededModal;
