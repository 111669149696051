import contractService from './contract.service'

export const checkIsUserCondominium = (contracts) => {
  const condominiumContracts = checkCondominiumContracts(contracts)
  const activeCondominiumContracts = checkActiveContracts(condominiumContracts)
  const activeContracts = checkActiveContracts(contracts)
  if (contracts?.length === 0) {
    return false
  } else if (contracts?.length === condominiumContracts?.length) {
    return true
  } else if (activeContracts?.length === 0) {
    return false
  } else if ( activeCondominiumContracts?.length === activeContracts?.length) {
    return true
  } else {
    return false
  }
} 

export const checkIfUserHasMixedContracts = (contracts) => {
  const condominiumContracts = checkCondominiumContracts(contracts)
  const activeCondominiumContracts = checkActiveContracts(condominiumContracts)
  const activeContracts = checkActiveContracts(contracts)
  if (contracts?.length === 0) {
    return false
  } else if ( activeCondominiumContracts?.length !== activeContracts?.length) {
    return true
  }
}

const checkActiveContracts = (contracts) => {
  return contracts.filter((contract) => contract?.active && contract?.valid)
}

const checkCondominiumContracts = (contracts) => {
  return contracts?.filter((contract) => contractService.checkIsContractCondominium(contract?.contractNumber))
}


