import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Input } from 'antd'
// import propTypes from 'prop-types'
import Select from 'react-select'
import find from 'lodash/find'
import adminAlertStore from '../../../store/admin/AdminAlert.store'
import './css/inboxMessagesForm/inboxMessagesForm.css'
import { Navigate } from 'react-router-dom'
//import 'antd/dist/antd.min.css'

class InboxMessagesForm extends Component {
  /* static propTypes = {
    id: propTypes.string
  } */

  onSubmit(e) {
    e.preventDefault()
    adminAlertStore.save()
  }

  onClone(e) {
    e.preventDefault()
    adminAlertStore.clone()
  }

  render() {
    const { TextArea } = Input
    const { model, setVal, selectUsersOptions, selectAlertOptions, selectedAlertOption,  onSelecetInputChange, userList, contractList, setSelectedUsers, onContractSelect, selectedUserBy } = adminAlertStore
    if (adminAlertStore.formSubmittedSuccessfully) {
      adminAlertStore.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/admin-alert' }} />
    }

    /* const uploadButton = (
      <div>
        <div className='ant-upload-text'>Upload</div>
      </div>
    ) */
    const customStyles = {
      control: (styles) => ({ ...styles, minWidth: '220px' }),
      option: (styles) => {
        return {
          ...styles,
          fontSize: '12px',
          textAlign: 'left',
          width: 'auto'
        }
      }
    }
    let cityContractList = []
    let addressContractList = []
    let numberContractList = []
    if (contractList.length > 0 && adminAlertStore.selectedUserOption.value === 'Select users by city') {
      contractList.map(x => cityContractList.filter(a => a.city === x.city).length > 0 ? null : cityContractList.push(x))
    }
    if (contractList.length > 0 && adminAlertStore.selectedUserOption.value === 'Select users by address') {
      contractList.map(x => addressContractList.filter(a => a.address === x.address).length > 0 ? null : addressContractList.push(x))
    }
    if (contractList.length > 0 && adminAlertStore.selectedUserOption.value === 'Select users by contract') {
      contractList.map(x => numberContractList.filter(a => a.contract_number === x.contract_number).length > 0 ? null : numberContractList.push(x))
    }
    let contractBy = ''
    switch (adminAlertStore.selectedUserOption.value) {
      case 'Select users by city':
        contractBy = 'city'
        break
      case 'Select users by address':
        contractBy = 'address'
        break
      case 'Select users by contract':
        contractBy = 'contract number'
    }
    return (
      !adminAlertStore.loading && (
        <div>
          <h1>Inbox Message</h1>
          <form onSubmit={this.onSubmit}>
            <div className='form-top-holder' >
              <Select
                styles={customStyles}
                value={adminAlertStore.selectedUserOption}
                options={selectUsersOptions}
                onChange={option => {
                  model.selectedUsers = []
                  adminAlertStore.selectedUserOption = find(selectUsersOptions, { value: option.value })
                  onSelecetInputChange('')
                }}
                isSearchable
              />
                 <Select
                styles={customStyles}
                value={selectedAlertOption}
                options={selectAlertOptions}
                onChange={option => {
                  adminAlertStore.selectedAlertOption = option
                }}
                placeholder='Select message type'
                isSearchable
              />
              {adminAlertStore.selectedUserOption.value !== 'Select all users' && (
                <div className='input-dropdown-holder'>
                  <Input
                    value={model.selectUserInputValue}
                    type='text'
                    onChange={e => onSelecetInputChange(e.target.value)}
                    onFocus={() => {
                      model.isFocus = true
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        model.isFocus = false
                      }, 100)
                    }}
                    placeholder={adminAlertStore.selectedUserOption.value}
                  />
                  {adminAlertStore.selectedUserOption.value === 'Select specific users' && userList.length > 0 && model['selectUserInputValue'] !== '' && model.isFocus && (
                    <ul className='users-options-holder'>
                      {userList.map((user) => {
                        if (!model.selectedUsers.find((selectedUser) => selectedUser.key === user.key)) {
                          return (
                            <li key={user.key}>
                              <button onClick={(e) => {
                                setSelectedUsers(user, 'add')
                                onSelecetInputChange(``)
                              }} type='button'>{`${user.firstName} ${user.lastName} (${user.email})`}</button>
                            </li>
                          )
                        }
                      })}
                    </ul>
                  )}
                  {adminAlertStore.selectedUserOption.value === 'Select users by contract' && numberContractList.length > 0 && model['selectUserInputValue'] !== '' && model.isFocus && (
                    <ul className='users-options-holder'>
                      {numberContractList.map((contract) => {
                        return (
                          <li key={contract.id}>
                            <button onClick={(e) => {
                              onContractSelect(contract.contract_number)
                              onSelecetInputChange(``)
                            }} type='button'>{`${contract.contract_number}`}</button>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  {adminAlertStore.selectedUserOption.value === 'Select users by address' && addressContractList.length > 0 && model['selectUserInputValue'] !== '' && model.isFocus && (
                    <ul className='users-options-holder'>
                      {addressContractList.map((contract) => {
                        return (
                          <li key={contract.id}>
                            <button onClick={(e) => {
                              onContractSelect(contract.address)
                              onSelecetInputChange(``)
                            }} type='button'>{`${contract.address}`}</button>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                  {adminAlertStore.selectedUserOption.value === 'Select users by city' && cityContractList.length > 0 && model['selectUserInputValue'] !== '' && model.isFocus && (
                    <ul className='users-options-holder'>
                      {cityContractList.map((contract) => {
                        return (
                          <li key={contract.id}>
                            <button onClick={(e) => {
                              onContractSelect(contract.city)
                              onSelecetInputChange(``)
                            }} type='button'>{`${contract.city}`}</button>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </div>
              )}
            </div>
            {adminAlertStore.selectedUserOption.value === 'Select specific users' && model.selectedUsers.length > 0 && (
              <div className='selected-users-holder'>
                <label>Selected users</label>
                <ul className='selected-users-list'>
                  {model.selectedUsers.map((selectedUser, index) => (
                    <li key={`${selectedUser.key}${index}`}>
                      {selectedUser.info}
                      <button onClick={(() => setSelectedUsers(selectedUser, 'remove'))} type='button'>x</button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {(adminAlertStore.selectedUserOption.value === 'Select users by contract' || adminAlertStore.selectedUserOption.value === 'Select users by address' || adminAlertStore.selectedUserOption.value === 'Select users by city') && model.selectedUsers.length > 0 && (
              <div className='selected-users-holder'>
                <label>Selected users by {contractBy} {selectedUserBy}</label>
                <ul className='selected-users-list'>
                  {model.selectedUsers.map((selectedUser, index) => (
                    <li key={`${selectedUser.key}${index}`}>
                      {selectedUser.info}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <div className='input-div'>
              <label>Content</label>
              <TextArea
                value={model.content}
                type='textarea'
                onChange={e => setVal('content', e.target.value)}
                required
                rows={10}
              />
            </div>
            <div className='input-div'>
              <label>Content de</label>
              <TextArea
                value={model.contentDe}
                type='textarea'
                onChange={e => setVal('contentDe', e.target.value)}
                required
                rows={10}
              />
            </div>

            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/admin-alert'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(InboxMessagesForm)
