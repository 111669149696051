import { api } from '../../service/api.service'
import { extendObservable } from 'mobx'
import { extractResponse, extractError } from '../../service/response.service'
import debounce from 'lodash/debounce'
import TokenService from '../../service/token.service'

class UserListStore {
  constructor () {
    extendObservable(this, {
      users: [],
      error: null,
      page: 1,
      count: 0,
      loading: false,
      query: '',
      contractQuery: '',
      orderBy: 'created_at',
      order: 'DESC',
      mailSent: false,
      message: '',
      activeFilter: 0
    })

    this.debouncedGetAll = debounce(this.getAll, 300)
  }

  deleteUser (id) {
    return api.delete('/api/user/' + id).then(() => {
      this.getAll()
    })
  }
  changeActiveStatus (id, status) {
    const data = {id, active: status}
    return api.post('/api/user/change-active-status', data).then(() => {
      this.getAll()
    }).catch(err => {
      this.error = extractError(err)
    })
  }
  getAll () {
    this.loading = true
    api
      .get('/api/user', { params: { page: this.page, q: this.query, contractQuery: this.contractQuery, orderBy: this.orderBy, order: this.order, activeFilter: this.activeFilter } })
      .then(res => {
        const { count, rows } = extractResponse(res)
        this.users = rows
        this.count = count
      })
      .catch(err => {
        this.error = extractError(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
  resendVerificationEmail (userEmail) {
    this.loading = true
    return api
      .post('/api/user/resend-verification-email', { email: userEmail })
      .then(res => {
        this.message = 'RESEND_VERIFICATION_EMAIL_SUCCESS'
      })
      .catch(err => {
        this.message = 'RESEND_VERIFICATION_EMAIL_ERROR'
        this.error = extractError(err)
      })
      .finally(() => {
        this.mailSent = true
        this.loading = false
      })
  }
  closeModal = () => {
    this.message = ''
    this.mailSent = false
  }
  updateContractQuery (val) {
    this.contractQuery = val
    this.page = 1
    this.debouncedGetAll()
  }

  updateQuery (val) {
    this.query = val
    this.page = 1
    this.debouncedGetAll()
  }

  updateActiveFilter () {
    if (this.activeFilter === 2) {
      this.activeFilter = 0
    } else {
      this.activeFilter += 1
    }
    this.page = 1
    this.debouncedGetAll()
  }

  updateQueryOrdering (val) {
    this.orderBy = val
    this.order = this.order === 'DESC' ? 'ASC' : 'DESC'
    this.debouncedGetAll()
  }

  onPageChange (page) {
    this.page = page
    this.getAll()
  }

  async resetLoyaltyRegistration (user) {
    this.loading = true
    return await api.post('/api/loyalty/reset', {
      serialNumber: user.loyaltySerialNumber,
      userId: user.id,
    }).catch(err => {
      this.error = extractError(err)
    })
    .finally(() => {
      this.loading = false
    })
  }

  async resetRefreshToken(userId) {
    this.loading = true
    return await api.get(`/api/auth/refresh-token/${userId}`).catch(err => {
      this.error = extractError(err)
    })
    .finally(() => {
      this.loading = false
    })

  }

  async editContractData(data) {
    this.loading = true
    const {contractNumber, contactId, appId, loyaltySerialNumber, registeredToLoyalty} = data
    return await api.post('/api/contract/admin/edit-contract', {
      contractNumber,
      contactId,
      appId,
      loyaltySerialNumber,
      registeredToLoyalty
    })
    .catch(err => {
      this.error = extractError(err)
    })
    .finally(() => {
      this.loading = false
    })
  }

  async onLoginAs(userRefreshToken) {
    this.loading = true
    return await api.post('/api/auth/refresh-token', {
      refreshToken: userRefreshToken
    }).then((result) => {
      const { token, refreshToken } = result.data
      TokenService.create({ token, refreshToken })
    }).catch(err => {
      this.error = extractError(err)
    })
    .finally(() => {
      this.loading = false
    })
  }
}
export default new UserListStore()
