import { api } from '../service/api.service'
import { extractError } from '../service/response.service'
import t from '../service/translate.service'

import {
  buildValidators,
  required
} from '../service/validate'
import set from 'lodash/set'

const { extendObservable } = require('mobx')

const FAQ_FEEDBACK_ENDPOINT = '/api/faq-feedback'
class FaqFeedbackStore {
  constructor () {
    extendObservable(this, {
      model: {
        feedback: ''
      },
      showThankYouPage: false,
      submitted: false,
      error: false,
      errorMsg: ''
    })
  }

  get validators () {
    return buildValidators(
      {
        feedback: [required]
      },
      this.submitted
    )
  }

  setVal = (field, val) => set(this.model, field, val)

  resetForm () {
    this.model.feedback = ''
    this.submitted = false
    this.showThankYouPage = false
  }
  submit () {
    this.submitted = true
   
    const isValid = !this.validators.feedback(this.model.feedback)
    const sanitizedInput = this.model.feedback.replace(/[^0-9a-zA-Z-"]/g," ")
    if (!isValid) return
    return api
      .post(FAQ_FEEDBACK_ENDPOINT, { feedback: sanitizedInput})
      .then(_res => this.showThankYouPage = true)
      .catch((err) => {
        if(t[extractError(err)]) {
          this.errorMsg = t[extractError(err)]
        } else this.errorMsg = t.RESEND_VERIFICATION_EMAIL_ERROR
        this.error = true
        throw new Error(extractError(err))})
  }
}

export default FaqFeedbackStore
