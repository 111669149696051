import React from 'react'
import { PropTypes } from 'prop-types'

const PrimaryTitle = props => {
  return (
    <div className='text-center'>
      <div className='title-primary-wrapper'>
        <span className='title primary'>{props.title}</span>
      </div>
    </div>
  )
}

PrimaryTitle.propTypes = {
  title: PropTypes.string
}

export default PrimaryTitle
