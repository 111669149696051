import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import t from '../service/translate.service'
import PrimaryTitle from '../components/PrimaryTitle'
import User from '../store/user.store'
import PropTypes from 'prop-types'
import withRouter from '../components/withRouter'
import TenantServicesList from '../components/TenantServicesList'

class TenantServices extends Component {
  onClick = (link) => {
    this.props.menuStore.setCurrentPage(link)
    this.props.navigate(link)
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    User.getProfile()
  }

  render() {
    return (
      <div>
        <PrimaryTitle title={t.TENANT_SERVICES} />

        <TenantServicesList
          menuStore={this.props.menuStore}
          receipts={this.props.receiptsStore.receipts}
          onClick={this.onClick}
          User={User}
        />

      </div>
    )
  }
}
TenantServices.propTypes = {
  menuStore: PropTypes.object.isRequired,
  receiptsStore: PropTypes.object.isRequired,
}
export default inject('menuStore', 'receiptsStore')(withRouter(observer(TenantServices)))
