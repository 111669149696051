module.exports = {
  FEEDBACK_TYPE: {
    POSITIVE: 'positive',
    NEGATIVE: 'negative'
  },
  REQUEST_CATEGORY: {
    AGREEMENT: 'agreement',
    GENERAL: 'general',
    LEASE: 'lease',
    DEPOSIT: 'deposit',
    OCC: 'occ',
    PUBLIC_AREA: 'public_area',
    MAINTENANCE: 'maintenance',
    RENT_INCREASE: 'rent_increase'
  },
  DAYS_OF_WEEK: {
    'monday': 1,
    'tuesday': 2,
    'wednesday': 3,
    'thursday': 4,
    'friday': 5,
    'saturday': 6,
    'sunday': 7
  },
  ENGLISH_WEEKDAYS: {
    'Montag': 'Monday',
    'Dienstag': 'Tuesday',
    'Mittwoch': 'Wednesday',
    'Donnerstag': 'Thursday',
    'Freitag': 'Friday',
    'Samstag': 'Saturday',
    'Sonntag': 'Sunday'
  },
  FREQUENCY_MONTH_WEEK_MAP: {
    'weekone': [1],
    'weektwo': [2],
    'weekthree': [3],
    'weekfour': [4]
  },
  GCP_WEB_URL_CONTACT: 'https://www.grandcityproperty.de/kontakt#kontaktformular',
  GCP_WEB_STRING: 'www.grandcityproperty.de',
  CONSULTATION_EMAIL: 'termin@grandcityproperty.de',
  CONSULTATION_PHONE: '030 346 55 777',
  PRIVACY_POLICY_URL: 'https://www.grandcityproperty.de/datenschutz',
  IMPRINT_URL: 'https://www.grandcityproperty.de/impressum',
  PARTNERS_UPLOAD_DIR: '/public/partner_uploads/',
  FAQ_QUESTIONS_UPLOAD_DIR: '/public/faq_uploads/',
  USER_FILE_UPLOAD_DIR: '/public/user_uploads/',
  FRESHCHAT: {
    MESSAGE_TYPE: {
      NORMAL: 'normal',
      PRIVATE: 'private',
      SYSTEM: 'system'
    },
    ACTOR_TYPE: {
      USER: 'user',
      AGENT: 'agent'
    }
  },
  DAMAGE_REPORT_URGENCY: {
    NO: '0',
    HIGH: '3'
  },
  AIMS_REPAIR_AND_INQUIRY_STATUS: {
    NEW: 'new',
    IN_PROCESS: 'in_process',
    CLOSED: 'closed'
  },
  CONSULTATION_FREQUENCIES: {
    NONE: '',
    WEEKLY: 'weekly',
    BIWEEKLY: 'biweekly',
    WEEKONE: 'weekone',
    WEEKTWO: 'weektwo',
    WEEKTHREE: 'weekthree',
    WEEKFOUR: 'weekfour',
    EVEN: 'even',
    ODD: 'odd'
  },
  LOYALTY_ACCOUNT_LINES_TEXT_TRANSLATIONS: [
    {
      en: 'Bonus points as goodwill opportunity',
      de: 'Kulanz-Bonuspunkte'
    },
    {
      en: 'Participation in loyalty events',
      de: 'Teilnahme an Treueaktionen'
    },
    {
      en: 'Raffle most beautiful balcony',
      de: 'Auslosung des am schönsten hergerichteten Balkons'
    },
    {
      en: "Raffle at tenants' festivals",
      de: 'Verlosung aus Mieterveranstaltungen'
    },
    {
      en: 'Participation in tenant course',
      de: 'Teilnahme an Mieterkursen'
    },
    {
      en: 'For additional Services from our partners',
      de: 'Für zzgl. Serviceleistungen unserer Partnerunternehmen'
    },
    {
      en: 'For having insurances',
      de: 'Für abgeschlossene Versicherungen'
    },
    {
      en: 'Tenants advertise tenants',
      de: 'Mieter werben Mieter'
    },
    {
      en: 'Registered with New Contract', // Replaced with Signed up with a new lease agreement
      de: 'Mit neuem Mietvertrag registriert'
    },
    {
      en: 'Registered with Existing Contract', // Replaced with Points for registration
      de: 'Mit bestehendem Mietvertrag registriert'
    },
    {
      en: 'Signed up with a new rent contract',
      de: 'Mit einem neuen Mietvertrag registriert'
    },
    {
      en: 'Signed up with a new lease agreement',
      de: 'Mit einem neuen Mietvertrag registriert'
    },
    {
      en: 'Points for registration',
      de: 'Registrierung im Treueprogramm'
    },
    {
      en: 'Punctual and correct rent payment',
      de: 'Pünktliche und korrekte Mietzahlung'
    },
    {
      en: 'Service charge statement on time',
      de: 'Pünktliche Zahlung der Nebenkostenabrechnung'
    },
    {
      en: 'Rent contract anniversary',
      de: 'Mietvertragsjährung'
    },
    {
      en: 'Participated in survey',
      de: 'Teilnahme an Umfragen'
    },
    {
      en: 'For having insurances',
      de: 'Hausratsversicherungsnachweis'
    },
    {
      en: 'Received proof of eco electricity certificate',
      de: 'Ökostromzertifikatsnachweis'
    },
    {
      en: 'Signed up with a new rent contract',
      de: 'Mit einem neuen Mietvertrag registriert'
    }
  ],
  CHRISTMAS_GREETING_URL: 'https://www.grandcityproperty.de/weihnachten',
  NUMBER_OF_ROOMS_SELECTION: {
    '1-2': '1-2',
    '2-3': '2-3',
    '3-4': '3-4',
    '4-5': '4-5',
    '5-6': '5+'
  },
  SIZE_SQUARE_METER_SELECTION: {
    '0': '0-30',
    '1': '31-50',
    '2': '51-80',
    '3': '81-100',
    '4': '101-200',
    '5': '201+'
  },
  MIN_DESKTOP_SIZE: 992,
  USER_FILE_TYPES: {
    CERTIFICATE: 'CERTIFICATE',
    PROOF: 'PROOF'
  }
}
