import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import { find as findAddress } from '../store/address.store'
import t from '../service/translate.service'
import { CustomSelect } from './formComponents'
import find from 'lodash/find'
import PropTypes from 'prop-types'

class AddressSelect extends Component {
  constructor (props) {
    super(props)
    this.lastFetchId = 0
    this.getAddress = debounce(this.getAddress, 300)
  }

  state = {
    data: [],
    value: '',
    remapedAddresses: [],
    selectedAddress: null,
    inputValue: ''
  } 
  componentDidMount() {
    if(this.props.value) {
      this.setState({selectedAddress:{label: this.props.value, value: this.props.value}})
    }
  }

  renderAddress = () => {
    if(this.props.value) this.setState({selectedAddress:{label: this.props.value, value: this.props.value}})
  }

  fetchAddress = (value, { action }) => {
    if (action === 'input-change') {
      this.setState({
        inputValue: value
      })
      this.props.onChange('')

      if (value.length) {
        this.getAddress(value)
      } else {
        this.setState({
          data: [],
          remapedAddresses: [],
          inputValue: ''
        })
      }
    }
  }
  getAddress = (value) => {
    findAddress(value)
      .then((addresses) => {
        const remapedAddresses = addresses.map(address => {
          return {
            label: address,
            value: address
          }
        })

        this.setState({
          data: addresses,
          remapedAddresses
        })
      })
  }

  handleChange = (value) => {
    this.address = find(this.state.data, i => i === value)
    const [selectedAddress] = getSelectedAddress(this.state.remapedAddresses, value)

    this.setState({
      value: selectedAddress,
      selectedAddress,
      inputValue: this.address
    })

    this.props.onChange(this.address)
  }
  render () {
    /* inputValue property unpack from next line */
    const { remapedAddresses, selectedAddress } = this.state
    const { label, validate } = this.props

    return (
      <CustomSelect
        value={selectedAddress || null}
        label={label}
        options={remapedAddresses}
        onChange={selectedAddress => this.handleChange(selectedAddress.value)}
        // inputValue={inputValue || ''}
        onInputChange={this.fetchAddress}
        placeholder={t.FORM_ADDRESS_SELECT_PLACEHOLDER(label)}
        isSearchable
        validate={validate}
      />
    )
  }
}
function getSelectedAddress (array, value) {
  return array.filter(selectedAddress => selectedAddress.value === value)
}
AddressSelect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default AddressSelect
