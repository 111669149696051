import React, {Component} from "react";
import {extendObservable} from "mobx";
import t from "../../service/translate.service";
import PropTypes from "prop-types";
import {Button, Form} from "react-bootstrap";
import {observer} from "mobx-react";
import {api, API_BASE} from "../../service/api.service";
import ThankYouOverlay from "../../components/ThankYouOverlay";

class ReceiptInspectionForm extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      opened: false,
      sending: false,
      sent: false,
      type: null,
    })
  }

  showForm = () => {
    this.opened = true;
  }

  selectType = type => {
    this.type = type;
  }

  closeForm = () => {
    this.opened = false;
    this.type = null;
    this.sent = false;
  }

  submitForm = async (data) => {
    this.sending = true;
    await api.post(`${API_BASE}/api/receipts/complaint`, {
      message: data.message,
      type: this.type,
      BK_Jahr: this.props.contractData.BK_Jahr,
      caseNumber: this.props.contractData.caseNumber,
      contractNumber: this.props.contractData.contractNumber
    });
    this.sent = true;
    this.sending = false;
  }

  componentWillUnmount() {
    this.closeForm();
  }

  render() {
    if (!this.opened) {
      return (
        <div className='text-center'>
          <button
            className='primary button'
            style={{marginBottom: '30px'}}
            onClick={() => this.showForm()}>{t.CONTACT}</button>
        </div>
      )
    } else {
      return (
        <div className='thank-you-overlay'>
          <button className='close-preview' onClick={() => this.closeForm()}>x</button>
          <div className='wrapper'>
            {!this.type && <TypeSelector onSelect={(type) => this.selectType(type)}/>}
            {this.type &&
              <ContactForm submitting={this.sending} submitted={this.sent} onSubmit={(data) => this.submitForm(data)}/>}
          </div>
        </div>
      )
    }
  }
}

const TypeSelector = (props) => {
  const {
    onSelect
  } = props;

  const types = [
    {label: t.CONTACT_OC, id: 'betriebskostenabteilung_kontaktieren'},
    {label: t.CONTACT_TECHNICAL, id: 'technisches_problem_melden'},
  ];

  return (
    <div>
      {
        types.map((type) => {
          return (
            <div className='text-center' key={type.id}>
              <button
                className='primary button'
                style={{marginBottom: '30px'}}
                onClick={() => onSelect(type.id)}>{type.label}</button>
            </div>
          )
        })
      }
    </div>
  )
}

const ContactForm = observer(({onSubmit, submitting, submitted}) => {
  const formState = React.useState(() => extendObservable({}, {
    message: ''
  }))[0];

  const onMessageChange = (e) => {
    formState.message = e.target.value;
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    onSubmit({message: formState.message});
  }

  if (!submitted) {
    return (
      <Form onSubmit={onSubmitForm} className='w-100'>
        <Form.Group controlId="message">
          <Form.Label className='form-label'>{t.YOUR_MESSAGE}</Form.Label>
          <Form.Control required as="textarea" rows={8} onChange={(e) => onMessageChange(e)}/>
        </Form.Group>

        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button type='submit' disabled={!formState.message} className='button primary'>{t.ABSENDEN}</Button>
          </div>
        </Form.Group>
      </Form>
    )
  } else {
    return (
      <ThankYouOverlay buttonText={t.BACK_HOME} buttonLink='/' description={t.OC_COMPLAINT_THANKS} title={t.THANK_YOU}/>
    )
  }
})

TypeSelector.propTypes = {
  onSelect: PropTypes.func
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  submitted: PropTypes.bool,
}

export default observer(ReceiptInspectionForm)