import React, { Component } from 'react'
import PrimaryTitle from '../components/PrimaryTitle'
import history from '../service/history.service'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import withRouter from '../components/withRouter'

class ServerErrorPage extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props)
  }
  goBack = (e) => {
    e.preventDefault()
    this.props.navigate(-2)
  }

  render() {
    const error = this.props.location.state
    return (
      <div className='thank-you-overlay'>
        <div className='wrapper'>
          <PrimaryTitle title={error.name} />
          <div className='description'>{localStorage.getItem('locale') === 'de' ? error.message : 'Anfrage fehlgeschlagen mit Statuscode 500'}</div>
          <Button
            className='button primary'
            onClick={this.goBack}
          >
            {'OK'}
          </Button>
        </div>
      </div>
    )
  }
}

export default (withRouter(ServerErrorPage))
