import strings from '../const/strings'
import { extendObservable } from 'mobx'
import each from 'lodash/each'

class Translate {
  constructor () {
    this.locale = localStorage.getItem('locale') || 'de'
    extendObservable(this, this.buildTranslations())
  }

  setLocale (locale) {
    localStorage.setItem('locale', locale)
    window.location.reload()
  }

  buildTranslations () {
    const t = {}
    each(strings, (v, k) => {
      t[k] = v[this.locale] || k
    })
    return t
  }
}

export default new Translate()
