import React from 'react'
import PropTypes from 'prop-types'

const ListSavedFields = props => {
  const { fields, textCenter } = props
  const centerCss = textCenter ? 'text-center' : ''
  return (
    <div className={`list-saved-fields ${centerCss}`}>
      {fields.map(field => {
        if (field.value) {
          return (
            <div key={field.label} className='item'>
              <div className='container'>
                <label>{field.label}</label>
                <p>{field.value}</p>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

ListSavedFields.propTypes = {
  fields: PropTypes.array,
  textCenter: PropTypes.bool
}

export default ListSavedFields
