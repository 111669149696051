import React, {Component} from 'react'
import {api, API_BASE} from "../../service/api.service";
import {extendObservable} from "mobx";
import withRouter from "../../components/withRouter";
import LoadingPageOverlay from "../../components/LoadingPageOverlay";
import {observer} from "mobx-react";
import { Alert } from 'antd';
import t from "../../service/translate.service";

class OcCalculationsPreview extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      loading: false,
      error: false,
      document: null,
    })
  }

  componentDidMount = () => {
    window.scrollTo(0, 0)
    this.setDocument();
  }

  async getDocument(contractNumber, docId) {
    try {
      this.error = false
      const res = await api.get(`${API_BASE}/api/contract/${contractNumber}/documents/ZOC_CALCULATION/${docId}`, {
        responseType: 'blob',
      })
      return res.data
    } catch (err) {
      this.error = true
    }
  }

  setDocument = async () => {
    this.loading = true;
    const { contractNumber, docId } = this.props.useParams;
    this.document = await this.getDocument(contractNumber, docId);
    this.loading = false;
  }

  render() {
    if (this.loading) {
      return (<LoadingPageOverlay/>)
    }
    return (
      <div>
        {this.error && <Alert message={t.SOMETHING_WENT_WRONG} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
        <div className='position-relative' style={{'min-height': '500px'}}>
          <div className='document-preview'>
            <div className='text-center'>
              {this.document && <iframe src={URL.createObjectURL(this.document)} toolbar='false'/>}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(OcCalculationsPreview))