import t from "../../../../service/translate.service";

export const topics = [
  {
    value: t.CONSULTATIONS_TOPIC_RENT_CONTRACT,
    label: t.CONSULTATIONS_TOPIC_RENT_CONTRACT
  },
  {
    value: t.CONSULTATIONS_TOPIC_REPAIRS,
    label: t.CONSULTATIONS_TOPIC_REPAIRS
  },
  {
    value: t.CONSULTATIONS_TOPIC_OUTDOOR,
    label: t.CONSULTATIONS_TOPIC_OUTDOOR
  },
  {
    value: t.CONSULTATIONS_TOPIC_CLEANING,
    label: t.CONSULTATIONS_TOPIC_CLEANING
  },
  {
    value: t.CONSULTATIONS_TOPIC_RENT_DEBTS,
    label: t.CONSULTATIONS_TOPIC_RENT_DEBTS
  },
  {
    value: t.CONSULTATIONS_TOPIC_RENT_ADJUSTMENTS,
    label: t.CONSULTATIONS_TOPIC_RENT_ADJUSTMENTS
  },
  {
    value: t.CONSULTATIONS_TOPIC_MOVE,
    label: t.CONSULTATIONS_TOPIC_MOVE
  },
  {
    value: t.CONSULTATIONS_TOPIC_OC,
    label: t.CONSULTATIONS_TOPIC_OC
  },
  {
    value: t.CONSULTATIONS_TOPIC_OTHER,
    label: t.CONSULTATIONS_TOPIC_OTHER
  }
]