import React from 'react'
import PrimaryTitle from '../components/PrimaryTitle'
import fiumuLogo from '../assets/imprint/fiumu.png'
import freepikLogo from '../assets/imprint/freepik.png'
import awinLogo from '../assets/imprint/awin.png'
import communicationAdsLogo from '../assets/imprint/communicationAds.png'
import mivoLogo from '../assets/imprint/mivo.png'
import t from '../service/translate.service'
import { PropTypes } from 'prop-types'

import { PRIVACY_POLICY_URL } from '../const'

const ImprintPage = () => {
  const imageRightsReferences = [
    {
      id: 1,
      image: fiumuLogo,
      title: 'FIUMU GmbH',
      text: ['Schmiedestrasse 6', '57076 Siegen'],
      details: [
        {
          label: t['USER_EMAIL'],
          value: 'hello@fiumu.de'
        },
        {
          label: 'Web',
          value: 'www.fiumu.de'
        }
      ]
    },
    {
      id: 2,
      image: freepikLogo,
      title: 'Freepik Company, S.L.',
      text: ['13 Molina Lario St., 5th floor', '29015 Málaga'],
      details: [
        {
          label: t['USER_EMAIL'],
          value: 'info@freepik-mail.com'
        },
        {
          label: 'Web',
          value: 'www.freepik.com'
        }
      ]
    },
    {
      id: 3,
      image: awinLogo,
      title: 'AWIN AG',
      text: ['Eichhornstraße 3', '10785 Berlin'],
      details: [
        {
          label: t['USER_EMAIL'],
          value: 'dach-service@awin.com'
        },
        {
          label: 'Web',
          value: 'www.awin.com'
        }
      ]
    },
    {
      id: 4,
      image: communicationAdsLogo,
      title: 'communicationAds GmbH & Co. KG',
      text: ['Karlstraße 9', '90403 Nürnberg'],
      details: [
        {
          label: t['USER_EMAIL'],
          value: 'service.de@communicationads.net'
        },
        {
          label: 'Web',
          value: 'www.communicationads.net'
        }
      ]
    },
    {
      id: 5,
      image: mivoLogo,
      title: 'MIVO mitarbeitervorteile GmbH',
      text: ['Theodor-Heuss-Allee 74', '60486 Frankfurt'],
      details: [
        {
          label: t['USER_EMAIL'],
          value: 'service@mitarbeitervorteile.de'
        },
        {
          label: 'Web',
          value: 'www.mitarbeitervorteile.de'
        }
      ]
    }
  ]
  return (
    <div className='imprint-page'>
      <PrimaryTitle title={t['IMPRINT']} />

      <Section
        title={t['IMPRINT_PAGE_BRANCH_OFFICE']}
        textRows={[
          'Grand City Property Ltd - Zweigniederlassung Deutschland',
          'Wittestraße 30, Haus F',
          '13509 Berlin'
        ]}
      />

      <Section textRows={['HRB 148650 B Amtsgericht Charlottenburg']} />

      <Section
        title={t['IMPRINT_PAGE_BRANCH_MANAGER']}
        textRows={['David Fischer']}
      />

      <Section textRows={[t['IMPRINT_PAGE_THIS_APP_IS_HOSTED']]} />

      <Section
        title={t['IMPRINT_PAGE_HEADQUARTERS']}
        textRows={[
          'Grandcity Property Ltd',
          'Larnaca, Cyprus',
          'Trade register of Nikosia',
          'HE 176494'
        ]}
      />

      <Section
        title={t['IMPRINT_PAGE_CONTACT']}
        textRows={['David Fischer']}
        details={[
          { label: t['USER_PHONE'], value: '030 346 55 777' },
          { label: t['USER_EMAIL'], value: 'post@grandcityproperty.de' }
        ]}
      />

      <Section
        title={t['IMPRINT_PAGE_EDITORIAL_CONTENT']}
        textRows={[
          'Grand City Property Ltd. Zweigniederlassung Deutschland',
          'PR & Marketing',
          'Wittestraße 30, Haus F',
          '13509 Berlin'
        ]}
      />

      <Section
        title={t['IMPRINT_PAGE_IMAGE_RIGHTS']}
        textRows={[t['IMPRINT_PAGE_IMAGE_RIGHTS_TEXT']]}
      />

      <div className='section image-rights-references'>
        {imageRightsReferences.map(reference => (
          <div key={reference.id} className='item'>
            <div className='container'>
              <div className='image'>
                <img src={reference.image} />
              </div>
              <Section
                title={reference.title}
                textRows={reference.text}
                details={reference.details}
              />
            </div>
          </div>
        ))}
      </div>

      <Section
        title={t['IMPRINT_PAGE_TECHNICAL_SUPPORT']}
        textRows={['KANBAN LLC.', 'Fallerovo šetalište 22', 'HR10000 Zagreb, Croatia']}
        details={[
          { label: t['USER_EMAIL'], value: 'hello@knb.io' },
          { label: 'Web', value: 'www.knb.io' }
        ]}
      />

      <div className='section'>
        <div className='title'>{t['IMPRINT_PAGE_DATA_PROTECTION_DECLARATION']}</div>
        <div className='text'>
          <p>
            {t['IMPRINT_PAGE_DATA_PROTECTION_DECLARATION_TEXT']}
            <span><a href={PRIVACY_POLICY_URL}>{t['IMPRINT_PAGE_DATA_PROTECTION_DECLARATION_LINK']}</a>.</span>
          </p>
        </div>
      </div>
    </div>
  )
}

const Section = ({ title, textRows, details }) => {
  return (
    <div className='section'>
      {title && <div className='title'>{title}</div>}

      <div className='text'>
        {textRows.map((row, index) => (
          <p key={index}>{row}</p>
        ))}
      </div>

      {details && (
        <div className='details'>
          {details.map((detail, index) => (
            <div key={index}>
              {detail.label}: {detail.value}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
Section.propTypes = {
  title: PropTypes.string,
  textRows: PropTypes.array,
  details: PropTypes.array
}
export default ImprintPage
