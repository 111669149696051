import React, { Component } from 'react'
import propTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import t from '../../service/translate.service'
import map from 'lodash/map'
import withRouter from '../../components/withRouter'
import LoadingPageOverlay from '../../components/LoadingPageOverlay'
import moment from 'moment'
import { Alert } from 'antd'
import { extendObservable } from 'mobx'


class RentAccountHistory extends Component {
  static propTypes = {
    contractStore: propTypes.object.isRequired
  }
  constructor(props) {
    super(props)
    extendObservable(this, {
      contract: '',
      loading: false
    });
  }

  componentDidMount() {
    this.loading = true
    const id = this.props.useParams.contractNumber
    const selectedContract = this.props.contractStore.activeContracts.find(
      (c) => c.contractNumber === id
    );
    if (!selectedContract.rentAccountBalance) {
      this.props.contractStore.fetchAccountBalance(selectedContract);
    }
    this.contract = selectedContract;
    this.props.contractStore.getContractDetails(selectedContract.contractNumber)
    this.props.contractStore.fetchAccountHistory(id)
    this.loading = false
  }

  render() {
    if (this.props.contractStore.loading || this.loading) {
      return <LoadingPageOverlay />
    }
    const accountHistoryError = this?.props?.contractStore?.accountHistoryError
    return (
      <div>
        {accountHistoryError && (
          <Alert message={accountHistoryError} type='error' style={{marginBottom: '12px', marginTop: '12px'}} />
        )}
        {(this.props.contractStore.selectedRentAccContract && this.contract.rentAccountBalance) ?
          (
            <div className='points-list balanceMessage'>
              <b style={{fontSize: '21px'}}>
                {`${
                  t.CONTRACT_RENT_ACCOUNT_BALANCE
                }: `} 
                <span style={{fontSize: '21px'}} className={`${this.contract.rentAccountBalance.toString().includes('-') ? 'redBalance' : 'greenBalance'}`}>{`${this.contract.rentAccountBalance
                  .toLocaleString("de-DE", {
                    minimumFractionDigits: 2,
                  })
                  .replace("-", "")}€`}
                  {this.contract.rentAccountBalance.toString().includes("-")
                    ? ` ${t.RENT_ACCOUNT_RESIDUE}`
                    : ` ${t.RENT_ACCOUNT_CREDIT}`}
                </span>
              </b>
              
              <p style={{marginTop: '20px'}}>{t.RENT_ACCOUNT_MESSAGE}</p>
              <div>
                {map(this.props.contractStore.selectedRentAccContract, (c, index) => {
                  return (
                    <div key={index} className='points-card pointsWrapper'>
                      <div>
                        <p className='date balanceCategory balanceDate'>{c.date ? moment(c.date).format('DD.MM.yyyy') : ''}</p>
                        <p className='date balanceCategory'> {c.categoryToDisplay || c.description}</p>
                      </div>
                      <div className='points'>
                        {c.dateDunned && 
                        <span style={{marginRight: 20}}>{t.RENT_ACCOUNT_DETAILS_CLARIFICATION}</span> 
                        }
                      <p style={{width: '100px'}}  className={` mb-0 ${c.amount.toString().includes('-') ? 'redBalance' : 'greenBalance'}`}>{c.amount.toLocaleString('de-DE', {
                              minimumFractionDigits: 2,
                            }).replace('-', '')}€ {c.amount.toString().includes('-') ? t.RENT_ACCOUNT_DETAILS_RESIDUE : t.RENT_ACCOUNT_DETAILS_CREDIT }</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
          :
          (<div></div>)}
      </div>
    )
  }


}

export default inject('contractStore')(withRouter(observer(RentAccountHistory)))