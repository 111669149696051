import { api } from '../service/api.service'
import { extractError } from '../service/response.service'
import history from '../service/history.service'
import { extendObservable } from 'mobx'
import UserStore from '../store/user.store'
import {
  buildValidators,
  email,
  requiredName,
  requiredLastName,
  minLength,
  maxLength,
  phone,
  requiredCheckbox,
  password,
  emptyPass,
  passwordValidation,
  checkPasswordResponse
} from '../service/validate'
import find from 'lodash/find'

class RegistrationStore {
  constructor() {
    extendObservable(this, {
      model: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        preferredLanguage: '',
        terms: false,
        subscribe: false
      },
      submitErr: null,
      passwordErr: null,
      submitted: false,
      loader: false,
      invalidEmails: []
    })
  }

  get validators() {
    return buildValidators(
      {
        firstName: [requiredName, minLength(1), maxLength(100)],
        lastName: [requiredLastName, minLength(1), maxLength(100)],
        email: [email],
        phone: [phone],
        password: [
          emptyPass,
          minLength(10),
          maxLength(50),
          passwordValidation,
          password(this.model.confirmPassword)
        ],
        terms: [requiredCheckbox]
      },
      this.submitted
    )
  }

  setVal = (field, val) => {
    this.model[field] = val
  }

  submit = async () => {
    try {
      this.submitted = true
      this.submitErr = null
      const isValid = !find(this.validators, (validate, field) =>
        validate(this.model[field])
      )
      if (!isValid || !this.model.terms) throw new Error('Not valid')
  
      this.loader = true
      var data = this.model
      return api
        .post('/api/auth/register', { ...data })
        .then(response => {
          UserStore.userEmail = response.data.email
          UserStore.loginError = null
          UserStore.mailError = null
        })
        .catch(err => {
          this.loader = false
          const errMsg = extractError(
            err,
            'Error occured when trying to sign in.'
          )
          this.passwordErr = checkPasswordResponse(errMsg)
          this.submitErr = errMsg
          window.scrollTo(0, 0)
          throw err
        })
    } catch (err) {
      throw err
    }
  }
}

export default RegistrationStore
