import React from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, DateInput, Textarea } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const DataRelease = (props) => {
  const { model, setVal, dataReleaseValidators } = DocumentStore
  const { file } = props
  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: dataReleaseValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.DATA_RELEASE} />
      <Form onSubmit={onSubmit}>
        <ContractSelector label padding page='documents' />
        <h3 className='inputs-text'>{t.DATA_RELEASE_HEAD_ONE}</h3>
        <Input name='fullName' {...getAttrs('fullName')} label={t.DIGITAL_CONTRACT_FORM_FULL_NAME + '*'} />
        <Input name='dataReleaseFileNumber' {...getAttrs('dataReleaseFileNumber')} label={t.DIGITAL_CONTRACT_FORM_FILE_NUMBER + '*'} />
        <Textarea name='dataReleaseResponsibleOffice' {...getAttrs('dataReleaseResponsibleOffice')} label={t.DIGITAL_CONTRACT_FORM_RESPONSIBLE_OFFICE + '*'} />
        <Input name='dataReleaseContactPerson' {...getAttrs('dataReleaseContactPerson')} label={t.DIGITAL_CONTRACT_FORM_CONTACT_PERSON + '*'} />
        <p className='input-para'>{t.DATA_RELEASE_PARA_ONE}</p>
        <p className='input-para'>{t.DATA_RELEASE_PARA_TWO}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={dataReleaseValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button
              type='submit'
              className='button primary'
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </Button>
            <Button
              type='button'
              className='button secondary'
              onClick={() => {
                DocumentStore.onCloseForm()
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

DataRelease.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(DataRelease))
