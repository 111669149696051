import React from 'react'
import PrimaryTitle from '../../components/PrimaryTitle'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import { Link } from 'react-router-dom'

const ErrorOverlay = ({ buttonLink, handleClick, message }) => {
  return (
    <div className='thank-you-overlay'>
      <div className='wrapper'>
        <PrimaryTitle title={t.CONTRACT_VERIFICATION_ERROR} />
        <div className='description'>{message? message : t.ERROR}</div>
        {buttonLink && <Link to={buttonLink} className='button primary' onClick={() => handleClick()}>Ok</Link>}
      </div>
    </div>
  )
}
ErrorOverlay.propTypes = {
  buttonLink: PropTypes.string,
  handleClick: PropTypes.func,
  message: PropTypes.string
}
export default ErrorOverlay
