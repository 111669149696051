import React from 'react'
import loaderGif from '../assets/animated-loader-v5-300.gif'

const LoadingPageOverlay = () => {
  return (
    <div className='loading-page-overlay'>
      <div className='gif'>
        <img src={loaderGif} />
      </div>
    </div>
  )
}

export default LoadingPageOverlay
