import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Input, Switch, Radio } from 'antd'
import appSettingsStore from '../../../store/admin/AppSettings.store'
import { Navigate } from 'react-router-dom'

class AppSettingsForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    appSettingsStore.save()
  }

  onClone (e) {
    e.preventDefault()
    appSettingsStore.clone()
  }

  render () {
    const { model } = appSettingsStore
    if (!model.type) {
      appSettingsStore.setVal('type', 'string')
    }
    if(appSettingsStore.formSubmittedSuccessfully) {
      appSettingsStore.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/app-settings/' }} />
    }
    return (
      !appSettingsStore.loading && (
        <div>
          <h1>App Settings</h1>
          <form onSubmit={this.onSubmit}>
            <div className='input-div'>
              <label>Name *</label>
              <Input
                value={model.name}
                type='input'
                required
                onChange={e => appSettingsStore.setVal('name', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Type *</label>
              <Radio
                name="type"
                value='string'
                checked={model.type === 'string'}
                onChange={e => {
                  appSettingsStore.setVal('type', e.target.value)
                  appSettingsStore.setVal('stringValue', e.target.value === 'string' && model.oldType === 'string' ? model.value : '')
                }}
              >
                String
              </Radio>
              <Radio 
                name="type"
                value='number'
                checked={model.type === 'number'}
                onChange={e => {
                  appSettingsStore.setVal('type', e.target.value)
                  appSettingsStore.setVal('numberValue', e.target.value === 'number' && model.oldType === 'number' ? model.value : '')
                }}
              >
                Number
              </Radio>
              <Radio 
                name="type"
                value='boolean'
                checked={model.type === 'boolean'}
                onChange={e => {
                  appSettingsStore.setVal('type', e.target.value)
                  appSettingsStore.setVal('booleanValue', e.target.value === 'boolean' ? model.value || 'false' : 'false')
                }}
              >
                Boolean
              </Radio>
            </div>
            {model.type === 'string' && (
              <div className='input-div'>
                <label>Value *</label>
                <Input
                  value={model.stringValue}
                  type='input'
                  required
                  onChange={e => appSettingsStore.setVal('stringValue', e.target.value)}
                />
              </div>
            )}
            {model.type === 'number' && (
              <div className='input-div'>
                <label>Value *</label>
                <Input
                  value={model.numberValue}
                  type='number'
                  required
                  onChange={e => appSettingsStore.setVal('numberValue', e.target.value)}
                />
              </div>
            )}
            {model.type === 'boolean' && (
              <div className='input-div'>
                <label>Value *</label>
                <div style={{width: '100px'}}>
                  <Switch
                    style={{background: model.active ? '#fabb00' : 'rgba(0, 0, 0, 0.25)'}}
                    checked={model.booleanValue === 'true'}
                    onChange={val => appSettingsStore.setVal('booleanValue', val ? 'true' : 'false')}
                  />
                </div>
              </div>
            )}
            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/app-settings'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(AppSettingsForm)
