import React from 'react'

const ServicesIcon = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    width='24pt'
    height='14pt'
    viewBox='0 0 24 14'
  >
    <g enableBackground='new'>
      <clipPath id='svg-tenant-services'>
        <path
          transform='matrix(1,0,0,-1,0,14)'
          d='M 3.117188 4.353125 L 6.5625 4.353125 L 6.5625 9.865625 L 3.117188 9.865625 L 3.117188 4.353125 Z M 4.485934 8.496878 L 5.193753 8.496878 L 5.193753 5.721872 L 4.485934 5.721872 L 4.485934 8.496878 Z M 20.34375 9.865625 L 9.31875 9.865625 L 9.31875 14 L 0 14 L 0 .21875 L 9.31875 .21875 L 9.31875 4.353125 L 12.76406 4.353125 L 13.79766 5.386719 L 14.83125 4.353125 L 16.20938 4.353125 L 17.24297 5.386719 L 18.27656 4.353125 L 20.34375 4.353125 L 23.1 7.109375 L 20.34375 9.865625 Z M 7.940625 1.596875 L 1.378125 1.596875 L 1.378125 12.62187 L 7.940625 12.62187 L 7.940625 1.596875 Z M 19.99922 5.73125 L 18.62109 5.73125 L 17.24297 7.109375 L 15.86484 5.73125 L 15.17578 5.73125 L 13.79766 7.109375 L 12.41953 5.73125 L 9.31875 5.73125 L 9.31875 8.4875 L 19.99922 8.4875 L 21.37734 7.109375 L 19.99922 5.73125 Z '
          fillRule='evenodd'
        />
      </clipPath>
      <g clipPath='url(#svg-tenant-services)'>
        <path
          transform='matrix(1,0,0,-1,0,14)'
          d='M -5 19 L 28.1 19 L 28.1 -4.78125 L -5 -4.78125 Z '
          fill='white'
        />
      </g>
    </g>
  </svg>
)

export default ServicesIcon
