import React, {useEffect} from 'react'
import PrimaryTitle from '../../../../components/PrimaryTitle'
import { inject, observer } from 'mobx-react'
import { Input, DateInput } from '../../../../components/formComponents'
import { Form, Button } from 'react-bootstrap'
import DocumentStore from '../../../../store/documentForm.store'
import PropTypes from 'prop-types'
import t from '../../../../service/translate.service'
import ContractSelector from '../../../../components/contractSelector'

const Deposit = (props) => {
  const { model, setVal, depositOutpaymentValidators } = DocumentStore
  const { file } = props

  const onSubmit = async (e) => {
    e.preventDefault()
    DocumentStore.submit(file.fileName, props.contractStore.selectedContract.contractNumber)
  }
  const getAttrs = field => {
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: depositOutpaymentValidators[field]
    }
  }
  return (
    <div className='form-holder'>
      <PrimaryTitle title={t.DEPOSIT} />
      <Form onSubmit={onSubmit}>
        <ContractSelector label padding page='documents' isDigitalForm={true} loadPrepayments={DocumentStore.loadPrepayments} />
        <h3 className='inputs-text'>{t.DEPOSIT_HEAD_ONE}</h3>
        <Input name='firstName' {...getAttrs('firstName')} label={t.DIGITAL_CONTRACT_FORM_FIRST_NAME + '*'} />
        <Input name='lastName' {...getAttrs('lastName')} label={t.DIGITAL_CONTRACT_FORM_LAST_NAME + '*'} />
        <Input name='depositCurrentAddress' {...getAttrs('depositCurrentAddress')} label={t.DIGITAL_CONTRACT_FORM_CURRENT_ADDRESS + '*'} />
        <Input name='phone' {...getAttrs('phone')} label={t.DIGITAL_CONTRACT_FORM_PHONE_NUMBER + '*'} />
        <h3 className='inputs-text'>{t.DEPOSIT_HEAD_TWO}</h3>
        <Input name='address' {...getAttrs('address')} label={t.DIGITAL_CONTRACT_FORM_APARTMENT_ADDRESS + '*'} />
        <Input name='tenantNo' {...getAttrs('tenantNo')} label={t.DIGITAL_CONTRACT_FORM_TENANT_NUMBER + '*'} disabled />
        <DateInput name='depositTerminationDate' value={model['depositTerminationDate']} onChange={(date) => setVal('depositTerminationDate', date)} validate={depositOutpaymentValidators['depositTerminationDate']} label={t.DIGITAL_CONTRACT_FORM_TERMINATION_DATE + '*'} />
        <DateInput name='depositApartmentHandoverDate' value={model['depositApartmentHandoverDate']} onChange={(date) => setVal('depositApartmentHandoverDate', date)} validate={depositOutpaymentValidators['depositApartmentHandoverDate']} label={t.DIGITAL_CONTRACT_FORM_APARTMENT_HANDOVER_DATE + '*'} />
        <Input disabled={model.depositAmount ? true : false} value={model.depositAmount} name='depositAmount' {...getAttrs('depositAmount')} label={t.DIGITAL_CONTRACT_FORM_DEPOSIT_AMOUNT + '*'} />
        <h3 className='inputs-text'>{t.DEPOSIT_HEAD_THREE}</h3>
        <Input name='depositAccountHolder' {...getAttrs('depositAccountHolder')} label={t.DIGITAL_CONTRACT_FORM_ACCOUNT_HOLDER + '*'} />
        <Input name='depositIban' {...getAttrs('depositIban')} label={t.DIGITAL_CONTRACT_FORM_IBAN + '*'} />
        <Input name='depositBic' {...getAttrs('depositBic')} label={t.DIGITAL_CONTRACT_FORM_BIC + '*'} />
        <p className='input-para'>{t.DEPOSIT_PARA_ONE}</p>
        <Input name='place' {...getAttrs('place')} label={t.DIGITAL_CONTRACT_FORM_PLACE + '*'} />
        <DateInput name='date' value={model['date']} onChange={(date) => setVal('date', date)} validate={depositOutpaymentValidators['date']} label={t.DIGITAL_CONTRACT_FORM_DATE + '*'} />
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button
              type='submit'
              className='button primary'
            >
              {t.DIGITAL_CONTRACT_FORM_SEND}
            </Button>
            <Button
              type='button'
              className='button secondary'
              onClick={() => {
                DocumentStore.onCloseForm()
              }}
            >
              {t.DIGITAL_CONTRACT_FORM_CANCEL}
            </Button>
          </div>
        </Form.Group>
      </Form>
    </div>
  )
}

Deposit.propTypes = {
  file: PropTypes.object.isRequired,
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(Deposit))
