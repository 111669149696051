import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import PropTypes from 'prop-types'
import t from '../../service/translate.service'
import { ConsultationsImage, ContractsImage } from '../../components/icons'
import { api, API_BASE } from '../../service/api.service'
import { Link } from 'react-router-dom'
import NoContent from '../../components/NoContent'
import './style.css'
import historyService from '../../service/history.service'
import * as DOMPurify from 'dompurify';
import { isEmpty } from 'lodash'

class CoronaMessage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      template: '',
      mailLink: ''
    }
  }

  componentWillMount = () => {
    window.scrollTo(0, 0)
    api.get(`${API_BASE}/public/static/tenant-consultations-${t.locale}.html`).then(res => {
      const sanitizedData = DOMPurify.sanitize(res.data)
      this.setState({ template: sanitizedData })
    })
  }

  componentDidMount = () => {
    setTimeout(() => this.setState({ mailLink: document.querySelector('.mail') }), 1000)
  }

  render () {
    if (this.state.mailLink) {
      this.state.mailLink.href = 'mailto:termin@grandcityproperty.de'
      this.state.mailLink.innerHTML = 'termin@grandcityproperty.de'
    }
    if (isEmpty(this.props.contractStore.selectedContract)) {
      return (
        <div>
          <div className='text-center'>
            <Link to='/contracts/add-contract'
            className='button primary'
            style={{ marginBottom: '30px' }}
            onClick={() => historyService.push('/contracts/add-contract')}>
              {t.ADD_A_CONTRACT_BUTTON}
            </Link>
          </div>
          <NoContent message={t.NO_CONTRACTS} image={ContractsImage} />
        </div>
      )
    }

    return (
      <div className='consultation-message-web'>
        <div className='consultation-img-holder'>
          {ConsultationsImage}
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.state.template }} />
      </div>
    )
  }
}

CoronaMessage.propTypes = {
  contractStore: PropTypes.object
}

export default inject('contractStore')(observer(CoronaMessage))
