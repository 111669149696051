import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import t from "../../../../service/translate.service";
import LoadingPageOverlay from "../../../../components/LoadingPageOverlay";
import { extractError, extractErrorFromSap } from "../../../../service/response.service";
import { Alert } from "antd";
const { Table } = require("react-bootstrap");

const WeekSlotsPage = (props) => {
  const navigate = useNavigate();
  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [address, setAddress] = useState("");
  const [calendarId, setCalendarId] = useState("");
  const { contractNumber } = useParams();

  useEffect(() => {
    (async () => {
      setLoading(true)
      try {
        const data = await props.consultationStore.getWeekSlots(contractNumber);
        setSlots(data.slots || []);
        setAddress(data.address || []);
        setCalendarId(data.calendarId || []);
      } catch (err) {
        setError(extractErrorFromSap(err) || t[extractError(err)] || t['ERROR'])
      }
      setLoading(false)
    })();
  }, [contractNumber, props.consultationStore]);

  if (loading) {
    return (
      <LoadingPageOverlay />
    )
  }

  return (
    <div>
      {error && (
        <Alert message={error} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />
      )}
      <h1
        className="heading-address"
      >
        {t.CONSULTATIONS_WORKING_HOURS}
        {` - ${address}`}
      </h1>
      <Table>
        <thead style={{ backgroundColor: "#26f8ab" }}>
          <tr>
            <th>{t["CONSULTATIONS_DATE"]}</th>
            <th>{t["CONSULTATIONS_TIME"]}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {slots.map((slot, index) => {
            return (
              <tr
                key={index}
                style={{
                  verticalAlign: "baseline",
                }}
              >
                <td>
                  {t[`DAY_${slot.day}`]}
                  {`, ${slot.date}`}
                </td>
                <td>{`${slot.startTime} - ${slot.endTime}`}</td>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {slot.booked ? (
                    <button
                      onClick={() => {}}
                      className="button secondary small"
                    >
                      {t.CONSULTATIONS_UNAVAILABLE_DATES_BUTTON}
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        if (!slot.booked) {
                          navigate(
                            `/consultations/${contractNumber}/slots?type=day&date=${slot.date}&startTime=${slot.startTime}&endTime=${slot.endTime}&address=${address}&calendarId=${calendarId}`
                          )
                        }
                      }}
                      className="button primary small"
                    >
                      {t.CONSULTATIONS_AVAILABLE_DATES_BUTTON}
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default WeekSlotsPage;
