import React from 'react'
import { observer } from 'mobx-react'
import { Form, Button } from 'react-bootstrap'
import t from '../../service/translate.service'
import PrimaryTitle from '../../components/PrimaryTitle'

const LoyaltyPaymentKeepPoints = observer(({ store, onCancel, onSubmit, uploadError, backToLoyalty }) => {
  // const { setCode, code } = store

  return (
    <div className='loyalty-upload module'>
      <div className='loyalty-code-top text-center'>
        <PrimaryTitle title={t.LOYALTY_PAYMENT_KEEP_POINTS_TITLE} />
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT1}</p>
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT2}</p>
        <p>{t.LOYALTY_PAYMENT_KEEP_POINTS_TEXT3}</p>
      </div>
      <Form >
        <Form.Group className='actions text-center'>
          <div className='group'>
            <Button onClick={backToLoyalty} type='submit' className='button primary'>{t.LOYALTY_PAYMENT_KEEP_POINTS_BTN}</Button>
          </div>
        </Form.Group>
      </Form>
      <Button className='button tertiary' onClick={(e) => onCancel(e)}>{t.FORM_CANCEL}</Button>
    </div>
  )
})

export default LoyaltyPaymentKeepPoints
