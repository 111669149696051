import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { extendObservable } from 'mobx'
import { Input, Checkbox } from '../../components/formComponents'
import ThankYouOverlay from '../../components/ThankYouOverlay'
import ProfileStore from '../../store/profile.store'
import t from '../../service/translate.service'
import { Form, Button } from 'react-bootstrap'
import historyService from '../../service/history.service'
import { Alert } from 'antd'

class ProfileForm extends Component {
  constructor(props) {
    super(props)

    extendObservable(this, {
      sending: false,
      profileStore: new ProfileStore()
    })
  }

  onSubmit = e => {
    e.preventDefault()
    this.profileStore.submit()
  }

  getAttrs = field => {
    const { model, setVal, validators } = this.profileStore
    return {
      value: model[field],
      onChange: e => setVal(field, e.target.value),
      validate: validators[field]
    }
  }

  render () {
    const { model, setVal, validators, successSubmit, submitErr, errorMsg} = this.profileStore
    if (successSubmit) {
      return (
        <ThankYouOverlay
          title={t.THANK_YOU}
          description={t.SAVE_SUCCESS}
          buttonText={t.BACK_TO_PROFILE}
          buttonLink='/profile'
        />
      )
    }
    return (
      <div>
        <Form onSubmit={this.onSubmit}>
          <Input
            name='firstName'
            {...this.getAttrs('firstName')}
            label={t['USER_FIRST_NAME'] + '*'}
          />
          <Input
            name='lastName'
            {...this.getAttrs('lastName')}
            label={t['USER_LAST_NAME'] + '*'}
          />
          <Input name='email' {...this.getAttrs('email')} label={t['USER_EMAIL'] + '*'} disabled />
          <Input name='phone' {...this.getAttrs('phone')} label={t['USER_PHONE'] + '*'} />
          <Input name='mobile' {...this.getAttrs('mobile')} label={t['USER_MOBILE']} />

          <Checkbox
            label={t['USER_WANT_NEWSLETTER']}
            value={model.subscribed}
            onChange={e => setVal('subscribed', !model.subscribed)}
            validate={validators['subscribed']}
          />

          <p className='newsletter-sub'>{t.NEWSLETTER_POLICY_TEXT_1}<a href='https://www.grandcityproperty.de/datenschutz' rel="nofollow noreferrer noopener" target='_blank'>{t.NEWSLETTER_POLICY_LINK}</a>{t.NEWSLETTER_POLICY_TEXT_2}</p>
          <Form.Group className='actions text-center'>
            <div className='group'>
              <Button
                type='submit'
                className='button primary'
                onClick={this.handleClick}
                disabled={this.sending}
              >
                {t['PROFILE_SAVE']}
              </Button>
              <Link to='/profile'
                className='button secondary'
                onClick={() => historyService.push('/profile')}>
                {t['FORM_CANCEL']}
              </Link>
            </div>
          </Form.Group>
        </Form>
        {submitErr && <Alert message={errorMsg} type='error' style={{marginTop: '12px', marginBottom: '12px'}} />}
      </div>
    )
  }
}

export default observer(ProfileForm)
