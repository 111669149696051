import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Input, Switch, Upload, message } from 'antd'
import TokenService from '../../../service/token.service'
import adventStore from '../../../store/admin/Advent.store'
import { Navigate } from 'react-router-dom'

class PartnerForm extends Component {
  onSubmit (e) {
    e.preventDefault()
    adventStore.save()
  }

  onClone (e) {
    e.preventDefault()
    adventStore.clone()
  }

  getBase64 (img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  beforeUpload (file) {
    const isRightFormat = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isRightFormat) {
      message.error('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!')
    }
    return isRightFormat && isLt2M
  }

  handleImageChange = info => {
    if (info.file.status === 'uploading') {
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, imageUrl => {
        adventStore.setVal('icon', info.file.response.url)
      })
    }
  };


  render () {
    const { model, isSubmitted } = adventStore
    if(adventStore.formSubmittedSuccessfully) {
      adventStore.formSubmittedSuccessfully = false
      return <Navigate to={{ pathname: '/admin/advents/' }} />
    }
    const token = TokenService.get()

    const uploadButton = (
      <div>
        <div className='ant-upload-text'>Upload</div>
      </div>
    )

    return (
      !adventStore.loading && (
        <div>
          <h1>Extra content</h1>
          <form onSubmit={this.onSubmit}>
            <div className='input-div'>
              <label>Name *</label>
              <Input
                value={model.name}
                type='input'
                required
                onChange={e => adventStore.setVal('name', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Name De *</label>
              <Input
                value={model.nameDe}
                type='input'
                required
                onChange={e => adventStore.setVal('nameDe', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Url *</label>
              <Input
                value={model.url}
                type='input'
                required
                onChange={e => adventStore.setVal('url', e.target.value)}
              />
            </div>
            <div className='input-div'>
              <label>Active *</label>
              <div style={{width: '100px'}}>
                <Switch
                  style={{background: model.active ? '#fabb00' : 'rgba(0, 0, 0, 0.25)'}}
                  checked={model.active}
                  onChange={val => adventStore.setVal('active', val)}
                />
              </div>
            </div>
            <div className='input-div'>
              <label>Icon *</label>
              <div style={{display: 'flex', justifyContent: 'start'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <Upload
                    name='advent_image'
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    action='/api/advent/image/upload'
                    beforeUpload={this.beforeUpload}
                    value={model.icon ? model.icon : 'test'}
                    data={(file) => { return { 'filename': file.name } }}
                    onChange={this.handleImageChange}
                    headers={{ 'Authorization': token}}
                  >
                    {model.icon ? <img src={model.icon} alt='icon' style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                  {isSubmitted && !model.icon && (
                      <div className='error-message' style={{marginRight: '8px'}}>
                        Please add image
                      </div>
                  )}
                </div>
              </div>
            </div>

            <div className='input-div'>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ marginRight: 10 }}>
                  <Link to='/admin/advents'>
                    <Button type='default' htmlType='button'>
                      Cancel
                    </Button>
                  </Link>
                </div>
                <div>
                  <Button type='primary' htmlType='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    )
  }
}

export default observer(PartnerForm)
